import { SITE_CONFIG } from '@config/siteConfig';
import { createUnitsRoomsNumberLabel } from '@domains/shared/helpers/createUnitsRoomsNumberLabel';
import { getPluralTranslationVariant } from '@domains/shared/helpers/getPluralTranslationVariant';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type {
    InvestmentEstimatedDelivery,
    InvestmentUnitsAreaInSquareMeters,
    InvestmentUnitsRoomsNumber,
} from '@type/ad/investment';
import { Container, DescriptionList } from '@widgets/AdvertCard/SpecsList/SpecsList.theme';

interface Props {
    estimatedDelivery: InvestmentEstimatedDelivery | null;
    unitsRoomsNumber: InvestmentUnitsRoomsNumber | null;
    unitsNumber: number | null;
    unitsAreaInSquareMeters: InvestmentUnitsAreaInSquareMeters | null;
    isCompact?: boolean;
}

export const InvestmentSpecsList = ({
    estimatedDelivery,
    unitsRoomsNumber,
    unitsNumber,
    unitsAreaInSquareMeters,
    isCompact = false,
}: Props): JSX.Element | null => {
    const [t] = useTranslations();

    const hasAnySpecsToDisplay = estimatedDelivery || unitsRoomsNumber || unitsNumber || unitsAreaInSquareMeters;

    if (!hasAnySpecsToDisplay) {
        return null;
    }

    return (
        <Container data-testid="investment-card-specs-list">
            <DescriptionList isCompact={isCompact}>
                {unitsNumber ? (
                    <>
                        <dt>{t('frontend.widgets.specs-list-investments.available-units-term')}</dt>
                        <UnitsNumberDefinition value={unitsNumber} />
                    </>
                ) : null}

                {estimatedDelivery ? (
                    <>
                        <dt>{t('frontend.widgets.specs-list-investments.estimated-delivery-term')}</dt>
                        <EstimatedDeliveryDefinition value={estimatedDelivery} />
                    </>
                ) : null}

                {unitsRoomsNumber ? (
                    <>
                        <dt>{t('frontend.widgets.specs-list-investments.units-rooms-term')}</dt>
                        <UnitsRoomsNumberDefinition value={unitsRoomsNumber} />
                    </>
                ) : null}

                {unitsAreaInSquareMeters ? (
                    <>
                        <dt>{t('frontend.widgets.specs-list-investments.units-size-term')}</dt>
                        <UnitsAreaInSquareMetersDefinition value={unitsAreaInSquareMeters} />
                    </>
                ) : null}
            </DescriptionList>
        </Container>
    );
};

const UnitsNumberDefinition = ({ value }: { value: number }): JSX.Element => {
    const { lang } = useSiteSettings();
    const [t] = useTranslations();

    const translationVariant = getPluralTranslationVariant({
        lang,
        value: value,
        translation: {
            zero: 'frontend.widgets.specs-list-investments.units-zero',
            one: 'frontend.widgets.specs-list-investments.units-one',
            two: 'frontend.widgets.specs-list-investments.units-two',
            few: 'frontend.widgets.specs-list-investments.units-few',
            many: 'frontend.widgets.specs-list-investments.units-many',
            other: 'frontend.widgets.specs-list-investments.units-other',
        },
    });

    return <dd>{t(translationVariant, { value: value.toString() })}</dd>;
};

const EstimatedDeliveryDefinition = ({ value }: { value: InvestmentEstimatedDelivery }): JSX.Element => {
    const [t] = useTranslations();

    const { year, quarter } = value;
    const values = {
        quarter: quarter.toString(),
        year: year.toString(),
    };

    return <dd>{`${t('frontend.widgets.specs-list-investment.planned-delivery', values)}`}</dd>;
};

const UnitsRoomsNumberDefinition = ({ value }: { value: InvestmentUnitsRoomsNumber }): JSX.Element => {
    const [t] = useTranslations();

    const roomsNumberLabelVariant = SITE_CONFIG.shouldUseTypologyInsteadOfNumberOfRooms ? 'typology' : undefined;

    const label = createUnitsRoomsNumberLabel(t, value, roomsNumberLabelVariant);

    return <dd>{label}</dd>;
};

const UnitsAreaInSquareMetersDefinition = ({ value }: { value: InvestmentUnitsAreaInSquareMeters }): JSX.Element => {
    const [t] = useTranslations();
    const { area: unit } = SITE_CONFIG.defaultUnits;
    const { from, to } = value;

    const values = {
        from: from.toString(),
        to: to.toString(),
        unit,
    };

    return <dd>{t('frontend.widgets.specs-list-investment.units-area', values)}</dd>;
};
