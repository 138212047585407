import type { FC } from 'react';

import { FactoidWrapper, Label, Value } from './Factoid.theme';

interface Props {
    label: string;
    value: number | string;
}

export const Factoid: FC<Props> = ({ label, value }) => {
    return (
        <FactoidWrapper>
            <Value>{value}</Value>
            <Label>{label}</Label>
        </FactoidWrapper>
    );
};
