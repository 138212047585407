import { MOBILE_CONTACT_FORM_DIALOG_ID } from '@domains/companies/sellerPage/constants';
import {
    MobileButtonsFooterContainer,
    PhoneButton,
} from '@domains/shared/components/nexus/MobileContactFormButtonsFooter/MobileContactFormButtonsFooter.theme';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import type { JSX } from 'react';
import { useCallback } from 'react';

import { StyledButton } from './MobileButtonsFooter.theme';

export const MobileButtonsFooter = ({ phone }: { phone: string }): JSX.Element => {
    const { openDialog } = useDialogState(MOBILE_CONTACT_FORM_DIALOG_ID);
    const [t] = useTranslations();

    const handleClickMessageButton = useCallback((): void => {
        openDialog();
    }, [openDialog]);

    return (
        <MobileButtonsFooterContainer data-cy="sticky-contact-actions">
            <StyledButton variant="secondary" onClick={handleClickMessageButton}>
                {t('frontend.shared.mobile-buttons-footer.message')}
            </StyledButton>

            <PhoneButton href={`tel:${phone}`}>{t('frontend.shared.mobile-buttons-footer.call')}</PhoneButton>
        </MobileButtonsFooterContainer>
    );
};
