import { SEARCH_BOX_WIDGET_CONFIG } from '@config/widgets/searchBox/searchBoxWidgetConfig';
import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { ViewTypeContext } from '@domains/shared/contexts/ViewTypeContext';
import { useClickOutside } from '@domains/shared/hooks/useClickOutside/useClickOutside';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useUrlMapParams } from '@domains/shared/hooks/useUrlMapParams';
import { useTracking } from '@lib/tracking/useTracking';
import { Button } from '@nexus/lib-react/dist/core/Button';
import type { GoogleSuggestion } from '@type/search/google';
import { GOOGLE_SEARCH_TYPE } from '@type/search/google';
import type { TreeNode } from '@type/search/location/pickerTreeNode';
import { LocationIcon } from '@widgets/search/LocationIcon';
import { LocationPickerInput } from '@widgets/search/LocationPickerInput';
import { getLocationPickerContent } from '@widgets/search/LocationPickerInput/helpers/getLocationPickerContent';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import dynamic from 'next/dynamic';
import type { FC } from 'react';
import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import type { LocationPickerTreeProps } from '../../../types/locationPicker';
import { LocationListSection } from './components/LocationListSection/LocationListSection';
import { LocationSuggestions } from './components/LocationSuggestions/LocationSuggestions';
import { MissingLocationFeedback } from './components/MissingLocationFeedback/MissingLocationFeedback';
import { MobileTitleBar } from './components/MobileTitleBar/MobileTitleBar';
import { SearchByStreetInitializeButton } from './components/SearchByStreetInitializeButton/SearchByStreetInitializeButton';
import { SelectedLocations } from './components/SelectedLocations/SelectedLocations';
import { LOCATION_PICKER_INPUT_ID } from './constants/elementVariables';
import { useGoogleSearch } from './hooks/useGoogleSearch/useGoogleSearch';
import { useLocationPickerService } from './hooks/useLocationPickerService/useLocationPickerService';
import { List as Regions, ListLabel, ListLabelItem } from './LocationPickerShared.theme';
import {
    ApplyLocationButton,
    ClearFiltersButton,
    Container as DefaultContainer,
    DropdownTrigger as DefaultDropdownTrigger,
    DropdownWrapper,
    ListWrapper as DefaultListWrapper,
    MobileApplyFilters,
    Placeholder,
} from './LocationPickerTree.theme';
import {
    NexusContainer,
    NexusDropdownTrigger,
    NexusListWrapper,
    StyledSearchIcon,
} from './NexusLocationPickerTree.theme';
import { LOCATION_PICKER_CONTENT } from './types/locationPickerContent';
import type { InputOnSuggestionsLoad, SuggestionsType } from './types/suggestions';

const LazyMissingLocationFeedbackModal = dynamic(
    () =>
        import(
            '@widgets/search/LocationPicker/components/LocationPickerTree/components/MissingLocationFeedbackModal/MissingLocationFeedbackModal'
        ),
    { ssr: false },
);

const LazyMissingLocationThankYouModal = dynamic(
    () =>
        import(
            '@widgets/search/LocationPicker/components/LocationPickerTree/components/MissingLocationThankYouModal/MissingLocationThankYouModal'
        ),
    { ssr: false },
);

const LazyLocationPickerStreetInput = dynamic(
    () =>
        import(
            '@widgets/search/LocationPicker/components/LocationPickerTree/components/LocationPickerStreetInput/LocationPickerStreetInput'
        ),
    { ssr: false },
);

const LazyLocationStreetSuggestions = dynamic(
    () =>
        import(
            '@widgets/search/LocationPicker/components/LocationPickerTree/components/LocationStreetSuggestions/LocationStreetSuggestions'
        ),
    { ssr: false },
);

const LazyClearLocationButton = dynamic(
    () =>
        import(
            '@widgets/search/LocationPicker/components/LocationPickerTree/components/ClearLocationButton/ClearLocationButton'
        ),
    { ssr: false },
);

const AUTOCOMPLETE_CHARS_MIN = 2;
const LOCATION_FILTERS_TRACKING_DATA = {
    touch_point_button: 'filters',
};

const LocationPickerTreeBase: FC<LocationPickerTreeProps> = ({
    selectedLocations,
    initialSelectedGoogleSuggestion,
    shouldUseNexusTheme = false,
    changeDistanceRadiusType,
    onLocationDropdownToggle,
}) => {
    const [t] = useTranslations();

    const {
        checkIsAnyChildSelected,
        checkIsExpanded,
        checkIsIdSelected,
        checkIsNodeSelected,
        clearExpanded,
        getSelectedNodes,
        recent,
        roots,
        saveRecent,
        selectedIds,
        clearSelectedIds,
        toggleExpanded,
        toggleSelectNode,
        toggleSelectSuggestion,
        getLocationsTitle,
        setPreviouslySelectedIds,
        removeTemporarySelectedIds,
    } = useLocationPickerService();

    const { trackEvent } = useTracking();
    const { isDesktop, isTablet } = useContext(RWDContext);
    const { viewType } = useContext(ViewTypeContext);

    const isAboveTablet = isDesktop || isTablet;
    const defaultTitle = isAboveTablet
        ? t('frontend.search.location-picker-tree.placeholder')
        : t('frontend.search.location-picker-tree.placeholder-mobile');

    const [title, setTitle] = useState(defaultTitle);

    const {
        formState: { isSubmitting: isFormSubmitting },
        setValue: setFieldValue,
    } = useFormContext();

    const [isPickerDropdownVisible, setIsPickerDropdownVisible] = useState(false);
    const [isMissingLocationFeedbackModalVisible, setIsMissingLocationFeedbackModalVisible] = useState(false);
    const [isMissingLocationThankYouModalVisible, setIsMissingLocationThankYouModalVisible] = useState(false);
    const [suggestionSearchPhrase, setSuggestionSearchPhrase] = useState('');
    const [suggestions, setSuggestions] = useState<SuggestionsType>([]);
    const isSuggestionSearchPhraseValid = suggestionSearchPhrase.length >= AUTOCOMPLETE_CHARS_MIN;

    const onSuggestionsLoad: InputOnSuggestionsLoad = useCallback(
        ({ searchValue, suggestions }) => {
            setSuggestions(suggestions);
            setSuggestionSearchPhrase(searchValue);
            if (searchValue && (!suggestions || suggestions.length === 0)) {
                trackEvent('location_autocomplete_no_results', { location_string: searchValue });
            }
        },
        [trackEvent],
    );

    const { shouldAlwaysDisplayToast } = SEARCH_BOX_WIDGET_CONFIG;

    const { isOnMapView, mapBounds } = useUrlMapParams();

    const isFieldTouched = useRef(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    const shouldDisplayCTAButtons = !isDesktop;

    const {
        googleSearchType,
        googleSuggestionSearchPhrase,
        googleSuggestions,
        onGoogleSuggestionsLoad,
        openGoogleSearch,
        closeGoogleSearch,
        selectGoogleSuggestion,
        selectedGoogleSuggestion,
        clearGoogleSearch,
        getSessionToken,
    } = useGoogleSearch({ initialSelectedGoogleSuggestion });

    const DropdownTrigger = shouldUseNexusTheme ? NexusDropdownTrigger : DefaultDropdownTrigger;
    const Container = shouldUseNexusTheme ? NexusContainer : DefaultContainer;
    const ListWrapper = shouldUseNexusTheme ? NexusListWrapper : DefaultListWrapper;

    const isStreetSearchActive = googleSearchType === GOOGLE_SEARCH_TYPE.street;

    const isPlaceholderVisible = shouldUseNexusTheme && title === defaultTitle ? true : false;

    useEffect(() => {
        changeDistanceRadiusType(selectedGoogleSuggestion ? 'm' : 'km');
    }, [selectedGoogleSuggestion, changeDistanceRadiusType]);

    useEffect(() => {
        const wrapper = wrapperRef.current as HTMLElement;

        if (!wrapper) {
            clearAllBodyScrollLocks();

            return;
        }
        if (!isDesktop && !isTablet) {
            if (isPickerDropdownVisible) {
                disableBodyScroll(wrapper);
            } else {
                enableBodyScroll(wrapper);
            }
        }
    }, [isPickerDropdownVisible, isDesktop, isTablet]);

    const closeDropdown = useCallback(() => {
        if (!isDesktop) {
            onLocationDropdownToggle?.(false);
        }
        setIsPickerDropdownVisible(false);
        setSuggestionSearchPhrase('');
        setSuggestions([]);
        clearExpanded();
        closeGoogleSearch();
    }, [isDesktop, clearExpanded, closeGoogleSearch, onLocationDropdownToggle]);

    const handleApplyLocation = useCallback(() => {
        if (!isDesktop) {
            onLocationDropdownToggle?.(false);
        }
        setIsPickerDropdownVisible(false);

        trackEvent('apply_location', {
            touch_point_button: isStreetSearchActive ? 'search_near_address' : 'filters_location_picker',
            search_near_address_place_id: selectedGoogleSuggestion ? selectedGoogleSuggestion?.placeId : null,
        });
    }, [isDesktop, trackEvent, isStreetSearchActive, selectedGoogleSuggestion, onLocationDropdownToggle]);

    const handleClearLocations = useCallback(() => {
        setSuggestions([]);
        clearSelectedIds();
        clearGoogleSearch();
        trackEvent('clear_filters', {
            ...LOCATION_FILTERS_TRACKING_DATA,
            search_near_address_place_id: null, // clear to avoid keeping it when selecting from normal picker
        });
    }, [clearSelectedIds, clearGoogleSearch, trackEvent]);

    const hideLocationPicker = useCallback(() => {
        if (!isDesktop) {
            onLocationDropdownToggle?.(false);
        }
        setIsPickerDropdownVisible(false);
        if (shouldDisplayCTAButtons) {
            removeTemporarySelectedIds();
        }
        trackEvent('hide_location_picker', LOCATION_FILTERS_TRACKING_DATA);
    }, [isDesktop, shouldDisplayCTAButtons, trackEvent, onLocationDropdownToggle, removeTemporarySelectedIds]);

    const openStreetSearch = useCallback((): void => openGoogleSearch(GOOGLE_SEARCH_TYPE.street), [openGoogleSearch]);

    const searchByStreetInitializeButtonOnClick = useCallback(() => {
        openStreetSearch();
        trackEvent('search_near_address_click', {
            touch_point_button: 'location_tree',
        });
    }, [openStreetSearch, trackEvent]);

    const onDropdownOpen = (): void => {
        if (mapBounds && !isFieldTouched.current) {
            clearGoogleSearch();
            clearSelectedIds();
        }

        setIsPickerDropdownVisible(true);
        if (!isDesktop) {
            onLocationDropdownToggle?.(true);
        }
        if (shouldDisplayCTAButtons) {
            setPreviouslySelectedIds();
        }
        if (selectedGoogleSuggestion) {
            openStreetSearch();

            // do not track search_box_click. It is tracked inside street input with onFocus
            return;
        }

        isFieldTouched.current = true;
        trackEvent('search_box_click', LOCATION_FILTERS_TRACKING_DATA);
    };

    const clearLocationsAndOpenDropdown = useCallback(() => {
        setSuggestions([]);
        clearSelectedIds();
        clearGoogleSearch();
        setIsPickerDropdownVisible(true);
        if (!isDesktop) {
            onLocationDropdownToggle?.(true);
        }
        trackEvent('filter_search_near_address_clear', {
            ...LOCATION_FILTERS_TRACKING_DATA, // touch_point_button is not required but clear it from 'search_near_address' value
            search_near_address_place_id: null, // clear to avoid keeping it when selecting from normal picker
        });
    }, [clearGoogleSearch, clearSelectedIds, isDesktop, onLocationDropdownToggle, trackEvent]);

    const missingLocationFeedbackHandler = (): void => {
        trackEvent('missing_location_survey_click');
        setIsMissingLocationFeedbackModalVisible(true);
        closeDropdown();
        if (!isDesktop) {
            setIsPickerDropdownVisible(false);
        }
    };

    const missingLocationFeedbackModalConfirmHandler = (): void => {
        setIsMissingLocationFeedbackModalVisible(false);
        if (viewType !== 'map' && !shouldAlwaysDisplayToast) {
            setIsMissingLocationThankYouModalVisible(true);
        }
    };

    const missingLocationFeedbackModalCloseHandler = (): void => {
        setIsMissingLocationFeedbackModalVisible(false);
    };

    const closeMissingLocationThankYouModal = (): void => {
        setIsMissingLocationThankYouModalVisible(false);
    };

    const onSelectStreet = useCallback(
        (place: GoogleSuggestion, placePosition: number) => {
            selectGoogleSuggestion(place);
            closeDropdown();
            trackEvent('search_near_address_suggestion_click', {
                index: placePosition,
                search_near_address_place_id: place.placeId,
                touch_point_button: 'search_near_address',
            });
        },
        [closeDropdown, selectGoogleSuggestion, trackEvent],
    );

    const onBackFromStreetSearch = useCallback(() => {
        closeGoogleSearch();
        clearGoogleSearch();
        trackEvent('back_button_click', {
            search_near_address_place_id: null, // clear to avoid keeping it when selecting from normal picker
            touch_point_button: 'search_near_address',
        });
    }, [clearGoogleSearch, closeGoogleSearch, trackEvent]);

    useClickOutside(wrapperRef, closeDropdown);

    /**
     * Auto selecting locations on site view.
     */
    useEffect(() => {
        if (roots.length > 0) {
            clearSelectedIds();
            selectedLocations?.map((locationItem) =>
                toggleSelectSuggestion(locationItem, { calledFrom: 'page_loaded' }),
            );
        }
    }, [selectedLocations, toggleSelectSuggestion, roots, clearSelectedIds]);

    useEffect(() => {
        if (isFormSubmitting) {
            //save into localStorage only after form submit
            saveRecent();
        }
    }, [isFormSubmitting, saveRecent]);

    /**
     * Set form field "locations" value so it can be send further to form submit handler
     */
    useEffect(() => {
        if (selectedGoogleSuggestion) {
            setFieldValue('locations', null);
            setFieldValue('location', null);
            setFieldValue('placeId', selectedGoogleSuggestion.placeId);

            return;
        }
        const selectedNodes = getSelectedNodes();

        if (selectedNodes.length === 0) {
            setFieldValue('locations', null);
            setFieldValue('location', null);
            setFieldValue('placeId', null);

            return;
        }

        const flattenLocations = selectedNodes.map((locationNode: TreeNode) => {
            const copyOfLocationNode = { ...locationNode };

            copyOfLocationNode.children = [];
            if (copyOfLocationNode?.parent) {
                delete copyOfLocationNode.parent;
            }

            return copyOfLocationNode;
        });

        setFieldValue('locations', [...flattenLocations], { shouldDirty: isFieldTouched.current });
        setFieldValue('placeId', null);
    }, [selectedIds, getSelectedNodes, setFieldValue, getLocationsTitle, selectedGoogleSuggestion]);

    useEffect(() => {
        if (selectedGoogleSuggestion) {
            setTitle(selectedGoogleSuggestion.name);
        } else if (selectedIds.length > 0) {
            setTitle(getLocationsTitle());
        } else {
            setTitle(defaultTitle);
        }
    }, [getLocationsTitle, selectedIds, defaultTitle, selectedGoogleSuggestion]);

    const hideKeyboardOnMobile = useCallback((): void => {
        if (!document.activeElement) {
            return;
        }
        //we assume that if input has focus then a keyboard is visible
        const isKeyboardVisible = document.activeElement.id === LOCATION_PICKER_INPUT_ID;

        if (isKeyboardVisible) {
            (document.activeElement as HTMLInputElement).blur();
        }
    }, []);

    useEffect(() => {
        if (!wrapperRef.current || isDesktop) {
            return;
        }

        const refCopy = wrapperRef.current;

        refCopy.addEventListener('scroll', hideKeyboardOnMobile);

        return (): void => {
            if (refCopy) {
                refCopy.removeEventListener('scroll', hideKeyboardOnMobile);
            }
        };
    }, [isDesktop, isPickerDropdownVisible, hideKeyboardOnMobile]);

    useEffect(() => {
        isSuggestionSearchPhraseValid && trackEvent('missing_location_button_impression');
    }, [isSuggestionSearchPhraseValid, trackEvent]);

    /* When someone pans the map, the location picker should display
      "Location area from the map" label; */
    const shouldDisplaySearchByMapTitle = isOnMapView && mapBounds;

    const locationPickerContentType = getLocationPickerContent({
        googleSearchType,
        suggestions,
    });

    const isWithClearIcon = !!isDesktop && !!selectedGoogleSuggestion;

    return (
        <>
            <DropdownTrigger
                id="location"
                type="button"
                data-cy="search.form.location.button"
                data-testid={shouldUseNexusTheme ? 'search.form.location.nexus-button' : 'search.form.location.button'}
                aria-label={t('frontend.search.location-picker-tree.location-label')}
                onClick={onDropdownOpen}
                withClearIcon={isWithClearIcon}
            >
                {shouldUseNexusTheme ? <StyledSearchIcon /> : <LocationIcon />}

                <Placeholder shouldHaveLightColor={isPlaceholderVisible} data-cy="search.form.location.placeholder">
                    {shouldDisplaySearchByMapTitle
                        ? t('frontend.search.location-picker-tree.location-title-based-on-map-area')
                        : title}
                </Placeholder>
            </DropdownTrigger>

            {isWithClearIcon ? (
                <LazyClearLocationButton
                    shouldUseNexusTheme={shouldUseNexusTheme}
                    onClick={clearLocationsAndOpenDropdown}
                />
            ) : null}

            {isPickerDropdownVisible ? (
                <DropdownWrapper
                    shouldUseNexusTheme={shouldUseNexusTheme}
                    ref={wrapperRef}
                    data-cy="search.form.location.dropdown"
                >
                    {isDesktop ? null : (
                        <MobileTitleBar
                            isStreetSearchActive={isStreetSearchActive}
                            onBackFromStreetSearch={onBackFromStreetSearch}
                            hideLocationPicker={hideLocationPicker}
                        />
                    )}

                    <Container
                        data-testid={
                            shouldUseNexusTheme
                                ? 'search.form.location.nexus-container'
                                : 'search.form.location.container'
                        }
                    >
                        {locationPickerContentType === LOCATION_PICKER_CONTENT.street ? (
                            <LazyLocationPickerStreetInput
                                minLength={AUTOCOMPLETE_CHARS_MIN}
                                defaultSearchValue={googleSuggestionSearchPhrase}
                                selectedSuggestion={selectedGoogleSuggestion}
                                shouldUseNexusTheme={shouldUseNexusTheme}
                                getSessionToken={getSessionToken}
                                onSuggestionsLoad={onGoogleSuggestionsLoad}
                            />
                        ) : (
                            <LocationPickerInput
                                onSuggestionsLoad={onSuggestionsLoad}
                                minLength={AUTOCOMPLETE_CHARS_MIN}
                                shouldUseNexusTheme={shouldUseNexusTheme}
                            />
                        )}
                        <ListWrapper
                            isDropdownOpened={isPickerDropdownVisible}
                            data-cy="search.form.location.dropdown.list-wrapper"
                            data-testid={
                                shouldUseNexusTheme
                                    ? 'search.form.location.nexus-list-wrapper'
                                    : 'search.form.location-list-wrapper'
                            }
                        >
                            {locationPickerContentType === LOCATION_PICKER_CONTENT.street ? (
                                <>
                                    <LazyLocationStreetSuggestions
                                        suggestions={googleSuggestions}
                                        selectSuggestion={onSelectStreet}
                                        selectedSuggestion={selectedGoogleSuggestion}
                                        currentSearchValue={googleSuggestionSearchPhrase}
                                    />
                                </>
                            ) : null}
                            {locationPickerContentType === LOCATION_PICKER_CONTENT.suggestions ? (
                                <>
                                    <LocationSuggestions
                                        checkIsIdSelected={checkIsIdSelected}
                                        minLength={AUTOCOMPLETE_CHARS_MIN}
                                        suggestions={suggestions}
                                        suggestionSearchQuery={suggestionSearchPhrase}
                                        toggleSelectSuggestion={toggleSelectSuggestion}
                                        shouldUseNexusTheme={shouldUseNexusTheme}
                                    />
                                    {isSuggestionSearchPhraseValid ? (
                                        <MissingLocationFeedback onReportButtonClick={missingLocationFeedbackHandler} />
                                    ) : null}
                                </>
                            ) : null}
                            {locationPickerContentType === LOCATION_PICKER_CONTENT.tree ? (
                                <>
                                    <SelectedLocations
                                        checkIsNodeSelected={checkIsNodeSelected}
                                        selectedLocations={getSelectedNodes()}
                                        toggleExpanded={toggleExpanded}
                                        toggleSelectNode={toggleSelectNode}
                                        shouldUseNexusTheme={shouldUseNexusTheme}
                                    />
                                    <SelectedLocations
                                        caller="recently_selected"
                                        checkIsNodeSelected={checkIsNodeSelected}
                                        checkboxPostfix="recent"
                                        label="frontend.search.location-picker-tree.location-dropdown-recent-label"
                                        labelId="recent-locations-label"
                                        dataCy="recent-locations-list"
                                        selectedLocations={recent}
                                        toggleExpanded={toggleExpanded}
                                        toggleSelectNode={toggleSelectNode}
                                        shouldUseNexusTheme={shouldUseNexusTheme}
                                    />
                                    <SearchByStreetInitializeButton onClick={searchByStreetInitializeButtonOnClick} />
                                    <Regions data-cy="regions-list" aria-describedby="regions-list-label">
                                        <ListLabelItem>
                                            <ListLabel
                                                id="regions-list-label"
                                                shouldUseNexusTheme={shouldUseNexusTheme}
                                            >
                                                {t(
                                                    'frontend.search.location-picker-tree.location-dropdown-region-label',
                                                )}
                                            </ListLabel>
                                        </ListLabelItem>
                                        {roots.map((item) => (
                                            <LocationListSection
                                                checkIsAnyChildSelected={checkIsAnyChildSelected}
                                                checkIsExpanded={checkIsExpanded}
                                                checkIsNodeSelected={checkIsNodeSelected}
                                                isExpanded={checkIsExpanded(item)}
                                                item={item}
                                                key={item.id}
                                                toggleExpanded={toggleExpanded}
                                                toggleSelectNode={toggleSelectNode}
                                                shouldUseNexusTheme={shouldUseNexusTheme}
                                            />
                                        ))}
                                    </Regions>
                                </>
                            ) : null}
                        </ListWrapper>
                        {shouldDisplayCTAButtons && shouldUseNexusTheme ? (
                            <MobileApplyFilters shouldUseNexusTheme>
                                <Button
                                    size="small"
                                    variant="secondary"
                                    data-cy="search.form.location.clear-locations"
                                    data-testid="search.form.location.clear-locations"
                                    onClick={handleClearLocations}
                                    disabled={
                                        isStreetSearchActive &&
                                        !googleSuggestionSearchPhrase &&
                                        !selectedGoogleSuggestion
                                    }
                                >
                                    {t('frontend.search.location-picker-tree.mobile-clear-locations-button')}
                                </Button>

                                <Button
                                    size="small"
                                    data-cy="search.form.location.apply-locations"
                                    onClick={handleApplyLocation}
                                    disabled={isStreetSearchActive && !selectedGoogleSuggestion}
                                >
                                    {t('frontend.search.location-picker-tree.mobile-apply-locations-button')}
                                </Button>
                            </MobileApplyFilters>
                        ) : null}
                        {shouldDisplayCTAButtons && !shouldUseNexusTheme ? (
                            <MobileApplyFilters>
                                <ClearFiltersButton
                                    data-cy="search.form.location.clear-locations"
                                    data-testid="search.form.location.clear-locations"
                                    onClick={handleClearLocations}
                                    variant={ButtonVariant.Ghost}
                                    disabled={
                                        isStreetSearchActive &&
                                        !googleSuggestionSearchPhrase &&
                                        !selectedGoogleSuggestion
                                    }
                                >
                                    {t('frontend.search.location-picker-tree.mobile-clear-locations-button')}
                                </ClearFiltersButton>
                                <ApplyLocationButton
                                    data-cy="search.form.location.apply-locations"
                                    onClick={handleApplyLocation}
                                    variant={ButtonVariant.Action}
                                    disabled={isStreetSearchActive && !selectedGoogleSuggestion}
                                >
                                    {t('frontend.search.location-picker-tree.mobile-apply-locations-button')}
                                </ApplyLocationButton>
                            </MobileApplyFilters>
                        ) : null}
                    </Container>
                </DropdownWrapper>
            ) : null}

            {/**
             * This placement of modal is intentional.
             * With the placement inside the <DropdownWrapper/>, the modal would've been closed upon opening because of useClickOutside hook
             */}
            {isMissingLocationFeedbackModalVisible ? (
                <LazyMissingLocationFeedbackModal
                    onCancel={missingLocationFeedbackModalCloseHandler}
                    onDismiss={missingLocationFeedbackModalCloseHandler}
                    onConfirm={missingLocationFeedbackModalConfirmHandler}
                />
            ) : null}

            {isMissingLocationThankYouModalVisible ? (
                <LazyMissingLocationThankYouModal
                    onDismiss={closeMissingLocationThankYouModal}
                    onMapButtonClick={closeMissingLocationThankYouModal}
                />
            ) : null}
        </>
    );
};

export const LocationPickerTree = memo(LocationPickerTreeBase);
