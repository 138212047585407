import { Container } from '@domains/shared/components/Container/Container';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE } from '@lib/styles/partials/typography';

export const StyledTitle = styled.h2`
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: ${SIZE.h6};

    @media (min-width: ${BREAKPOINT.md}) {
        font-size: ${SIZE.h4};
    }
`;

export const TopSectionWrapper = styled(Container)`
    flex-direction: column;

    @media (max-width: ${BREAKPOINT.md}) {
        border-top: 1px solid
            ${({ theme }): string => theme.deprecated.domains.companies.discoverySection.topSection.borderColor};
    }
`;
