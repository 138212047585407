import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import ChevronLeft from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronLeft';
import type { FC } from 'react';

import { MobileBackButton, MobileStickyHeader, MobileStickyHeaderText, ResetButton } from './MobileFiltersHeader.theme';

interface Props {
    onClose: () => void;
    onClear: () => void;
}

export const MobileFiltersHeader: FC<Props> = ({ onClose, onClear }) => {
    const [t] = useTranslations();

    return (
        <MobileStickyHeader>
            <MobileBackButton
                prefixIcon={ChevronLeft}
                variant="flat"
                aria-label={t('frontend.global.navigation.close-button')}
                onClick={onClose}
                data-cy="adverts-filters-exit-button"
            />
            <MobileStickyHeaderText>
                {t('frontend.companies-seller-page.mobile-filters-header.filters')}
            </MobileStickyHeaderText>
            <ResetButton variant="flat" onClick={onClear} data-cy="adverts-filters-reset-button">
                {t('frontend.companies-seller-page.mobile-filters-header.clear-filters')}
            </ResetButton>
        </MobileStickyHeader>
    );
};
