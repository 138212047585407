export const MARKET_METADATA = {
    all: {
        label: 'frontend.global.search.market-all' as string,
        value: 'ALL',
    },
    primary: {
        label: 'frontend.global.search.market-primary' as string,
        value: 'PRIMARY',
    },
    secondary: {
        label: 'frontend.global.search.market-secondary' as string,
        value: 'SECONDARY',
    },
} as const;

export const MARKET_OPTIONS = Object.values(MARKET_METADATA);

export const DEFAULT_MARKET_OPTION = MARKET_METADATA.all;

export const VALID_MARKET_VALUES = MARKET_OPTIONS.map(({ value }) => value) as ReadonlyArray<string>;

export const DEFAULT_MARKET_VALUE = DEFAULT_MARKET_OPTION.value;
