import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const NoResults = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 352px;
    padding: 0 ${theme.space[16]};
    text-align: center;
    gap: ${theme.space[16]};
`;
