import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const FactoidsWrapper = styled.dl`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-block: 0;
    padding: ${theme.space[12]} 0;
`;

export const FactoidWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: start;
    min-width: 148px;
    margin: 0 0 ${theme.space[12]} 0;
    line-height: ${theme.lineHeights[26]};
`;

export const Value = styled.dd`
    margin-inline-start: 0;
    color: ${theme.colors['text-global-primary']};
    font-size: ${theme.fontSizes[22]};
    font-weight: ${theme.fontWeights[700]};
`;

export const Label = styled.dt`
    color: ${theme.colors['text-global-primary']};
    font-size: ${theme.fontSizes[11]};
    font-weight: ${theme.fontWeights[400]};
`;
