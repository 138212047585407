import { Button } from '@domains/shared/components/Button/Button';
import { ButtonGroup } from '@domains/shared/components/ButtonGroup/ButtonGroup';
import {
    getActiveButtonCss,
    getStandardButtonCss,
    getTextShadow,
} from '@domains/shared/helpers/styles/getStylesForListingTopBarButtons';
import type { SerializedStyles } from '@emotion/react';
import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LETTER_SPACING, WEIGHT } from '@lib/styles/partials/typography';
import type { PropsWithChildren } from 'react';

export const Container = styled.div`
    display: flex;
    align-items: center;
    max-width: 100%;
`;

export const Label = styled.label`
    flex-grow: 1;
    align-self: center;
    width: 100%;
    padding-right: 8px;
    color: ${({ theme }): string => theme.deprecated.domains.search.listingPanel.sorting.label.color};
    font-weight: ${WEIGHT.semibold};
    text-align: right;
    white-space: nowrap;
`;

export const StyledButton = styled(Button)<{ isActive?: boolean }>`
    width: fit-content;
    min-width: 32px;
    padding: 8px;
    transition: 0.2s color ease-in-out;
    border: 1px solid;
    ${({ theme }): SerializedStyles => getStandardButtonCss(theme)};
    border-radius: 2px;
    font-weight: ${WEIGHT.regular};
    letter-spacing: ${LETTER_SPACING.x1};
    white-space: nowrap;

    @media (min-width: ${BREAKPOINT.md}) {
        max-width: unset;
        padding: 8px 16px;
    }

    &:hover,
    &:focus {
        border-color: ${({ theme }): CSSProperties['borderColor'] =>
            theme.deprecated.domains.shared.sortingDropdown.buttonGroup.button.base.borderColor};
        background-color: ${({ theme }): CSSProperties['backgroundColor'] =>
            theme.deprecated.domains.shared.sortingDropdown.buttonGroup.button.hover.backgroundColor};
        color: ${({ theme }): CSSProperties['color'] =>
            theme.deprecated.domains.shared.sortingDropdown.buttonGroup.button.hover.color};
    }

    &:focus {
        text-shadow: ${({ theme }): CSSProperties['textShadow'] => getTextShadow({ isActive: true, theme })};
    }

    ${({ isActive, theme }): SerializedStyles | string => (isActive ? getActiveButtonCss(theme) : '')}
`;

// &[aria-checked='true'] + ${StyledButton}: Mark the next button as selected if this one is active and hidden
// :78 &[aria-checked='true'] + ${StyledButton} Reset marking the next button as selected
export const StyledButtonHiddenOnMobile = styled(StyledButton)`
    display: none;

    &[aria-checked='true'] + ${StyledButton} {
        ${({ isActive, theme }): SerializedStyles | string => (isActive ? getActiveButtonCss(theme) : '')}
    }

    @media (min-width: ${BREAKPOINT.md}) {
        display: inline-block;

        &[aria-checked='true'] + ${StyledButton} {
            ${({ isActive, theme }): SerializedStyles | string => (isActive ? getStandardButtonCss(theme) : '')}
        }
    }
`;

export const StyledButtonGroup = styled(ButtonGroup)<{ isSFS302VariantB?: boolean }>`
    > button {
        height: 36px;
    }

    > :first-of-type {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        > :first-of-type {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
        }
    }
`;

export const PopoverWrapper = styled.div<PropsWithChildren>`
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 50px;
`;
