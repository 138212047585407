import type { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SCREEN_READER_ONLY } from '@lib/styles/partials/helpers';
import { WEIGHT } from '@lib/styles/partials/typography';

export const StyledLabel = styled.label<{ isVisuallyHidden?: boolean }>`
    display: inline-block;
    margin-bottom: 8px;
    font-weight: ${WEIGHT.semibold};

    ${({ isVisuallyHidden }): SerializedStyles | null => (isVisuallyHidden ? SCREEN_READER_ONLY : null)};

    @media (min-width: ${BREAKPOINT.md}) {
        ${SCREEN_READER_ONLY};
    }
`;
