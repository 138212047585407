import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import SearchIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Search';
import type { FC } from 'react';

import { Container, LabelButton, Separator, StyledChevronRight, Text } from './SearchByStreetInitializeButton.theme';

export const SearchByStreetInitializeButton: FC<{ onClick: () => void }> = ({ onClick }) => {
    const [t] = useTranslations();

    return (
        <Container>
            <LabelButton
                onClick={onClick}
                variant={ButtonVariant.Ghost}
                data-testid="search.form.location.street.search-near-location"
            >
                <SearchIcon />
                <Text>{t('frontend.global.search.street-search-near-location')}</Text>
                <StyledChevronRight />
            </LabelButton>
            <Separator />
        </Container>
    );
};
