import { VALID_DISTANCE_RADIUS_VALUES } from '@domains/companies/sellerPage/config/filters/distanceRadius';
import { ViewTypeContext } from '@domains/shared/contexts/ViewTypeContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { LocationsObjects } from '@type/search/location/dataItems';
import { LocationPicker } from '@widgets/search/LocationPicker';
import type { JSX } from 'react';
import { useContext } from 'react';

import { FieldLabel } from '../components/FieldLabel';
import { Container } from './LocationField.theme';

interface Props {
    locations: LocationsObjects[] | null;
}

const DISTANCE_RADIUS_NUMBER_VALUES = VALID_DISTANCE_RADIUS_VALUES.map(Number);
const DISTANCE_RADIUS_OPTIONS = {
    km: DISTANCE_RADIUS_NUMBER_VALUES,
    m: [],
};

export const LocationField = ({ locations }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { viewType } = useContext(ViewTypeContext);

    return (
        <Container>
            <FieldLabel htmlFor="location">{t('frontend.companies.search-form.location-label')}</FieldLabel>
            <LocationPicker
                selectedLocations={locations}
                viewType={viewType}
                distanceRadiusOptions={DISTANCE_RADIUS_OPTIONS}
                distanceRadiusDataCy="dropdown"
            />
        </Container>
    );
};
