import { DEFAULT_DISTANCE_RADIUS_VALUE } from '@domains/companies/sellerPage/config/filters/distanceRadius';
import { DEFAULT_ESTATE_OPTION, ESTATE_METADATA } from '@domains/companies/sellerPage/config/filters/estate';
import { DEFAULT_MARKET_OPTION } from '@domains/companies/sellerPage/config/filters/market';
import { DEFAULT_ROOMS_NUMBER_OPTION } from '@domains/companies/sellerPage/config/filters/roomsNumber';
import { DEFAULT_TRANSACTION_OPTION } from '@domains/companies/sellerPage/config/filters/transaction';
import type { FormData } from '@domains/companies/sellerPage/types/filtersData';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import type { BaseSyntheticEvent } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { useUpdateRoute } from './useUpdateRoute';

interface UseSearchFormReturnType extends UseFormReturn<FormData> {
    submit: (event?: BaseSyntheticEvent) => Promise<void>;
    clear: () => void;
}

interface UseSearchFormArguments {
    formData: FormData;
    isAgency: boolean;
}

export const useSearchForm = ({ formData, isAgency }: UseSearchFormArguments): UseSearchFormReturnType => {
    const form = useForm<FormData>({
        mode: 'onTouched',
        defaultValues: formData,
    });
    const {
        featureFlags: { isNexusSellerPageEnabled },
    } = useSiteSettings();
    const { updateRoute } = useUpdateRoute();

    const submit = form.handleSubmit(async (data) => {
        await updateRoute(data);
    });

    const clear = (): void => {
        form.reset({
            estate: isNexusSellerPageEnabled && !isAgency ? ESTATE_METADATA.investment : DEFAULT_ESTATE_OPTION,
            transaction: DEFAULT_TRANSACTION_OPTION,
            roomsNumber: DEFAULT_ROOMS_NUMBER_OPTION,
            market: DEFAULT_MARKET_OPTION,
            locations: null,
            distanceRadius: DEFAULT_DISTANCE_RADIUS_VALUE,
            priceMin: null,
            priceMax: null,
            areaMin: null,
            areaMax: null,
            investmentState: null,
        });
    };

    return {
        ...form,
        submit,
        clear,
    };
};
