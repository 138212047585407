import { SEARCH_FIELDS_METADATA } from './searchFieldsMetadata';
import type { CompaniesConfig } from './type';

export * from './type';

export const COMPANIES_CONFIG: CompaniesConfig = {
    sellerPage: {
        provinceImageFile: {
            1: 'aveiro',
            2: 'beja',
            3: 'braga',
            4: 'braganca',
            5: 'castelo-branco',
            6: 'coimbra',
            7: 'evora',
            8: 'faro',
            9: 'guarda',
            10: 'leiria',
            11: 'lisboa',
            12: 'portalegre',
            13: 'porto',
            14: 'santarem',
            15: 'setubal',
            16: 'viana-do-castelo',
            17: 'vila-real',
            18: 'viseu',
            19: 'ilha-da-madeira',
            20: 'ilha-de-porto-santo',
            21: 'ilha-de-santa-maria',
            22: 'ilha-de-sao-miguel',
            23: 'ilha-terceira',
            24: 'ilha-da-graciosa',
            25: 'ilha-de-sao-jorge',
            26: 'ilha-do-pico',
            27: 'ilha-do-faial',
            28: 'ilha-das-flores',
            29: 'ilha-do-corvo',
        },
        defaultBoundingBoxForMap: {
            neLat: 42.154311,
            neLng: -6.189159,
            swLat: 36.909815,
            swLng: -9.545657,
        },
        isCitiesFactoidEnabled: false,
        searchFieldsMetadata: SEARCH_FIELDS_METADATA,
    },
};
