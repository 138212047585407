import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';

export const Container = styled.div`
    display: flex;
    width: 100%;
    padding: 0 16px;

    @media (min-width: ${BREAKPOINT.lg}) {
        max-width: 1160px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 0;
        padding-left: 0;
    }
`;
