import { DEFAULT_DISTANCE_RADIUS_VALUE } from '@domains/companies/sellerPage/config/filters/distanceRadius';
import { DEFAULT_ESTATE_OPTION, ESTATE_METADATA } from '@domains/companies/sellerPage/config/filters/estate';
import { DEFAULT_MARKET_OPTION } from '@domains/companies/sellerPage/config/filters/market';
import { DEFAULT_ROOMS_NUMBER_OPTION } from '@domains/companies/sellerPage/config/filters/roomsNumber';
import { DEFAULT_TRANSACTION_OPTION } from '@domains/companies/sellerPage/config/filters/transaction';
import type { FormData, NexusFormData } from '@domains/companies/sellerPage/types/filtersData';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { BaseSyntheticEvent } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { useUpdateRoute } from './useUpdateRoute';

interface UseNexusSearchFormReturnType extends UseFormReturn<NexusFormData> {
    submit: (event?: BaseSyntheticEvent) => Promise<void>;
    clear: () => void;
}

interface UseNexusSearchFormArguments {
    formData: FormData;
    isAgency: boolean;
}

const getFormDefaultValues = ({ formData, t }: { formData: FormData; t: Translator }): NexusFormData => {
    return {
        areaMin: formData.areaMin,
        areaMax: formData.areaMax,
        priceMin: formData.priceMin,
        priceMax: formData.priceMax,
        distanceRadius: formData.distanceRadius,
        locations: formData.locations,
        investmentState: formData.investmentState,
        estate: formData.estate && {
            ...formData.estate,
            label: t(formData.estate.label),
        },
        transaction: formData.transaction && {
            ...formData.transaction,
            label: t(formData.transaction.label),
        },
        market: formData.market && {
            ...formData.market,
            label: t(formData.market.label),
        },
        roomsNumber: formData.roomsNumber && formData.roomsNumber.map((roomsNumber) => roomsNumber.value),
    };
};

export const useNexusSearchForm = ({
    formData,
    isAgency,
}: UseNexusSearchFormArguments): UseNexusSearchFormReturnType => {
    const [t] = useTranslations();
    const form = useForm<NexusFormData>({
        mode: 'onTouched',
        defaultValues: getFormDefaultValues({ formData, t }),
    });
    const { updateRoute } = useUpdateRoute();

    const submit = form.handleSubmit(async (data) => {
        await updateRoute(data);
    });

    const clear = (): void => {
        form.reset({
            estate: isAgency
                ? { ...DEFAULT_ESTATE_OPTION, label: t(DEFAULT_ESTATE_OPTION.label) }
                : ESTATE_METADATA.investment,
            transaction: { ...DEFAULT_TRANSACTION_OPTION, label: t(DEFAULT_TRANSACTION_OPTION.label) },
            roomsNumber: DEFAULT_ROOMS_NUMBER_OPTION,
            market: { ...DEFAULT_MARKET_OPTION, label: t(DEFAULT_MARKET_OPTION.label) },
            locations: null,
            distanceRadius: DEFAULT_DISTANCE_RADIUS_VALUE,
            priceMin: null,
            priceMax: null,
            areaMin: null,
            areaMax: null,
            investmentState: null,
        });
    };

    return {
        ...form,
        submit,
        clear,
    };
};
