import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';
import { GREY_UPDATED } from '@lib/styles/partials/colors';
import { theme } from '@lib/styles/themes/nexus';

export const StyledIcon = styled(Icon)`
    color: ${GREY_UPDATED.x400};
`;

export const Container = styled.div`
    display: flex;
    gap: ${theme.space[12]};
    align-items: center;
    margin-bottom: ${theme.space[16]};
    font-weight: ${theme.fontWeights[600]};

    @media (min-width: ${theme.breakpoints.md}) {
        margin-bottom: ${theme.space[32]};
    }
`;
