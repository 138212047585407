import Head from 'next/head';
import type { JSX } from 'react';

type Item = string | null;

interface Props {
    items: Item[];
}

export const ImagesPreload = ({ items }: Props): JSX.Element | null => {
    const itemsToPreload = items.filter(Boolean) as NonNullable<Item>[];

    if (itemsToPreload.length === 0) {
        return null;
    }

    return (
        <Head>
            {itemsToPreload.map((item) => (
                <link key={item} href={item} rel="preload" as="image" />
            ))}
        </Head>
    );
};
