import { Checkbox } from '@domains/shared/components/Checkbox/Checkbox';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { CheckboxInput } from '@nexus/lib-react/dist/core/CheckboxInput';
import type { LocationsObjects } from '@type/search/location/dataItems';
import { getLocationDetailedLevelTranslation } from '@widgets/search/core/helpers/getLocationDetailedLevelTranslation';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import type { UseLocationPickerService } from '../../hooks/useLocationPickerService/types';
import type { ToggleSelectOptions } from '../../types/selectOptions';
import { Container, Label, LocationDetailsContent } from './SuggestionsListItem.theme';

interface Props extends Pick<UseLocationPickerService, 'toggleSelectSuggestion'> {
    isSelected?: boolean;
    item: LocationsObjects;
    minLength: number;
    suggestionSearchQuery: string;
    shouldUseNexusTheme?: boolean;
}

export const SuggestionsListItem: FC<Props> = ({
    item,
    isSelected,
    minLength,
    suggestionSearchQuery,
    shouldUseNexusTheme,
    toggleSelectSuggestion,
}) => {
    const [t] = useTranslations();
    const identifier = `location-picker-suggestions-item-${item.id}`;

    const onChange = useCallback((): void => {
        const { name: rawName } = item;

        const options: ToggleSelectOptions = {
            calledFrom: 'location_tree',
            withAutocomplete: true,
        };

        return toggleSelectSuggestion({ ...item, name: rawName }, options);
    }, [item, toggleSelectSuggestion]);

    const label = useMemo(() => {
        const shouldHighlightQuery = suggestionSearchQuery.length >= minLength;

        const name = item.fullName;

        if (!shouldHighlightQuery) {
            return name;
        }

        return (
            name
                // create an array of words from `Piotrowice, Katowice, śląskie` splitted by comma
                .split(',')
                // highlight searched query in a location name part
                .map((part, index, elements) => {
                    const isLastItem = index === elements.length - 1;
                    const lowerCaseQuery = suggestionSearchQuery.toLocaleLowerCase();
                    const lowerCasePart = part.toLocaleLowerCase();

                    if (!lowerCasePart.includes(lowerCaseQuery)) {
                        return <span key={name + part}>{`${part}${isLastItem ? '' : ','}`}</span>;
                    }

                    // This solution possibly could be simplified with RegExp and String.replace()
                    // but it would require using dangerouslySetInnerHtml which I wanted to avoid
                    const queryStartIndex = lowerCasePart.indexOf(lowerCaseQuery);
                    const queryLastIndex = queryStartIndex + lowerCaseQuery.length;

                    if (queryStartIndex === 0) {
                        /**
                         * sample query: owi
                         *
                         * part: Owiesno
                         * result: <strong>Owi</strong>esno
                         *
                         * if query exists at the beginning of a location name part
                         */
                        return (
                            <span key={name + part}>
                                <strong>{part.slice(queryStartIndex, queryLastIndex)}</strong>
                                <span>{`${part.slice(queryLastIndex, part.length)}${isLastItem ? '' : ','}`}</span>
                            </span>
                        );
                    } else if (queryLastIndex === part.length && queryStartIndex !== 0) {
                        /**
                         * sample query: ytom
                         *
                         * part: Bytom
                         * result: B<strong>ytom</strong>
                         *
                         * if query is at the end of location name part
                         */
                        return (
                            <span key={name + part}>
                                <span>{part.slice(0, Math.max(0, queryStartIndex))}</span>
                                <strong>{part.slice(queryStartIndex, queryLastIndex)}</strong>
                                <span>{isLastItem ? '' : ','}</span>
                            </span>
                        );
                    } else if (queryLastIndex !== part.length && queryStartIndex !== 0) {
                        /**
                         * sample query: yto
                         *
                         * part: Bytom
                         * result: B<strong>yto</strong>m
                         *
                         * if query is in the middle of location name part
                         */
                        return (
                            <span key={name + part}>
                                <span>{part.slice(0, Math.max(0, queryStartIndex))}</span>
                                <strong>{part.slice(queryStartIndex, queryLastIndex)}</strong>
                                <span>{`${part.slice(queryLastIndex, part.length)}${isLastItem ? '' : ','}`}</span>
                            </span>
                        );
                    }

                    return null;
                })
        );
    }, [suggestionSearchQuery, minLength, item]);

    const CheckboxComponent = shouldUseNexusTheme ? CheckboxInput : Checkbox;

    return (
        <Container data-cy="suggestions-item">
            <CheckboxComponent id={item.id} onChange={onChange} defaultChecked={isSelected} />
            <Label htmlFor={item.id} dataCy={identifier}>
                <span>{label}</span>
                <LocationDetailsContent
                    data-testid="suggestions-item-label-detailed-location"
                    data-cy="suggestions-item-label-detailed-location"
                >
                    {getLocationDetailedLevelTranslation(t, item.detailedLevel)}
                </LocationDetailsContent>
            </Label>
        </Container>
    );
};
