import type { TooltipIcon } from '@domains/shared/components/TooltipIcon/TooltipIcon';
import { useDeferredAbortableTracking } from '@domains/shared/hooks/useDeferredAbortableTracking/useDeferredAbortableTracking';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { StyledComponent } from '@emotion/styled';
import type { ComponentProps, JSX } from 'react';

import { RefreshedLabel, StyledTooltip, TooltipText } from './AdPushedUpTooltip.theme';

export const TRACKING_DELAY = 2000;

interface Props {
    date: string;
    time: string;
    width?: 'fixed' | 'contentWidth';
    container?: StyledComponent<ComponentProps<typeof TooltipIcon>>;
}

export const AdPushedUpTooltip = ({
    date,
    time,
    width = 'contentWidth',
    container: Container = StyledTooltip,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent, abortTracking } = useDeferredAbortableTracking(TRACKING_DELAY);

    const handleShowTooltip = (): void => {
        trackEvent('tooltip_onboarding_view', {
            touch_point_button: 'bumped_offer',
        });
    };

    return (
        <Container
            width={width}
            icon={<RefreshedLabel>{t('frontend.shared.ad-pushed-up-tooltip.refreshed')}</RefreshedLabel>}
            description={
                <TooltipText>
                    {t('frontend.shared.ad-pushed-up-tooltip.refreshed-tooltip-text', { date, time })}
                </TooltipText>
            }
            onShowTooltip={handleShowTooltip}
            onHideTooltip={abortTracking}
        />
    );
};
