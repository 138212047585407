import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { theme } from '@lib/styles/themes/nexus';
import { Dialog, DialogBody } from '@nexus/lib-react/dist/core/Dialog';

export const StyledDialog = styled(Dialog)`
    height: 100%;
    padding-bottom: ${theme.space['24']};

    @media (max-width: ${BREAKPOINT.sm}) {
        width: 100%;
        max-height: 100%;
    }
`;
export const StyledDialogBody = styled(DialogBody)`
    height: 100%;
    max-height: fit-content;
    margin-top: 50px;
    padding: ${theme.space['0']};
`;
