export const getSelectedLocationsDomainId = (
    locationMappedToTrackingForm: string,
    selectedIdsTrackingRef: string[],
): string[] => {
    //additional_location_removed event
    const shouldRemoveLocation = selectedIdsTrackingRef.includes(locationMappedToTrackingForm);

    if (shouldRemoveLocation) {
        return selectedIdsTrackingRef.filter((element) => element !== locationMappedToTrackingForm);
    }

    //additional_location_included || search_box_list_selection_click events
    return [...selectedIdsTrackingRef, locationMappedToTrackingForm];
};
