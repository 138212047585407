import { SeoLinks } from '@domains/companies/sellerPage/components/SeoLinks/SeoLinks';
import { MOBILE_CONTACT_FORM_DIALOG_ID } from '@domains/companies/sellerPage/constants';
import { useShowMap } from '@domains/companies/sellerPage/hooks/useShowMap';
import { AboutUs } from '@domains/companies/sellerPage/nexusComponents/AboutUs/AboutUs';
import BasicInfoWithLogo from '@domains/companies/sellerPage/nexusComponents/BasicInfoWithLogo/BasicInfoWithLogo';
import { Breadcrumbs } from '@domains/companies/sellerPage/nexusComponents/Breadcrumbs/Breadcrumbs';
import { ContactForm } from '@domains/companies/sellerPage/nexusComponents/ContactForm/ContactForm';
import { DialogContactForm } from '@domains/companies/sellerPage/nexusComponents/DialogContactForm/DialogContactForm';
import { MobileButtonsFooter } from '@domains/companies/sellerPage/nexusComponents/MobileButtonsFooter/MobileButtonsFooter';
import { SellerAds } from '@domains/companies/sellerPage/nexusComponents/SellerAds/SellerAds';
import { Team } from '@domains/companies/sellerPage/nexusComponents/Team/Team';
import type { ContactPerson } from '@domains/companies/sellerPage/types/contactPerson';
import type { FilterAttributes, FormData } from '@domains/companies/sellerPage/types/filtersData';
import type { OwnerStatistics, SellerPageOwner } from '@domains/companies/sellerPage/types/sellerPageOwner';
import type { SellerPageSearchAds } from '@domains/companies/sellerPage/types/sellerPageSearchAds';
import type { SearchMapBoundingBoxData } from '@domains/search/types/SearchMapPins';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import { OWNER_TYPE } from '@type/ad/ownerType';
import type { FilterLocations } from '@type/location/filterLocation';
import type { SearchLinksRelatedLocations } from '@type/location/locationLink';
import type { SortingOption } from '@type/sorting/option';
import { useContext } from 'react';

import {
    BackgroundGradient,
    ContentWrapper,
    PrimarySection,
    SecondarySection,
    Wrapper,
} from './NexusSellerPageContent.theme';

interface Props {
    agencyContacts?: ContactPerson[];
    owner: SellerPageOwner;
    ownerStatistics: OwnerStatistics;
    formData: FormData;
    searchAds: SellerPageSearchAds;
    seoLinks: SearchLinksRelatedLocations;
    sortingOption: SortingOption;
    filterAttributes: FilterAttributes;
    filterLocations: FilterLocations<string | string[] | { id: unknown }[]> | null;
    mapBoundingBox: SearchMapBoundingBoxData | null;
}

const NexusSellerPageContent = ({
    owner,
    formData,
    seoLinks,
    ownerStatistics,
    searchAds,
    agencyContacts,
    sortingOption,
    filterAttributes,
    filterLocations,
    mapBoundingBox,
}: Props): JSX.Element => {
    const { isDesktop } = useContext(RWDContext);
    const { shouldShowMap, isMapFullScreen } = useShowMap();
    const { isOpen: isDialogContactFormOpen } = useDialogState(MOBILE_CONTACT_FORM_DIALOG_ID);

    const { type, companyName, contacts: developerContacts } = owner;
    const isAgency = type === OWNER_TYPE.agency;
    const { yearsOfActivity } = ownerStatistics;
    const hasActiveAds = owner.statistics.activeAds > 0;

    if (isMapFullScreen) {
        return (
            <SellerAds
                isAgency={isAgency}
                searchAds={searchAds}
                hasActiveAds={hasActiveAds}
                formData={formData}
                sortingOption={sortingOption}
                filterAttributes={filterAttributes}
                filterLocations={filterLocations}
                mapBoundingBox={mapBoundingBox}
                sellerId={owner.id}
            />
        );
    }

    return (
        <>
            <Breadcrumbs companyName={companyName} companyType={type} />
            <BackgroundGradient isAgency={isAgency} data-testid="nexus-seller-page">
                <Wrapper>
                    <BasicInfoWithLogo owner={owner} yearsOfActivity={yearsOfActivity} />
                    <ContentWrapper shouldRenderAsOneColumn={shouldShowMap}>
                        <PrimarySection isFullWidth={shouldShowMap}>
                            <AboutUs owner={owner} ownerStatistics={ownerStatistics} />
                            <SellerAds
                                isAgency={isAgency}
                                searchAds={searchAds}
                                hasActiveAds={hasActiveAds}
                                formData={formData}
                                sortingOption={sortingOption}
                                filterAttributes={filterAttributes}
                                filterLocations={filterLocations}
                                mapBoundingBox={mapBoundingBox}
                                sellerId={owner.id}
                            />
                            <Team isAgency={isAgency} contacts={agencyContacts || developerContacts} />
                        </PrimarySection>
                        <SecondarySection>
                            <ContactForm
                                ownerId={owner.id}
                                isBottomPageContactForm={!isDesktop && !isDialogContactFormOpen}
                            />
                        </SecondarySection>
                    </ContentWrapper>
                    <SeoLinks seoLinks={seoLinks} formData={formData} />
                </Wrapper>
            </BackgroundGradient>
            {isDesktop ? null : <MobileButtonsFooter phone={owner.phones[0]} />}
            {isDialogContactFormOpen ? <DialogContactForm ownerId={owner.id} /> : null}
        </>
    );
};

export default NexusSellerPageContent;
