import { SITE_CONFIG } from '@config/siteConfig';

export const getCanonicalUrl = (path: string, isUrlWithQueryParam: boolean): string => {
    const defaultCanonical = SITE_CONFIG.metadata.links.find(({ rel }) => rel === 'canonical')?.href;

    // build url from main url ("https://www.otodom.pl/" for PL) + path (with removed first "/" and without query params if exists)
    return isUrlWithQueryParam
        ? `${defaultCanonical}${path.slice(1, path.indexOf('?'))}`
        : `${defaultCanonical}${path.slice(1)}`;
};
