import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

export const List = styled.ul`
    margin: ${theme.space[0]};
    padding: ${theme.space[8]} ${theme.space[16]};
    list-style: none;

    @media ${theme.mediaQueries.lgMin} {
        padding: ${theme.space[8]} ${theme.space[0]};
    }
`;

export const ListLabelItem = styled.li`
    padding-top: ${theme.space[16]};

    @media ${theme.mediaQueries.lgMin} {
        padding: ${theme.space[8]} ${theme.space[24]} ${theme.space[0]} ${theme.space[32]};
    }
`;

export const ListLabel = styled.p<{ shouldUseNexusTheme?: boolean }>`
    margin: ${theme.space[0]};
    font-size: ${({ shouldUseNexusTheme }): CSSProperties['fontSize'] =>
        shouldUseNexusTheme ? theme.fontSizes.p3 : theme.fontSizes.p5};
    font-weight: ${theme.fontWeights[600]};
`;
