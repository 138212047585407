import { Toggle } from '@domains/shared/components/Toggle/Toggle';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { SEARCH_FORM_UNIVERSAL_FIELD } from '@type/search/searchFormUniversalField';
import type { ChangeEvent, JSX } from 'react';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { Container, StyledToggleText, Title } from './SpecialOffersSection.theme';

interface Props {
    shouldDisplayTopMargin: boolean;
    trackingEventName: string;
    className?: string;
}

export const SpecialOffersSection = ({ shouldDisplayTopMargin, trackingEventName, className }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { register } = useFormContext();
    const { trackEvent } = useTracking();
    const { onChange, ...toggleProps } = register(SEARCH_FORM_UNIVERSAL_FIELD.hasDiscount);

    const title = t('frontend.global.special-offer.title');

    const handleOnChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            onChange(event);
            trackEvent(trackingEventName, { only_special_offers: event.currentTarget.checked ? 1 : 0 });
        },
        [onChange, trackEvent, trackingEventName],
    );

    return (
        <Container shouldDisplayTopMargin={shouldDisplayTopMargin} className={className}>
            <StyledToggleText>
                <Title>{title}</Title>
            </StyledToggleText>
            <Toggle
                {...toggleProps}
                onChange={handleOnChange}
                ariaLabel={title}
                id={SEARCH_FORM_UNIVERSAL_FIELD.hasDiscount}
            />
        </Container>
    );
};
