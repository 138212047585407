import { COMPANIES_CONFIG } from '@config/companies/companiesConfig';
import { CURRENT_SITE_CODE } from '@config/siteCode';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';

const getProvinceFileName = (provinceId: string | undefined): string => {
    if (!provinceId || COMPANIES_CONFIG.sellerPage.provinceImageFile[provinceId] === undefined) {
        return 'default';
    }

    return COMPANIES_CONFIG.sellerPage.provinceImageFile[provinceId];
};

export const useProvinceBackgroundImage = (provinceId: string | undefined): { desktop: string; mobile: string } => {
    const { staticAssetsPrefix } = getPublicEnvConfig();
    const imagesRoot = `${staticAssetsPrefix}/images/companies/provinceImage/${CURRENT_SITE_CODE}`;
    const provinceFileName = getProvinceFileName(provinceId);

    return {
        desktop: `${imagesRoot}/desktop/${provinceFileName}.webp`,
        mobile: `${imagesRoot}/mobile/${provinceFileName}.webp`,
    };
};
