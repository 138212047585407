import { COMPANIES_CONFIG } from '@config/companies/companiesConfig';
import type { FormData } from '@domains/companies/sellerPage/types/filtersData';
import { ESTATE } from '@type/search/filters/estate';
import type { LocationsObjects } from '@type/search/location/dataItems';
import { FieldsFactory } from '@widgets/search/FieldsFactory';
import type { FC } from 'react';

import { InvestmentStateFieldWrapper, Wrapper } from './DeveloperFilters.theme';
import { LocationFilter } from './LocationFilter';

interface Props {
    locationsObjects: LocationsObjects[] | null;
    formData: FormData;
}

export const DeveloperFilters: FC<Props> = ({ locationsObjects, formData }) => {
    const { searchFieldsMetadata } = COMPANIES_CONFIG.sellerPage;

    return (
        <Wrapper>
            <InvestmentStateFieldWrapper>
                <FieldsFactory
                    estate={ESTATE.investment}
                    fieldsMetadata={searchFieldsMetadata}
                    fieldsMetadataExperimentsVariants={[]}
                    keys={['investmentState']}
                    shouldRenderLabels
                />
            </InvestmentStateFieldWrapper>
            <LocationFilter formData={formData} locationsObjects={locationsObjects} />
        </Wrapper>
    );
};
