import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Wrapper = styled.div`
    margin-inline: ${theme.space[16]};

    @media ${theme.mediaQueries.lgMin} {
        margin-inline: 0;
    }
`;
