import styled from '@emotion/styled';
import { WEIGHT } from '@lib/styles/partials/typography';

export const Container = styled.strong`
    font-weight: ${WEIGHT.semibold};
`;

export const Label = styled.span`
    margin-right: 4px;
    color: ${({ theme }): string => theme.deprecated.domains.shared.searchResultCount.label.color};
`;

export const Count = styled.span`
    color: ${({ theme }): string => theme.deprecated.domains.shared.searchResultCount.count.color};
`;
