import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import type { JSX, ReactNode } from 'react';
import { useCallback, useRef, useState } from 'react';

import { ContentWrapper, Label, StyledTextButton, Wrapper } from './ExpandableContent.theme';

interface Props {
    content: ReactNode;
    isTrimmable: boolean;
    trimmedContainerHeight: number;
    onToggleButtonClick?: (isExpanded: boolean) => void;
    onContentScrollEnd?: () => void;
}

export const ExpandableContent = ({
    content,
    isTrimmable,
    trimmedContainerHeight,
    onContentScrollEnd,
    onToggleButtonClick,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const endDescriptionIntersector = useRef<HTMLSpanElement>(null);
    const hasScrolledDescription = useRef<boolean>(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const shouldTrimContent = isTrimmable && !isExpanded;

    useIntersection(
        endDescriptionIntersector,
        (_, observer) => {
            if (!onContentScrollEnd || hasScrolledDescription.current) {
                return observer?.disconnect();
            }

            if (!shouldTrimContent) {
                onContentScrollEnd();
                hasScrolledDescription.current = true;
            }

            observer?.disconnect();
        },
        { threshold: 0, shouldRunOnIntersectionOnly: true },
    );

    const handleButtonClick = useCallback(() => {
        setIsExpanded((isExpanded) => !isExpanded);
        onToggleButtonClick?.(isExpanded);
    }, [isExpanded, onToggleButtonClick, setIsExpanded]);

    return (
        <Wrapper>
            <ContentWrapper
                data-testid="content-container"
                shouldTrim={shouldTrimContent}
                height={trimmedContainerHeight}
            >
                {content}
            </ContentWrapper>

            {isTrimmable ? (
                <StyledTextButton onClick={handleButtonClick} aria-expanded={isExpanded}>
                    <Label>
                        {isExpanded
                            ? t('frontend.expandable-content.show-less')
                            : t('frontend.expandable-content.show-more')}
                    </Label>
                    <Icon icon={isExpanded ? faChevronUp : faChevronDown} size="xs" />
                </StyledTextButton>
            ) : null}
            <span id="expandableContentEndIntersector" ref={endDescriptionIntersector} />
        </Wrapper>
    );
};
