import type { TreeNode } from '@type/search/location/pickerTreeNode';

import type { GqlDomainIdNode } from '../../../types/locations';
import { parseGqlNodeFactory } from './parseGqlNode';

export const parseChildrenDataAndSetTreeRef = (
    locationsData: GqlDomainIdNode[],
    treeRef: React.MutableRefObject<Map<string, TreeNode>>,
    rootItem: TreeNode,
): void => {
    const parseGqlNode = parseGqlNodeFactory(rootItem);
    for (const item of locationsData) {
        const rawChildren = item.sublocations;

        const node = parseGqlNode(item);
        const parseGqlChildrenNode = parseGqlNodeFactory(node);

        const children = rawChildren
            ? rawChildren.map((child) => {
                  const childNode = parseGqlChildrenNode(child);

                  treeRef.current.set(childNode.id, childNode);

                  return childNode;
              })
            : [];

        node.children.push(...children);
        treeRef.current.set(node.id, node);
        rootItem.children.push(node);
    }
};
