import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { P2 } from '@nexus/lib-react/dist/core/Typography';
import type { SortingOption } from '@type/sorting/option';

import { SortingDropdown, SortingDropdownWrapper } from './Sorting.theme';

export const Sorting = ({ sortingOption }: { sortingOption: SortingOption }): JSX.Element => {
    const [t] = useTranslations();

    return (
        <SortingDropdownWrapper data-cy="adverts-sorting-container">
            <P2>{t('frontend.shared.listing-sorting.sort-by')}</P2>
            <SortingDropdown sortingOption={sortingOption} />
        </SortingDropdownWrapper>
    );
};
