import { InvestmentStateBadge } from '@domains/companies/sellerPage/components/AdvertItem/components/InvestmentStateBadge';
import type {
    InvestmentEstimatedDelivery,
    InvestmentUnitsArea,
    InvestmentUnitsRoomsNumber,
} from '@domains/companies/sellerPage/types/sellerPageSearchAds';
import { TRANSLATIONS } from '@domains/search/consts/ListingItemTranslations';
import { useIsRenderedInViewport } from '@domains/search/hooks/useIsRenderedInViewport/useIsRenderedInViewport';
import { AdPushedUpTooltip } from '@domains/shared/components/AdPushedUpTooltip/AdPushedUpTooltip';
import {
    ADD_TO_FAVORITES_BUTTON_VARIANT,
    AddToFavorites,
} from '@domains/shared/components/AdvertItem/AddToFavorites/AddToFavorites';
import { ListingItemMeta } from '@domains/shared/components/AdvertItem/ListingItemMeta/ListingItemMeta';
import { SpecialOfferHeader } from '@domains/shared/components/AdvertItem/SpecialOfferHeader/SpecialOfferHeader';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { AD_PAGE_TYPE } from '@domains/shared/consts/adPageType';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { getLocationAddress } from '@domains/shared/helpers/getLocationAddress';
import { getTransactionVariant } from '@domains/shared/helpers/getTransactionVariant';
import { useAdvertImpressionsTracking } from '@domains/shared/hooks/useAdvertImpressionsTracking';
import { useGetPriceForListingItem } from '@domains/shared/hooks/useGetPriceForListingItem/useGetPriceForListingItem';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { useTracking } from '@lib/tracking/useTracking';
import type { SpecialOffer } from '@type/ad/specialOffer';
import type { ListingItemPrice } from '@type/pricing/listingItemPrice';
import type { Estate } from '@type/search/filters/estate';
import { ESTATE } from '@type/search/filters/estate';
import type { InvestmentState } from '@type/search/filters/investmentState';
import type { PeoplePerRoom } from '@type/search/filters/peoplePerRoom';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';
import type { Transaction } from '@type/search/filters/transaction';
import type { SearchAdsItemLocation } from '@type/search/location/searchAdsItemLocation';
import dayjs from 'dayjs';
import type { JSX } from 'react';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';

import {
    AdvertLink,
    Container,
    ExclusiveOfferBadge,
    ImageContainer,
    LocationLabel,
    MainContentWrapper,
    MainImage,
    MobileAddToFav,
    OpenDays,
    Title,
    TitleWrapper,
} from './AdvertItem.theme';

interface Props {
    id: number;
    isExclusiveOffer: boolean;
    estate: Estate;
    hidePrice: boolean;
    images: { medium: string; large: string }[];
    location: SearchAdsItemLocation;
    openDays: string;
    slug: string;
    title: string;
    totalPrice: ListingItemPrice | null;
    transaction: Transaction;
    specialOffer: SpecialOffer | null;
    investmentState: InvestmentState | null;
    pushedUpAt: string | null;
    priceFromPerSquareMeter: ListingItemPrice | null;
    roomsNumber: RoomsNumber | null;
    areaInSquareMeters: number | null;
    terrainAreaInSquareMeters: string | null;
    pricePerSquareMeter: ListingItemPrice | null;
    investmentUnitsAreaInSquareMeters: InvestmentUnitsArea | null;
    investmentUnitsNumber: number | null;
    peoplePerRoom: PeoplePerRoom | null;
    investmentUnitsRoomsNumber: InvestmentUnitsRoomsNumber | null;
    investmentEstimatedDelivery: InvestmentEstimatedDelivery | null;
    rentPrice: ListingItemPrice | null;
}

export const AdvertItem = ({
    id,
    isExclusiveOffer,
    estate,
    hidePrice,
    images,
    location,
    openDays,
    slug,
    title,
    totalPrice,
    transaction,
    specialOffer,
    investmentState,
    pushedUpAt,
    priceFromPerSquareMeter,
    roomsNumber,
    areaInSquareMeters,
    pricePerSquareMeter,
    investmentUnitsAreaInSquareMeters,
    peoplePerRoom,
    rentPrice,
}: Props): JSX.Element => {
    const { lang } = useSiteSettings();
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const containerRef = useRef<HTMLLIElement>(null);
    const isImageInView = useIsRenderedInViewport(containerRef);

    const [isRedirectToLoginModalOpen, setIsRedirectToLoginModalOpen] = useState(false);
    const { isDesktop } = useContext(RWDContext);

    const transactionVariant = getTransactionVariant({ estate, transaction });
    const isInvestment = estate === ESTATE.investment;
    const formattedPushUpDate = pushedUpAt && dayjs(pushedUpAt).format('DD.MM');
    const formattedPushUpTime = pushedUpAt && dayjs(pushedUpAt).format('HH:mm');

    const adPageTypeUrlPart = isInvestment ? AD_PAGE_TYPE.investment : AD_PAGE_TYPE.ad;
    const mainImage = images[0];

    const { address, mapDetails, reverseGeocoding } = location;
    const locationAddress = getLocationAddress({
        street: address.street,
        radius: mapDetails?.radius,
        locations: reverseGeocoding?.locations ?? [],
    });

    const { price, priceBeforeDiscount } = useGetPriceForListingItem({
        hidePrice,
        isInvestment,
        priceFromPerSquareMeter,
        specialOffer,
        totalPrice,
        transaction,
    });

    const addToFavoritesProps = useMemo(
        () => ({
            id,
            isRedirectToLoginModalOpen,
            setIsRedirectToLoginModalOpen,
            variant: ADD_TO_FAVORITES_BUTTON_VARIANT.secondary,
            trackingData: {
                ad_id: id,
            },
        }),
        [id, isRedirectToLoginModalOpen, setIsRedirectToLoginModalOpen],
    );

    useAdvertImpressionsTracking(containerRef, {
        touch_point_button: 'ads',
        ad_id: id,
    });

    const handleAdClick = useCallback(() => {
        trackEvent('ad_click', {
            touch_point_button: 'ads',
            ad_id: id,
        });
    }, [trackEvent, id]);

    return (
        <Container ref={containerRef} onClick={handleAdClick}>
            {specialOffer ? (
                <SpecialOfferHeader
                    discountType={specialOffer.__typename}
                    discountValue={specialOffer.discountValue}
                    displayAdditionalTitlePart={!specialOffer.details.appliesToAllUnits && isInvestment}
                />
            ) : null}

            <LocalLink passHref href={`/${lang}/${adPageTypeUrlPart}/${slug}`} prefetch={false}>
                <AdvertLink>
                    <ImageContainer>
                        {mainImage ? (
                            <>
                                <picture>
                                    <source media={`(max-width: ${BREAKPOINT.sm})`} srcSet={mainImage.medium} />
                                    <source media={`(min-width: ${BREAKPOINT.sm})`} srcSet={mainImage.large} />
                                    <MainImage
                                        src={mainImage.large}
                                        alt={title}
                                        loading={isImageInView ? undefined : 'lazy'}
                                        height={210}
                                        width={300}
                                    />
                                </picture>
                            </>
                        ) : null}

                        {isDesktop ? null : <MobileAddToFav {...addToFavoritesProps} />}

                        {isExclusiveOffer ? (
                            <ExclusiveOfferBadge variant="primary">
                                {t('frontend.companies.advert-item.otodom-only')}
                            </ExclusiveOfferBadge>
                        ) : null}

                        {investmentState ? <InvestmentStateBadge investmentState={investmentState} /> : null}

                        {openDays ? (
                            <OpenDays>{`${t('frontend.companies.advert-item.open-day')}: ${openDays}`}</OpenDays>
                        ) : null}
                    </ImageContainer>
                    <MainContentWrapper isAdRefreshed={!!pushedUpAt}>
                        {formattedPushUpDate && formattedPushUpTime ? (
                            <AdPushedUpTooltip date={formattedPushUpDate} time={formattedPushUpTime} />
                        ) : null}
                        <TitleWrapper>
                            <Title title={title}>{title}</Title>
                            {isDesktop ? <AddToFavorites {...addToFavoritesProps} /> : null}
                        </TitleWrapper>
                        <LocationLabel title={`${t(TRANSLATIONS[transactionVariant])}: ${locationAddress}`}>
                            {locationAddress}
                        </LocationLabel>
                        <ListingItemMeta
                            price={price}
                            priceBeforeChange={priceBeforeDiscount}
                            metaData={{
                                roomsNumber,
                                areaInSquareMeters,
                                pricePerSquareMeter,
                                investmentUnitsAreaInSquareMeters,
                                peoplePerRoom,
                                rentPrice,
                            }}
                            transactionVariant={transactionVariant}
                        />
                    </MainContentWrapper>
                </AdvertLink>
            </LocalLink>
        </Container>
    );
};
