import type { JSX } from 'react';

import type { ButtonAction } from '../types/action';
import { ActionIcon } from './ActionIcon';

export const ButtonActionElement = ({ text, iconProps, onClick }: ButtonAction): JSX.Element => {
    return (
        <button onClick={onClick}>
            <ActionIcon {...iconProps} />
            <span>{text}</span>
        </button>
    );
};
