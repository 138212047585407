import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Wrapper = styled.div<{ hasEvenNumberOfStatistics: boolean }>`
    display: flex;
    flex-direction: column;
    width: calc(
        ${({ hasEvenNumberOfStatistics }): CSSProperties['width'] => (hasEvenNumberOfStatistics ? '50%' : '1/3 * 100%')} -
            ${theme.space[8]}
    );
    padding: ${theme.space[28]};
    background-color: ${theme.colors.$02_gray};
    text-align: center;

    @media ${theme.mediaQueries.lgMin} {
        flex: 1;
    }
`;

export const Value = styled.span`
    color: ${theme.colors.$05_gray};
    font-size: ${theme.fontSizes[28]};
    font-weight: ${theme.fontWeights[700]};
`;

export const Label = styled.span`
    color: ${theme.colors.$05_gray};
    font-size: ${theme.fontSizes[12]};
    font-weight: ${theme.fontWeights[600]};
`;
