import { Popover } from '@domains/shared/components/Popover/Popover';
import { getTextShadow } from '@domains/shared/helpers/styles/getStylesForListingTopBarButtons';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LETTER_SPACING } from '@lib/styles/partials/typography';
import type { CSSProperties } from 'react';

export const StyledPopover = styled(Popover)`
    width: 100%;
    max-width: unset;
    border-radius: 4px;

    @media (min-width: ${BREAKPOINT.md}) {
        width: auto;
    }
`;

export const PopoverContent = styled.ul`
    margin: 0;
    padding-left: 0;
    list-style-type: none;

    & > *:first-of-type {
        padding-top: 0;
    }

    & > *:last-of-type {
        padding-bottom: 0;
    }
`;

export const PopoverItem = styled.li<{ isActive?: boolean }>`
    padding: 6px 0;
    color: ${({ theme, isActive }): CSSProperties['color'] => {
        if (isActive) {
            return theme.deprecated.domains.shared.sortingDropdown.buttonGroup.button.hover.color;
        }

        return theme.deprecated.domains.shared.sortingDropdown.buttonGroup.button.base.color;
    }};
    letter-spacing: ${LETTER_SPACING.x1};
    text-shadow: ${({ theme, isActive }): CSSProperties['textShadow'] => getTextShadow({ isActive, theme })};
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        text-shadow: ${({ theme, isActive }): CSSProperties['textShadow'] => getTextShadow({ isActive, theme })};
    }
`;
