import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${theme.space['8']};
    height: 40px;
`;

export const PhoneNumberLink = styled.a`
    color: ${theme.colors['text-global-primary']};
    font-family: ${theme.fonts.family};
    font-size: ${theme.fontSizes['14']};
    font-weight: ${theme.fontWeights.bold};
    text-decoration: none;
`;
