import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

import { List } from '../../LocationPickerShared.theme';

/**
 * Just for alias purposes
 */
export const Subregions = styled(List)`
    margin-left: ${theme.space[28]};
    padding-top: 0;
`;
