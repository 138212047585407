import { CompanyAddress } from '@domains/companies/sellerPage/components/BasicCompanyInfo/components/CompanyAddress';
import { getFormattedCompanyAddress } from '@domains/companies/sellerPage/components/BasicCompanyInfo/helpers/getFormattedCompanyAddress';
import { SellerBadgesList } from '@domains/companies/sellerPage/components/SellerBadgesList/SellerBadgesList';
import { ExpandableContent } from '@domains/shared/components/ExpandableContent/ExpandableContent';
import { Divider } from '@nexus/lib-react/dist/core/Divider';
import type { Address } from '@type/location/address';
import type { SellerBadge } from '@type/sellerBadge/sellerBadge';
import type { DetailedHTMLProps, HTMLAttributes, JSX } from 'react';

import { CompanyName, DescriptionWrapper } from './BasicCompanyInfo.theme';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    name: string;
    description: string | null;
    address: Address;
    sellerBadges?: SellerBadge[];
}

export const BasicCompanyInfo = ({ name, description, address, sellerBadges, ...props }: Props): JSX.Element => {
    const formattedAddress = getFormattedCompanyAddress(address);

    const shouldShowDivider = sellerBadges?.length !== 0;

    return (
        <div {...props}>
            <CompanyName>{name}</CompanyName>
            {formattedAddress ? <CompanyAddress formattedAddress={formattedAddress} /> : null}
            {shouldShowDivider && <Divider variant="secondary-light" />}
            <SellerBadgesList sellerBadges={sellerBadges} />
            {shouldShowDivider && <Divider variant="secondary-light" />}
            {description ? (
                <ExpandableContent
                    content={<DescriptionWrapper>{description}</DescriptionWrapper>}
                    isTrimmable={description.length >= 500}
                    trimmedContainerHeight={80}
                />
            ) : null}
        </div>
    );
};
