import { getDomainIdLocationWithLevel } from '@domains/shared/helpers/getDomainLocationTracking';
import { logError } from '@domains/shared/helpers/logger';
import { useTracking } from '@lib/tracking/useTracking';
import type { LocationDataItem } from '@type/search/location/dataItem';
import type { LocationsObjects } from '@type/search/location/dataItems';
import type { TreeNode } from '@type/search/location/pickerTreeNode';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Context } from 'urql';

import { LOC_VERSIONS } from '../../constants/locationVersion';
import { useCache } from '../useCache/useCache';
import { checkIsLocationSuggestionDataItem } from './helpers/checkIsLocationSuggestionDataItem';
import { clearLocationCache } from './helpers/clearLocationCache';
import { getSelectedLocationsDomainId } from './helpers/getSelectedLocationsDomainId';
import { parseChildrenDataAndSetTreeRef } from './helpers/parseChildrenDataAndSetTreeRef';
import { parseGqlRootNode } from './helpers/parseGqlNode';
import { runBaseLocationsQuery } from './helpers/runBaseLocationsQuery';
import { runSubLocationsQuery } from './helpers/runSubLocationsQuery';
import type { UseLocationPickerService } from './types';

const handleError = (error: unknown, data?: unknown): void => logError('location picker hook', { error, data });

const PREVIOUSLY_SELECTED_LOCATIONS_MAX = 5;

type ToggleSelectEvent =
    | 'additional_location_included'
    | 'search_box_list_selection_click'
    | 'additional_location_removed';

export const useLocationPickerService = (): UseLocationPickerService => {
    const rootsRef = useRef<TreeNode[]>([]);
    const treeRef = useRef(new Map<string, TreeNode>());
    const selectedIdsRef = useRef<LocationDataItem['id'][]>([]);
    const previouslySelectedIdsRef = useRef<LocationDataItem['id'][]>([]);
    const selectedIdsTrackingRef = useRef<string[]>([]);
    const expandedNodesRef = useRef<TreeNode[]>([]);
    const onLoadRecentNodesRef = useRef<TreeNode[]>([]);
    const currentRecentNodesRef = useRef<TreeNode[]>([]);
    const [, setLastUpdate] = useState<number>(0); // Used to trigger re-render
    const triggerChangeRef = useRef(() => setLastUpdate(Date.now()));
    const urqlClient = useContext(Context);
    const { trackEvent } = useTracking();
    const { readCache, saveCache } = useCache();

    // Fetch the base data
    useEffect(() => {
        const versionCache = readCache()[3];

        const shouldClearOldCache = versionCache === LOC_VERSIONS.sphere;

        if (shouldClearOldCache) {
            clearLocationCache();
            // Clear recent nodes, and remove the wrong currentRecentNodes when switching between location service version
            onLoadRecentNodesRef.current = [];
            currentRecentNodesRef.current = currentRecentNodesRef.current.filter((item) => !!item.detailedLevel);
        }

        //read cache after clearing
        const [rootsCache, treeCache, recentCache] = readCache();

        if (rootsCache && treeCache) {
            rootsRef.current = rootsCache;
            treeRef.current = treeCache;
            onLoadRecentNodesRef.current = [...recentCache];
            currentRecentNodesRef.current = recentCache;
            triggerChangeRef.current();

            return;
        }

        runBaseLocationsQuery(urqlClient)
            .then(({ data, error }) => {
                if (error || !data) {
                    handleError(error, data);

                    return;
                }

                const roots = data.locationTree.locationTreeObjects.map(parseGqlRootNode);

                treeRef.current = new Map(roots);
                rootsRef.current = roots.map(([, item]) => item);
                saveCache({ roots: rootsRef.current, nodes: treeRef.current });
                triggerChangeRef.current();
            })
            .catch((error) => {
                handleError(error);
            });
    }, [readCache, saveCache, urqlClient]);

    const fetchRootChildren = useCallback<(parent: TreeNode) => Promise<void>>(
        (rootItem) => {
            //difference between sphere and new location service
            const parent = rootItem.parentIds || rootItem.parent;

            // Fetch children only for root nodes, skip everything else
            if (parent || rootItem.children.length > 0) {
                return Promise.resolve();
            }

            if (rootItem.fetching) {
                return rootItem.fetching;
            }

            rootItem.fetching = runSubLocationsQuery(urqlClient, rootItem)
                .then(({ data, error }) => {
                    if (error || !data) {
                        handleError(error, data);

                        return;
                    }

                    const locationsData = data.locationTree.locationTreeObjects;

                    parseChildrenDataAndSetTreeRef(locationsData, treeRef, rootItem);

                    saveCache({ nodes: treeRef.current });
                    triggerChangeRef.current();
                })
                .catch((error) => {
                    handleError(error);
                })
                .finally(() => {
                    rootItem.fetching = undefined;
                });

            return rootItem.fetching;
        },
        [saveCache, urqlClient],
    );
    const saveRecentItem = useCallback<(item: TreeNode) => void>((item) => {
        if (currentRecentNodesRef.current.findIndex((savedItem) => savedItem.id === item.id) !== -1) {
            return;
        }
        if (currentRecentNodesRef.current.length > PREVIOUSLY_SELECTED_LOCATIONS_MAX) {
            currentRecentNodesRef.current.shift();
        }
        currentRecentNodesRef.current.push(item);
    }, []);
    const saveRecent = useCallback<UseLocationPickerService['saveRecent']>(
        () => saveCache({ recent: currentRecentNodesRef.current }),
        [saveCache],
    );

    const checkIsNodeSelected = useCallback<UseLocationPickerService['checkIsNodeSelected']>(
        (item) => selectedIdsRef.current.includes(item.id),
        [],
    );
    const checkIsAnyChildSelected: UseLocationPickerService['checkIsAnyChildSelected'] = useCallback<
        UseLocationPickerService['checkIsAnyChildSelected']
    >(
        (item) => item.children.some((child) => checkIsNodeSelected(child) || checkIsAnyChildSelected(child)),
        [checkIsNodeSelected],
    );
    const checkIsExpanded = useCallback<UseLocationPickerService['checkIsExpanded']>(
        (item: TreeNode) => expandedNodesRef.current.includes(item),
        [],
    );
    const toggleExpanded = useCallback<UseLocationPickerService['toggleExpanded']>(
        (itemToToggle) => {
            // remove from expanded parent and its children
            const newExpanded = expandedNodesRef.current.filter(
                (item) => item !== itemToToggle && !itemToToggle.children.includes(item),
            );

            if (expandedNodesRef.current.length === newExpanded.length) {
                newExpanded.push(itemToToggle);
                fetchRootChildren(itemToToggle);
            }

            expandedNodesRef.current = newExpanded;
            triggerChangeRef.current();
        },
        [fetchRootChildren],
    );
    const toggleSelectNode = useCallback<UseLocationPickerService['toggleSelectNode']>(
        (item, options) => {
            const { id } = item;

            const baseLength = selectedIdsRef.current.length;
            let event: ToggleSelectEvent = 'additional_location_removed';
            let newSelected = selectedIdsRef.current.filter((selectedId) => selectedId !== id);
            const isSelectAction = newSelected.length === baseLength;

            if (isSelectAction) {
                newSelected = [...newSelected, id];
                event = baseLength ? 'additional_location_included' : 'search_box_list_selection_click';
            }

            saveRecentItem(item);
            selectedIdsRef.current = newSelected;
            triggerChangeRef.current();

            const locationMappedToTrackingForm = getDomainIdLocationWithLevel(item);

            selectedIdsTrackingRef.current = getSelectedLocationsDomainId(
                locationMappedToTrackingForm,
                selectedIdsTrackingRef.current,
            );

            if (options.calledFrom === 'page_loaded') {
                return;
            }

            const touchPointButton =
                event === 'additional_location_included' || event === 'search_box_list_selection_click'
                    ? options.calledFrom
                    : null;
            const withAutocomplete = options?.withAutocomplete ? 1 : 0;

            trackEvent(event, {
                with_autocomplete: withAutocomplete,
                touch_point_button: touchPointButton,
                selected_locations_id: selectedIdsTrackingRef.current,
            });
        },
        [saveRecentItem, trackEvent],
    );

    const findRootParent = useCallback((item: LocationsObjects): TreeNode | undefined => {
        if (!item.parentIds) {
            return treeRef.current.get(item.id);
        }

        const parentNodes = item.parentIds.map((item) => treeRef.current.get(item)).filter(Boolean);

        return parentNodes.find((item) => !item?.parentIds || !item?.parent);
    }, []);

    const getDirectParent = useCallback((parentsIds: string[], rootParent?: TreeNode): TreeNode | undefined => {
        let parent = rootParent;

        while (parent) {
            const child = parent.children.find((item) => parentsIds.includes(item.id));

            if (!child) {
                return parent;
            }

            parent = child;
        }
    }, []);

    const toggleSelectSuggestion = useCallback<UseLocationPickerService['toggleSelectSuggestion']>(
        (item, options) => {
            if (checkIsLocationSuggestionDataItem(item)) {
                const { id, parentIds, detailedLevel, name } = item;

                const rootParent = findRootParent(item) || null;
                const createNode = (parent: TreeNode | null): TreeNode => ({
                    id,
                    name,
                    parent,
                    children: [],
                    detailedLevel,
                });
                let treeNode = treeRef.current.get(id);

                if (treeNode) {
                    toggleSelectNode({ ...treeNode, detailedLevel }, options);
                } else if (rootParent) {
                    fetchRootChildren(rootParent).then(() => {
                        treeNode = treeRef.current.get(id);
                        if (!treeNode) {
                            const parent = getDirectParent(parentIds, rootParent) || null;

                            treeNode = createNode(parent);
                            treeRef.current.set(id, treeNode);
                            if (parent) {
                                parent.children.push(treeNode);
                            }

                            saveCache({ nodes: treeRef.current });
                        }
                        toggleSelectNode({ ...treeNode, detailedLevel }, options);
                    });
                } else {
                    // the item doesn't have root in the tree - corner case
                    treeNode = createNode(null);
                    treeRef.current.set(id, treeNode);
                    toggleSelectNode({ ...treeNode, detailedLevel }, options);
                    saveCache({ nodes: treeRef.current });
                }
            } else {
                const treeNode = treeRef.current.get(item.id);

                if (treeNode) {
                    toggleSelectNode({ ...treeNode }, options);
                }
            }
        },
        [findRootParent, toggleSelectNode, fetchRootChildren, getDirectParent, saveCache],
    );
    const checkIsIdSelected = useCallback<UseLocationPickerService['checkIsIdSelected']>((suggestion) => {
        return selectedIdsRef.current.includes(suggestion.id);
    }, []);
    const clearExpanded = useCallback<UseLocationPickerService['clearExpanded']>(() => {
        expandedNodesRef.current = [];
        triggerChangeRef.current();
    }, []);
    const clearSelectedIds = useCallback<UseLocationPickerService['clearSelectedIds']>(() => {
        selectedIdsRef.current = [];
        selectedIdsTrackingRef.current = [];
    }, []);
    const getSelectedNodes = useCallback<UseLocationPickerService['getSelectedNodes']>(
        () => selectedIdsRef.current.map((id) => treeRef.current.get(id) as TreeNode),
        [],
    );

    const getLocationsTitle = useCallback<UseLocationPickerService['getLocationsTitle']>(
        () =>
            getSelectedNodes()
                .map(({ name }) => name)
                .join(' • '),
        [getSelectedNodes],
    );

    const setPreviouslySelectedIds = useCallback<UseLocationPickerService['setPreviouslySelectedIds']>(() => {
        previouslySelectedIdsRef.current = selectedIdsRef.current;
    }, []);

    const removeTemporarySelectedIds = useCallback<UseLocationPickerService['removeTemporarySelectedIds']>(() => {
        selectedIdsRef.current = previouslySelectedIdsRef.current;
    }, []);

    return {
        checkIsAnyChildSelected,
        checkIsExpanded,
        checkIsIdSelected,
        checkIsNodeSelected,
        clearExpanded,
        getSelectedNodes,
        recent: onLoadRecentNodesRef.current,
        toggleSelectSuggestion,
        roots: rootsRef.current,
        saveRecent,
        selectedIds: selectedIdsRef.current,
        clearSelectedIds,
        toggleExpanded,
        toggleSelectNode: toggleSelectNode,
        getLocationsTitle,
        treeNodes: treeRef.current,
        setPreviouslySelectedIds,
        removeTemporarySelectedIds,
    };
};
