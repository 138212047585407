import type { JSX, LabelHTMLAttributes } from 'react';

import { StyledLabel } from './FieldLabel.theme';

interface Props extends LabelHTMLAttributes<HTMLLabelElement> {
    isVisuallyHidden?: boolean;
}

export const FieldLabel = ({ children, ...props }: Props): JSX.Element => {
    return <StyledLabel {...props}>{children}</StyledLabel>;
};
