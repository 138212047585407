import { ChangeViewTypeButton } from '@domains/search/components/ChangeViewTypeButton/ChangeViewTypeButton';
import { useMapTracking } from '@domains/search/hooks/useMapTracking';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import type { ForwardRefRenderFunction, JSX } from 'react';
import { forwardRef } from 'react';

import { Container } from './ButtonWithMapBackground.theme';

const ButtonWithMapBackgroundBase: ForwardRefRenderFunction<HTMLButtonElement> = (_, ref): JSX.Element => {
    const {
        featureFlags: { isNexusSellerPageEnabled },
    } = useSiteSettings();

    const { trackViewChange } = useMapTracking();

    const handleViewChange = (): void => {
        trackViewChange({
            touch_point_button: 'map_top_listing',
        });
    };

    return (
        <Container shouldDisplayNexusBackground={!!isNexusSellerPageEnabled}>
            <ChangeViewTypeButton renderPlace="listingPanel" ref={ref} onViewChange={handleViewChange} />
        </Container>
    );
};

export const ButtonWithMapBackground = forwardRef<HTMLButtonElement>(ButtonWithMapBackgroundBase);
