import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { TreeNode } from '@type/search/location/pickerTreeNode';
import type { FC } from 'react';

import type { UseLocationPickerService } from '../../hooks/useLocationPickerService/types';
import { ListLabel, ListLabelItem } from '../../LocationPickerShared.theme';
import type { CalledFromOrigin } from '../../types/selectOptions';
import { LocationListItem } from '../LocationListItem/LocationListItem';
import { SelectedLocationsContainer } from './SelectedLocations.theme';

interface Props extends Pick<UseLocationPickerService, 'checkIsNodeSelected' | 'toggleExpanded' | 'toggleSelectNode'> {
    labelId?: string;
    label?: string;
    selectedLocations: TreeNode[];
    dataCy?: string;
    checkboxPostfix?: string;
    caller?: CalledFromOrigin;
    shouldUseNexusTheme?: boolean;
}

export const SelectedLocations: FC<Props> = ({
    labelId = 'selected-locations-label',
    checkIsNodeSelected,
    label = 'frontend.search.dropdown.selected-label',
    selectedLocations,
    dataCy = 'selected-locations',
    toggleExpanded,
    toggleSelectNode,
    caller,
    checkboxPostfix = 'selected',
    shouldUseNexusTheme,
}) => {
    const [t] = useTranslations();

    if (selectedLocations.length === 0) {
        return null;
    }

    return (
        <SelectedLocationsContainer aria-describedby={labelId} data-cy={dataCy}>
            <ListLabelItem>
                <ListLabel id={labelId} shouldUseNexusTheme={shouldUseNexusTheme}>
                    {t(label)}
                </ListLabel>
            </ListLabelItem>
            {selectedLocations.map((item) => (
                <LocationListItem
                    checkboxPostfix={checkboxPostfix}
                    key={item.id}
                    isChecked={checkIsNodeSelected(item)}
                    isPartiallyChecked={false}
                    item={item}
                    caller={caller}
                    toggleExpanded={toggleExpanded}
                    toggleSelectNode={toggleSelectNode}
                    shouldUseNexusTheme={shouldUseNexusTheme}
                />
            ))}
        </SelectedLocationsContainer>
    );
};
