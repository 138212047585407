import { ModalContactForm } from '@domains/companies/sellerPage/components/ContactForm/modal/ModalContactForm';
import { DiscoverySection } from '@domains/companies/sellerPage/components/DiscoverySection/DiscoverySection';
import { FactoidsSection } from '@domains/companies/sellerPage/components/FactoidsSection/FactoidsSection';
import { IntroSection } from '@domains/companies/sellerPage/components/IntroSection/IntroSection';
import { NavigationSection } from '@domains/companies/sellerPage/components/NavigationSection/NavigationSection';
import { ImagesPreload } from '@domains/companies/sellerPage/components/PageHead/ImagesPreload';
import { SeoMetadata } from '@domains/companies/sellerPage/components/PageHead/SeoMetadata';
import { StickyContactActions } from '@domains/companies/sellerPage/components/StickyContactActions/StickyContactActions';
import { useFetchAgencyContacts } from '@domains/companies/sellerPage/hooks/useFetchAgencyContacts';
import { useProvinceBackgroundImage } from '@domains/companies/sellerPage/hooks/useProvinceBackgroundImage';
import { useShowMap } from '@domains/companies/sellerPage/hooks/useShowMap';
import NexusSellerPageContent from '@domains/companies/sellerPage/nexusComponents/NexusSellerPageContent/NexusSellerPageContent';
import type { FilterAttributes, FormData } from '@domains/companies/sellerPage/types/filtersData';
import type { OwnerStatistics, SellerPageOwner } from '@domains/companies/sellerPage/types/sellerPageOwner';
import type { SellerPageSearchAds } from '@domains/companies/sellerPage/types/sellerPageSearchAds';
import type { SellerPageTracking } from '@domains/companies/sellerPage/types/tracking';
import { useModal } from '@domains/companies/shared/hooks/useModal';
import type { SearchMapBoundingBoxData } from '@domains/search/types/SearchMapPins';
import Toaster from '@domains/shared/components/Toast/Toaster';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { SavedAdsContextProvider } from '@domains/shared/contexts/SavedAdsContext/SavedAdsContext';
import { ViewTypeContextProvider } from '@domains/shared/contexts/ViewTypeContext';
import { getInitialMapArea } from '@domains/shared/helpers/getInitialMapArea';
import { useFavoritesSubscriptionHash } from '@domains/shared/hooks/useFavoritesSubscriptionHash/useFavoritesSubscriptionHash';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { FontProvider } from '@domains/shared/theme/FontProvider';
import type { LaquesisPageProps } from '@lib/experiments/types/laquesisPageProps';
import { withGraphQLClient } from '@lib/graphql/withGraphQLClient';
import { MainLayout } from '@lib/layouts/MainLayout/MainLayout';
import { ContentBackground } from '@lib/pages/contentBackground';
import { PageProviders } from '@lib/pages/contexts/PageProviders/PageProviders';
import type { FilterLocations } from '@type/location/filterLocation';
import type { SearchLinksRelatedLocations } from '@type/location/locationLink';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { SortingOption } from '@type/sorting/option';
import type { JSX } from 'react';
import { useContext, useMemo } from 'react';

import { MapSection } from './components/MapSection/MapSection';
import { Wrapper } from './SellerPage.theme';

export interface SellerPageProps {
    searchLinksRelatedLocations: SearchLinksRelatedLocations;
    owner: SellerPageOwner;
    searchAds: SellerPageSearchAds;
    referer: string | null;
    formData: FormData;
    sortingOption: SortingOption;
    tracking: SellerPageTracking;
    urlViewType: 'listing' | 'map';
    filterAttributes: FilterAttributes;
    filterLocations: FilterLocations<string | string[] | { id: unknown }[]> | null;
    mapBoundingBox: SearchMapBoundingBoxData | null;
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    ownerStatistics: OwnerStatistics;
}

const BaseSellerPage = ({
    owner,
    searchAds,
    searchLinksRelatedLocations,
    referer,
    formData,
    sortingOption,
    filterAttributes,
    filterLocations,
    mapBoundingBox,
    fieldsMetadataExperimentsVariants,
    ownerStatistics,
}: SellerPageProps): JSX.Element => {
    const { isDesktop } = useContext(RWDContext);
    const { isVisible: isContactModalVisible, open: openContactModal, close: closeContactModal } = useModal();
    const {
        featureFlags: { isNexusSellerPageEnabled },
    } = useSiteSettings();

    const navigationSectionBackgroundImage = useProvinceBackgroundImage(owner.address.province?.id);

    const ownerImage = owner.image?.url ?? null;
    const [firstPhoneNumber] = owner.phones;
    const hasActiveAdverts = owner.statistics.activeAds > 0;

    const { shouldShowMap, isMapFullScreen } = useShowMap();
    const isViewportMeasurable = isDesktop !== null;

    const shouldShowStickyContactActions = isViewportMeasurable && !isDesktop && !shouldShowMap;

    // adding ad to observed if needed after login
    useFavoritesSubscriptionHash();

    const initialArea = getInitialMapArea(searchAds.geometries);

    const agencyContacts = useFetchAgencyContacts({ owner, isMapDisplayed: shouldShowMap });

    if (isNexusSellerPageEnabled) {
        return (
            <>
                <SeoMetadata type={owner.type} address={owner.address} companyName={owner.companyName} />
                <ImagesPreload items={[ownerImage]} />

                <MainLayout
                    contentBackground={ContentBackground.default}
                    hasStickyPageHeader={false}
                    shouldDisplayFooter={!isMapFullScreen}
                    isMinimal
                >
                    <FontProvider>
                        <NexusSellerPageContent
                            agencyContacts={agencyContacts}
                            owner={owner}
                            ownerStatistics={ownerStatistics}
                            formData={formData}
                            seoLinks={searchLinksRelatedLocations}
                            searchAds={searchAds}
                            sortingOption={sortingOption}
                            filterAttributes={filterAttributes}
                            filterLocations={filterLocations}
                            mapBoundingBox={mapBoundingBox}
                        />
                    </FontProvider>
                </MainLayout>
            </>
        );
    }

    return (
        <>
            <Toaster />
            <SeoMetadata type={owner.type} address={owner.address} companyName={owner.companyName} />
            <ImagesPreload items={[ownerImage]} />

            <Wrapper hasBottomSpacing={shouldShowStickyContactActions}>
                <MainLayout
                    contentBackground={ContentBackground.none}
                    hasStickyPageHeader={false}
                    shouldDisplayFooter={!shouldShowMap}
                    isMinimal={shouldShowMap}
                >
                    {shouldShowMap ? (
                        <MapSection
                            fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                            owner={owner}
                            referer={referer}
                            formData={formData}
                            sortingOption={sortingOption}
                            filterAttributes={filterAttributes}
                            filterLocations={filterLocations}
                            mapBoundingBox={mapBoundingBox}
                            initialArea={initialArea || null}
                            hasActiveAdverts={hasActiveAdverts}
                            navigationSectionBackgroundImage={navigationSectionBackgroundImage}
                            ownerStatistics={ownerStatistics}
                        />
                    ) : (
                        <>
                            <NavigationSection
                                companyName={owner.companyName}
                                companyType={owner.type}
                                image={navigationSectionBackgroundImage}
                                referer={referer}
                            />
                            <IntroSection
                                logo={ownerImage}
                                name={owner.companyName}
                                description={owner.description}
                                address={owner.address}
                                phones={owner.phones}
                                onContactButtonClick={openContactModal}
                                sellerBadges={owner.sellerBadges}
                            />
                            <FactoidsSection ownerType={owner.type} factoids={ownerStatistics} />
                            <DiscoverySection
                                ownerId={owner.id}
                                ownerType={owner.type}
                                contactPeople={agencyContacts || owner.contacts}
                                adverts={searchAds.items}
                                pagination={searchAds.pagination}
                                formData={formData}
                                seoLinks={searchLinksRelatedLocations}
                                hasActiveAdverts={hasActiveAdverts}
                                sortingOption={sortingOption}
                                fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                            />
                        </>
                    )}

                    {isContactModalVisible ? (
                        <ModalContactForm
                            ownerId={owner.id}
                            logo={ownerImage}
                            companyName={owner.companyName}
                            phoneNumbers={owner.phones}
                            onDismiss={closeContactModal}
                        />
                    ) : null}

                    {shouldShowStickyContactActions ? (
                        <StickyContactActions phoneNumber={firstPhoneNumber} onMessageActionClick={openContactModal} />
                    ) : null}
                </MainLayout>
            </Wrapper>
        </>
    );
};

export const SellerPageWithProviders = (props: SellerPageProps & LaquesisPageProps): JSX.Element => {
    const { experiments, laquesisResult, tracking, urlViewType } = props;
    const trackingData = useMemo(
        () => ({
            ...tracking,
            touch_point_page: 'seller_listing',
        }),
        [tracking],
    );

    return (
        <PageProviders
            defaultTrackingData={trackingData}
            experiments={experiments}
            laquesisResult={laquesisResult}
            trackPageName="seller_listing"
        >
            <SavedAdsContextProvider>
                <ViewTypeContextProvider urlViewType={urlViewType}>
                    <BaseSellerPage {...props} />
                </ViewTypeContextProvider>
            </SavedAdsContextProvider>
        </PageProviders>
    );
};

export const SellerPage = withGraphQLClient({
    component: SellerPageWithProviders,
});
