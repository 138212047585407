import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { theme } from '@lib/styles/themes/nexus';

export const Title = styled.h3`
    margin: 0;
    color: ${({ theme }): string => theme.deprecated.domains.search.searchForm.specialOffer.color};
    font-size: ${theme.fontSizes[14]};
    font-weight: ${theme.fontWeights[600]};

    @media (min-width: ${BREAKPOINT.md}) {
        margin: 0;
    }
`;

export const Container = styled.div<{ shouldDisplayTopMargin: boolean }>`
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: 1fr 48px;
    align-items: center;
    width: 100%;
    margin-top: ${({ shouldDisplayTopMargin }): CSSProperties['marginTop'] =>
        shouldDisplayTopMargin ? '16px' : '0px'};
    padding-bottom: 12px;
    border-bottom: 1px solid;
    border-bottom-color: ${({ theme }): string =>
        theme.deprecated.domains.search.searchForm.fieldSectionSeparator.borderColor};

    @media (min-width: ${BREAKPOINT.md}) {
        display: flex;
        align-items: center;
        max-width: fit-content;
        margin-top: 0;
        padding: 0;
        border-bottom: none;
    }
`;

export const StyledToggleText = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${BREAKPOINT.md}) {
        flex-direction: row;
    }
`;
