import type { BasicPluralTranslation } from '@lib/i18n/types/plural';

export const STATISTIC = {
    yearsOfActivity: 'yearsOfActivity',
    citiesWithAds: 'citiesWithAds',
    investmentAds: 'investmentAds',
    sellAds: 'sellAds',
    rentAds: 'rentAds',
} as const;

export type StatisticId = keyof typeof STATISTIC;

export const LABEL: Record<StatisticId, BasicPluralTranslation> = {
    [STATISTIC.yearsOfActivity]: {
        one: 'frontend.companies.factoids.years-one',
        few: 'frontend.companies.factoids.years-few',
        many: 'frontend.companies.factoids.years-many',
    },
    [STATISTIC.citiesWithAds]: {
        one: 'frontend.companies.factoids.cities-one',
        few: 'frontend.companies.factoids.cities-few',
        many: 'frontend.companies.factoids.cities-many',
    },
    [STATISTIC.sellAds]: {
        one: 'frontend.companies.factoids.properties-for-sale-one',
        few: 'frontend.companies.factoids.properties-for-sale-few',
        many: 'frontend.companies.factoids.properties-for-sale-many',
    },
    [STATISTIC.investmentAds]: {
        one: 'frontend.companies.factoids.projects-one',
        few: 'frontend.companies.factoids.projects-few',
        many: 'frontend.companies.factoids.projects-many',
    },
    [STATISTIC.rentAds]: {
        one: 'frontend.companies.factoids.properties-for-rent-one',
        few: 'frontend.companies.factoids.properties-for-rent-few',
        many: 'frontend.companies.factoids.properties-for-rent-many',
    },
};

// TODO: deprecated configs to be removed in https://naspersclassifieds.atlassian.net/browse/EURE-28574

const DEPRECATED_CONFIG = [STATISTIC.yearsOfActivity, STATISTIC.citiesWithAds, STATISTIC.sellAds];

export const DEPRECATED_DEVELOPER_CONFIG = [...DEPRECATED_CONFIG, STATISTIC.investmentAds];

export const DEPRECATED_AGENCY_CONFIG = [...DEPRECATED_CONFIG, STATISTIC.rentAds];

const FACTOIDS_CONFIG = [STATISTIC.citiesWithAds, STATISTIC.sellAds];

export const DEVELOPER_FACTOIDS_CONFIG = [...FACTOIDS_CONFIG, STATISTIC.investmentAds];

export const AGENCY_FACTOIDS_CONFIG = [...FACTOIDS_CONFIG, STATISTIC.rentAds];
