import { CURRENT_SITE_CODE } from '@config/siteCode';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

const { staticAssetsPrefix } = getPublicEnvConfig();

const DEPRECATED_BACKGROUND_DIRECTORY = `${staticAssetsPrefix}/images/search/map/${CURRENT_SITE_CODE}`;
const DEPRECATED_MOBILE_BACKGROUND_URL = `${DEPRECATED_BACKGROUND_DIRECTORY}/backgroundRectangleShort.webp`;
const DEPRECATED_DESKTOP_BACKGROUND_URL = `${DEPRECATED_BACKGROUND_DIRECTORY}/backgroundRectangleLong.webp`;

const BACKGROUND_DIRECTORY = `${staticAssetsPrefix}/images/companies/map/${CURRENT_SITE_CODE}`;
const BACKGROUND_URL = `${BACKGROUND_DIRECTORY}/mapBackground.webp`;

export const Container = styled.div<{ shouldDisplayNexusBackground: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: ${({ shouldDisplayNexusBackground }): CSSProperties['padding'] =>
        shouldDisplayNexusBackground ? ' 32px 0' : '40px 0'};
    border-radius: ${theme.radii[4]};
    background-image: ${({ shouldDisplayNexusBackground }): string =>
        shouldDisplayNexusBackground ? `url(${BACKGROUND_URL})` : `url(${DEPRECATED_MOBILE_BACKGROUND_URL})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media ${theme.mediaQueries.smMin} {
        padding: ${({ shouldDisplayNexusBackground }): CSSProperties['padding'] =>
            shouldDisplayNexusBackground ? '60px 0' : '40px 0'};
    }

    @media (min-width: ${BREAKPOINT.md}) {
        padding: ${({ shouldDisplayNexusBackground }): CSSProperties['padding'] =>
            shouldDisplayNexusBackground ? '60px 0' : '50px 00'};
        background-image: ${({ shouldDisplayNexusBackground }): string =>
            shouldDisplayNexusBackground ? `url(${BACKGROUND_URL})` : `url(${DEPRECATED_DESKTOP_BACKGROUND_URL})`};
    }

    @media ${theme.mediaQueries.lgMin} {
        padding: ${({ shouldDisplayNexusBackground }): CSSProperties['padding'] =>
            shouldDisplayNexusBackground ? '100px 0' : '50px 0'};
    }
`;
