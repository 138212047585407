import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { InvestmentState } from '@type/search/filters/investmentState';

import { StateBadge } from './InvestmentStateBadge.theme';

/* eslint-disable @typescript-eslint/naming-convention -- InvestmentState is returned from BE */
const INVESTMENT_STATE_TRANSLATION: Record<InvestmentState, string> = {
    NOT_STARTED: 'frontend.companies.advert-item.investment-state-not-started',
    IN_BUILDING: 'frontend.companies.advert-item.investment-state-in-building',
    READY: 'frontend.companies.advert-item.investment-state-ready',
} as const;
/* eslint-enable @typescript-eslint/naming-convention */

export const InvestmentStateBadge = ({ investmentState }: { investmentState: InvestmentState }): JSX.Element => {
    const [t] = useTranslations();

    const state = investmentState.toUpperCase() as InvestmentState;

    return <StateBadge>{t(INVESTMENT_STATE_TRANSLATION[state])}</StateBadge>;
};
