import { LinkButton } from '@domains/shared/components/nexus/LinkButton/LinkButton';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P1 } from '@nexus/lib-react/dist/core/Typography';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 140px;
    padding-top: ${theme.space[16]};

    @media ${theme.mediaQueries.lgMin} {
        min-width: 168px;
        padding-top: ${theme.space[24]};
    }
`;

export const Name = styled(P1)`
    padding-top: ${theme.space[16]};
    font-size: ${theme.fontSizes[14]};
    font-weight: ${theme.fontWeights[700]};
    text-align: center;
    white-space: nowrap;

    @media ${theme.mediaQueries.lgMin} {
        font-size: ${theme.fontSizes[18]};
    }
`;

export const SeeProfileLink = styled(LinkButton)`
    padding-top: ${theme.space[8]};
    font-size: ${theme.fontSizes[14]};
`;
