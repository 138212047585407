/* eslint-disable no-restricted-syntax -- EURE-27021 will cover these */
export const ESTATE_METADATA = {
    all: {
        label: 'frontend.global.search.estate-all' as string,
        value: 'ALL',
    },
    house: {
        label: 'frontend.search.form-estate-house-fieldvalue' as string,
        value: 'HOUSE',
    },
    flat: {
        label: 'frontend.search.form-estate-flat-fieldvalue' as string,
        value: 'FLAT',
    },
    investment: {
        label: 'frontend.search.form-estate-investment-fieldvalue' as string,
        value: 'INVESTMENT',
    },
    room: {
        label: 'frontend.search.form-estate-room-fieldvalue' as string,
        value: 'ROOM',
    },
    terrain: {
        label: 'frontend.search.form-estate-terrain-fieldvalue' as string,
        value: 'TERRAIN',
    },
    commercial_property: {
        label: 'frontend.search.form-estate-commercialproperty-fieldvalue' as string,
        value: 'COMMERCIAL_PROPERTY',
    },
    hall: {
        label: 'frontend.search.form-estate-hall-fieldvalue' as string,
        value: 'HALL',
    },
    garage: {
        label: 'frontend.search.form-estate-garage-fieldvalue' as string,
        value: 'GARAGE',
    },
} as const;
/* eslint-enable no-restricted-syntax */

export const ESTATE_OPTIONS = Object.values(ESTATE_METADATA);

export const DEFAULT_ESTATE_OPTION = ESTATE_METADATA.all;

export const VALID_ESTATE_VALUES = ESTATE_OPTIONS.map(({ value }) => value) as ReadonlyArray<string>;

export const DEFAULT_ESTATE_VALUE = DEFAULT_ESTATE_OPTION.value;
