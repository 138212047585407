import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { theme } from '@lib/styles/themes/nexus';
import ChevronDown from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronDown';
import ChevronRight from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronRight';
import type { JSX } from 'react';

import { SEARCH_WIDGET_THEME } from '../../../../../theme/[[[SITECODE]]]';
import { LIST_ITEM_LEFT_PADDING, LIST_ITEM_RIGHT_PADDING } from '../../constants/styleVariables';

export const Label = styled.label`
    padding-left: ${theme.space[8]};
    font-weight: ${theme.fontWeights[600]};
`;

export const NexusCheckboxWrapper = styled.div`
    flex: 1;
`;

export const ListItem = styled.li`
    display: flex;
    align-items: center;
    padding: ${theme.space[16]} ${theme.space[0]};

    &:not(:last-child) {
        border-bottom: ${theme.borderWidths[1]} ${theme.borderStyles.solid};
        border-color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.borderColor};
    }

    @media ${theme.mediaQueries.lgMin} {
        padding: ${theme.space[8]} ${LIST_ITEM_RIGHT_PADDING}px ${theme.space[8]} ${LIST_ITEM_LEFT_PADDING}px;

        &:not(:last-child) {
            border-bottom: none;
        }
    }

    ${Label} {
        flex: 1;
    }
`;

const ToggleStyledIcon = styled(Icon)`
    color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.color};
`;

interface ToggleButtonProps {
    onClick: () => void;
    isRegionExpanded: boolean;
}

const ToggleGenericButton = styled.button`
    margin: ${theme.space[0]};
    padding: ${theme.space[0]};
    border: ${theme.borderWidths[0]};
    outline: ${theme.space[0]};
    background: none;
    cursor: pointer;
`;

export const ToggleButton = ({ onClick, isRegionExpanded }: ToggleButtonProps): JSX.Element => (
    <ToggleGenericButton onClick={onClick} type="button">
        <ToggleStyledIcon icon={isRegionExpanded ? faChevronDown : faChevronRight} />
    </ToggleGenericButton>
);

export const NexusToggleButton = ({ onClick, isRegionExpanded }: ToggleButtonProps): JSX.Element => (
    <ToggleGenericButton onClick={onClick} type="button">
        {isRegionExpanded ? <ChevronDown /> : <ChevronRight />}
    </ToggleGenericButton>
);
