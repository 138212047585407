import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import type { RefObject } from 'react';
import { useContext, useEffect } from 'react';

export const useScrollTrap = (ref: RefObject<HTMLElement>): { removeScrollLock: () => void } => {
    const { isDesktop } = useContext(RWDContext);

    useEffect(() => {
        if (isDesktop) {
            return;
        }

        // Note: It is needed for the cleanup function, it needs the same referance.
        const currentRefInstance = ref.current;

        if (currentRefInstance) {
            disableBodyScroll(currentRefInstance);
        }

        return (): void => {
            if (currentRefInstance) {
                enableBodyScroll(currentRefInstance);
            }
        };
    }, [isDesktop, ref]);

    const removeScrollLock = (): void => {
        if (ref.current) {
            enableBodyScroll(ref.current);
        }
    };

    return {
        removeScrollLock,
    };
};
