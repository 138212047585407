import type { ComponentProps, JSX } from 'react';
import { Fragment } from 'react';

import { AnchorActionElement } from './components/AnchorActionElement';
import { ButtonActionElement } from './components/ButtonActionElement';
import { Container } from './StickyActionButtons.theme';
import type { AnchorAction, ButtonAction } from './types/action';

const checkIsAnchorAction = (action: ButtonAction | AnchorAction): action is AnchorAction => {
    return 'href' in action;
};

interface Props extends ComponentProps<typeof Container> {
    actions: (ButtonAction | AnchorAction)[];
}

export const StickyActionButtons = ({ actions, ...containerProps }: Props): JSX.Element => {
    return (
        <Container {...containerProps}>
            {actions.map((action) => (
                <Fragment key={action.text}>
                    {checkIsAnchorAction(action) ? (
                        <AnchorActionElement {...action} />
                    ) : (
                        <ButtonActionElement {...action} />
                    )}
                </Fragment>
            ))}
        </Container>
    );
};
