import { SITE_CONFIG } from '@config/siteConfig';
import { getFormattedPrice } from '@domains/shared/helpers/getFormattedPrice';
import { getDiscountedPrice } from '@domains/shared/helpers/price/getDiscountedPrice';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { SpecialOffer } from '@type/ad/specialOffer';
import type { ListingItemPrice } from '@type/pricing/listingItemPrice';
import type { Transaction } from '@type/search/filters/transaction';
import { TRANSACTION } from '@type/search/filters/transaction';

interface Props {
    hidePrice: boolean | null;
    isInvestment: boolean;
    priceFromPerSquareMeter?: ListingItemPrice | null;
    specialOffer?: SpecialOffer | null;
    totalPrice: ListingItemPrice | null;
    transaction: Transaction;
}

interface Price {
    price: string;
    priceBeforeDiscount: string | undefined;
}

export const useGetPriceForListingItem = ({
    hidePrice,
    isInvestment,
    priceFromPerSquareMeter,
    specialOffer,
    totalPrice,
    transaction,
}: Props): Price => {
    const [t] = useTranslations();
    const areaUnit = SITE_CONFIG.defaultUnits.area;

    let price = t('frontend.global.ad.ask-for-price');
    let priceBeforeDiscount = undefined;

    if (!hidePrice && totalPrice) {
        price =
            transaction === TRANSACTION.rent
                ? `${getFormattedPrice(totalPrice)}/${t('frontend.global.price.per-month')}`
                : getFormattedPrice(totalPrice);
    }

    if (!hidePrice && isInvestment && priceFromPerSquareMeter) {
        if (specialOffer?.discountValue) {
            const discountedPrice = getDiscountedPrice(priceFromPerSquareMeter.value, specialOffer.discountValue);

            price = `${t('frontend.global.price.from')} ${getFormattedPrice({
                value: discountedPrice,
                currency: priceFromPerSquareMeter.currency,
            })}/${areaUnit}`;

            priceBeforeDiscount = `${t('frontend.global.price.from')} ${getFormattedPrice({
                ...priceFromPerSquareMeter,
                value: specialOffer.minPriceLastDays ?? priceFromPerSquareMeter.value,
            })}/${areaUnit}`;
        } else {
            price = `${t('frontend.global.price.from')} ${getFormattedPrice(priceFromPerSquareMeter)}/${areaUnit}`;
        }
    }

    return { price, priceBeforeDiscount };
};
