import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';
import { SIZE } from '@lib/styles/partials/typography';

export const Container = styled.nav`
    color: ${({ theme }): string => theme.deprecated.domains.companies.navigationSection.breadcrumbNavigation.color};
`;

export const StyledList = styled.ol`
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const StyledListItem = styled.li`
    flex-shrink: 0;

    &:last-of-type {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        flex-shrink: initial;
        overflow: hidden;
    }
`;

export const StyledLink = styled.a`
    color: inherit;
    font-size: ${SIZE.p3};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;
export const StyledLastItem = styled.span`
    color: inherit;
    font-size: ${SIZE.p3};
    text-decoration: none;
`;

export const StyledIcon = styled(Icon)`
    margin: 0 8px;
`;
