import type { FormData, NexusFormData } from '@domains/companies/sellerPage/types/filtersData';
import { checkIsOption } from '@type/search/dropdownOption';
import type { InvestmentState } from '@type/search/filters/investmentState';
import { useRouter } from 'next/router';

interface UseUpdateRouteReturnType {
    updateRoute: (data: FormData | NexusFormData) => Promise<boolean>;
}

export const useUpdateRoute = (): UseUpdateRouteReturnType => {
    const router = useRouter();

    const updateRoute = (data: FormData | NexusFormData): Promise<boolean> => {
        const url = {
            href: router.route,
            query: {
                lang: router.query.lang,
                slug: router.query.slug,

                // Note: We can't simply use anything nullish here because it would be visible in the URL.
                ...(data.estate && { estate: data.estate.value }),
                ...(data.transaction && { transaction: data.transaction.value }),
                ...(data.roomsNumber && {
                    roomsNumber: data.roomsNumber.map((roomsNumber) =>
                        typeof roomsNumber === 'string' ? roomsNumber : roomsNumber.value,
                    ),
                }),
                ...(data.market && { market: data.market.value }),
                ...(data.locations && { locations: data.locations.map(({ id }) => id) }),
                ...(data.distanceRadius && {
                    // note: when set by user from dropdown it's option, when from url it's string
                    distanceRadius: checkIsOption(data.distanceRadius)
                        ? data.distanceRadius.value
                        : String(data.distanceRadius),
                }),
                ...(data.investmentState && {
                    investmentState: checkIsOption<InvestmentState>(data.investmentState)
                        ? data.investmentState.value
                        : data.investmentState,
                }),

                // Note: Values here could be `0` so we need to check it in a different way.
                ...(data.priceMin !== null && { priceMin: data.priceMin }),
                ...(data.priceMax !== null && { priceMax: data.priceMax }),
                ...(data.areaMin !== null && { areaMin: data.areaMin }),
                ...(data.areaMax !== null && { areaMax: data.areaMax }),

                // Note: We will get rid of `currentPage` because we potentialy have different results,
                // but we should keep user preference regarding the limit if set.
                ...(router.query.pageLimit && { pageLimit: router.query.pageLimit }),

                // Note: This will preserve applied sorting which is handled outside the form
                ...(router.query.by && { by: router.query.by }),
                ...(router.query.direction && { direction: router.query.direction }),
            },
        };

        return router.push(url, undefined, { scroll: false });
    };

    return { updateRoute };
};
