import type { Locale } from '@lib/i18n/types/locale';
import { localiseRoute } from '@lib/routes/localiseRoute';

import { convertFullNameToSlug } from './convertFullNameToSlug';

interface Props {
    firstName: string;
    lastName: string;
    id: number;
    lang: Locale;
}
export const getAgentProfileUrl = ({ firstName, lastName, id, lang }: Props): string => {
    const nameAsSlug = convertFullNameToSlug({
        firstName,
        lastName,
    });
    const profileSlug = encodeURIComponent(`${nameAsSlug}-ID${id}`);
    const localisedRoute = localiseRoute(`/${lang}/profile/agents/`);

    return `${localisedRoute}${profileSlug}`;
};
