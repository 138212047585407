import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';

export const MobileStickyHeader = styled.div`
    display: flex;
    position: fixed;
    z-index: 1;
    align-items: center;
    width: 100%;
    height: ${theme.sizes[56]};
    border-bottom: 1px solid ${theme.colors['borders-global-tertiary']};
    background-color: ${theme.colors['background-global-primary']};
`;

export const MobileBackButton = styled(Button)`
    height: auto;
    padding: ${theme.space[16]};
`;

export const MobileStickyHeaderText = styled.div`
    font-size: ${theme.fontSizes[20]};
    line-height: ${theme.lineHeights[1]};
`;

export const ResetButton = styled(Button)`
    margin-left: auto;
    padding: 0 ${theme.space[16]};
    font-size: ${theme.fontSizes[12]};
    font-weight: ${theme.fontWeights[500]};
    text-transform: uppercase;
`;
