import { useTracking } from '@lib/tracking/useTracking';
import type { GoogleSearchType, GoogleSuggestion, InputOnGoogleSuggestionsLoad } from '@type/search/google';
import { useCallback, useRef, useState } from 'react';

interface UseGoogleSearch {
    googleSearchType: GoogleSearchType | null;
    googleSuggestionSearchPhrase: string;
    googleSuggestions: GoogleSuggestion[];
    openGoogleSearch: (type: GoogleSearchType) => void;
    closeGoogleSearch: () => void;
    onGoogleSuggestionsLoad: InputOnGoogleSuggestionsLoad;
    selectGoogleSuggestion: (suggestion: GoogleSuggestion) => void;
    clearGoogleSearch: () => void;
    deselectGoogleSuggestion: () => void;
    getSessionToken: () => google.maps.places.AutocompleteSessionToken;
    selectedGoogleSuggestion: GoogleSuggestion | null;
}

const THREE_MINUTES_IN_MS = 180000;

export const useGoogleSearch = ({
    initialSelectedGoogleSuggestion,
}: {
    initialSelectedGoogleSuggestion?: GoogleSuggestion | null;
} = {}): UseGoogleSearch => {
    const { trackEvent } = useTracking();
    const [googleSearchType, setGoogleSearchType] = useState<GoogleSearchType | null>(
        initialSelectedGoogleSuggestion?.type ?? null,
    );
    const [googleSuggestionSearchPhrase, setGoogleSearchValue] = useState(initialSelectedGoogleSuggestion?.name ?? '');
    const [googleSuggestions, setGoogleSuggestions] = useState(
        initialSelectedGoogleSuggestion ? [initialSelectedGoogleSuggestion] : [],
    );
    const [selectedGoogleSuggestion, setSelectedGoogleSuggestion] = useState<GoogleSuggestion | null>(
        initialSelectedGoogleSuggestion ?? null,
    );
    const sessionRef = useRef<{
        token: google.maps.places.AutocompleteSessionToken;
        createdAt: number;
    } | null>();
    const onGoogleSuggestionsLoad: InputOnGoogleSuggestionsLoad = useCallback(
        ({ searchValue, suggestions }) => {
            setGoogleSearchValue(searchValue);
            setGoogleSuggestions(suggestions);
            if (searchValue && (!suggestions || suggestions.length === 0)) {
                trackEvent('location_autocomplete_no_results', { location_string: searchValue });
            }
        },
        [trackEvent],
    );

    const openGoogleSearch = useCallback(
        (type: GoogleSearchType) => {
            setGoogleSearchType(type);
            setGoogleSearchValue(selectedGoogleSuggestion ? selectedGoogleSuggestion.name : '');
        },
        [selectedGoogleSuggestion],
    );

    const closeGoogleSearch = useCallback(() => {
        setGoogleSearchType(null);
    }, []);

    const selectGoogleSuggestion = useCallback((suggestion: GoogleSuggestion) => {
        setSelectedGoogleSuggestion(suggestion);
        setGoogleSearchValue(suggestion.name);
        sessionRef.current = null;
    }, []);

    const deselectGoogleSuggestion = useCallback(() => {
        setSelectedGoogleSuggestion(null);
    }, []);

    const clearGoogleSearch = useCallback(() => {
        setGoogleSearchValue('');
        setGoogleSuggestions([]);
        setSelectedGoogleSuggestion(null);
    }, []);

    const getSessionToken = useCallback((): google.maps.places.AutocompleteSessionToken => {
        const currentDate = Date.now();
        const currentToken = sessionRef.current?.token;
        const currentTokenCreatedAt = sessionRef.current?.createdAt;
        const isTokenCreatedMoreThan3MinutesAgo =
            currentTokenCreatedAt && Math.abs(currentTokenCreatedAt - currentDate) >= THREE_MINUTES_IN_MS;
        if (!currentToken || isTokenCreatedMoreThan3MinutesAgo) {
            const newSessionToken = new google.maps.places.AutocompleteSessionToken();
            sessionRef.current = { token: newSessionToken, createdAt: currentDate };

            return newSessionToken;
        }

        return currentToken;
    }, []);

    return {
        googleSearchType,
        googleSuggestionSearchPhrase,
        googleSuggestions,
        selectedGoogleSuggestion,
        openGoogleSearch,
        closeGoogleSearch,
        onGoogleSuggestionsLoad,
        selectGoogleSuggestion,
        clearGoogleSearch,
        deselectGoogleSuggestion,
        getSessionToken,
    };
};
