import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

export const Button = styled.button<{ shouldButtonHavePadding: boolean }>`
    padding: ${({ shouldButtonHavePadding }): CSSProperties['padding'] => (shouldButtonHavePadding ? 'revert' : 0)};
    border: none;
    background: none;
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.sizes[14]};
    font-weight: ${theme.fontWeights[400]};
    line-height: ${theme.lineHeights[18]};
    text-decoration-line: underline;
    cursor: pointer;
`;
