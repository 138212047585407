import { Label as BaseLabel } from '@domains/shared/components/Label/Label';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

import { SEARCH_WIDGET_THEME } from '../../../../../theme/[[[SITECODE]]]';

const PADDING_WITH_LOCATION_SERVICE = `${theme.space[4]} ${theme.space[24]} ${theme.space[4]} ${theme.space[32]}`;

export const Container = styled.li`
    display: flex;
    align-items: center;
    padding: ${theme.space[16]} ${theme.space[0]};

    &:not(:last-child) {
        border-bottom: ${theme.borderWidths[1]} ${theme.borderStyles.solid};
        border-color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.borderColor};
    }

    @media ${theme.mediaQueries.lgMin} {
        padding: ${PADDING_WITH_LOCATION_SERVICE};
        &:not(:last-child) {
            border-bottom: none;
        }
    }
`;

export const Label = styled(BaseLabel)`
    display: flex;
    flex-direction: column;
    padding-left: ${theme.space[16]};
    text-align: left;
`;

export const LocationDetailsContent = styled.span`
    color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.suggestionsListItem.color};
    font-size: ${theme.fontSizes[12]};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights[16]};
`;
