import { Container } from '@domains/shared/components/Container/Container';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Wrapper = styled(Container)`
    flex-wrap: wrap;
    padding-block: ${theme.space[24]};
    gap: ${theme.space[12]};

    @media ${theme.mediaQueries.lgMin} {
        flex-wrap: nowrap;
        margin-block: ${theme.space[24]};
        padding-block: 0;
        border-top: 0;
        background-color: ${theme.colors.$02_gray};
        gap: 0;
    }
`;

export const Divider = styled.span`
    width: 1px;
    margin-block: ${theme.space[28]};
    background-color: ${theme.colors.$05_gray};
`;
