import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    gap: 16px;
`;

export const StyledList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const StyledIcon = styled(Icon)`
    color: ${({ theme }): string => theme.deprecated.domains.companies.businessCard.phoneIcon.color};
`;
