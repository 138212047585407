import styled from '@emotion/styled';
import { GREY_UPDATED } from '@lib/styles/partials/colors';

export const InputGroup = styled.div`
    display: flex;
    align-items: center;
`;

export const Divider = styled.div`
    flex-shrink: 0;
    width: 4px;
    height: 1px;
    margin: 0 8px;
    background-color: ${GREY_UPDATED.x700};
`;

export const InputWrapper = styled.div`
    width: 100%;
`;
