import { BusinessCard } from '@domains/companies/sellerPage/components/BusinessCard/BusinessCard';
import { Container as PageContentSizedContainer } from '@domains/shared/components/Container/Container';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { theme } from '@lib/styles/themes/nexus';

const STYLED_BUSINESS_CARD_WIDTH = '252px';

export const Container = styled(PageContentSizedContainer)`
    position: relative;
    min-height: 192px;

    @media (max-width: ${BREAKPOINT.md}) {
        flex-direction: column;
        min-height: unset;
    }
`;

export const SmallCompanyImageWrapper = styled.div`
    display: none;
    align-self: flex-start;
    margin-top: -48px;
    padding: 8px;
    background-color: ${({ theme }): string =>
        theme.deprecated.domains.companies.introSection.smallCompanyImage.backgroundColor};

    @media (max-width: ${BREAKPOINT.md}) {
        display: initial;
    }
`;

export const BasicCompanyInfoWrapper = styled.div`
    width: calc(100% - ${STYLED_BUSINESS_CARD_WIDTH});
    margin-top: ${theme.space[48]};
    padding-right: ${theme.space[48]};

    @media (max-width: ${BREAKPOINT.md}) {
        width: 100%;
        margin: ${theme.space[12]} 0 0 0;
        padding-right: 0;
    }
`;

export const StyledBusinessCard = styled(BusinessCard)`
    position: absolute;
    top: -50px;
    right: 0;
    bottom: 0;
    width: ${STYLED_BUSINESS_CARD_WIDTH};

    @media (max-width: ${BREAKPOINT.md}) {
        display: none;
    }
`;
