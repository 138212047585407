import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { FC } from 'react';

import {
    MissingLocationFeedbackButton,
    MissingLocationFeedbackContainer,
    MissingLocationFeedbackText,
} from './MissingLocationFeedback.theme';

interface MissingLocationFeedbackProps {
    onReportButtonClick: () => void;
}

export const MissingLocationFeedback: FC<MissingLocationFeedbackProps> = ({ onReportButtonClick }) => {
    const [t] = useTranslations();

    return (
        <MissingLocationFeedbackContainer>
            <MissingLocationFeedbackText>
                {t('frontend.search.missing-location-feedback.text')}
            </MissingLocationFeedbackText>
            <MissingLocationFeedbackButton
                data-cy="search.form.missing-location-feedback.button"
                onClick={onReportButtonClick}
                type="button"
            >
                {t('frontend.search.missing-location-feedback.button')}
            </MissingLocationFeedbackButton>
        </MissingLocationFeedbackContainer>
    );
};
