import { parseError } from '@domains/shared/components/nexus/FormInput/helpers/parseError';
import { Label } from '@domains/shared/components/nexus/Label/Label';
import { VALIDATION_MESSAGE } from '@domains/shared/consts/validationMessages';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { ValidationStatus } from '@nexus/lib-react/dist/common/InputText';
import { FormControl } from '@nexus/lib-react/dist/core/FormControl';
import type { TextareaProps } from '@nexus/lib-react/dist/core/Textarea';
import { Textarea } from '@nexus/lib-react/dist/core/Textarea';
import type { FC, JSX } from 'react';
import type { FieldValues, Path, RegisterOptions } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';

interface Props<T extends FieldValues>
    extends Partial<
        Pick<
            TextareaProps,
            'defaultValue' | 'placeholder' | 'required' | 'maxLength' | 'minLength' | 'rows' | 'showSymbolsCount'
        >
    > {
    id: Path<T>;
    inputComponent?: FC;
    label?: string;
    variant?: 'light' | 'dark';
    registerOptions?: RegisterOptions;
    shouldDisplayValidationState?: boolean;
    hint?: string;
    onClick?: () => void;
    shouldUseControllerComponent?: boolean;
}

export const TextArea = <T extends FieldValues>({
    id,
    defaultValue,
    registerOptions,
    placeholder,
    inputComponent = Textarea,
    label,
    maxLength,
    minLength = 0,
    rows,
    showSymbolsCount,
    shouldDisplayValidationState = false,
    required = false,
    variant = 'light',
    hint,
    onClick,
    shouldUseControllerComponent = false,
}: Props<T>): JSX.Element => {
    const [t] = useTranslations();

    const {
        register,
        control,
        formState: { errors, touchedFields },
    } = useFormContext<T>();

    const error = parseError<T>(id, errors);

    const isTouched = touchedFields[id];

    const validationState: ValidationStatus = error ? 'hasError' : 'isFilled';
    const isValidationIconVisible =
        (error && shouldDisplayValidationState) || (!error && isTouched && shouldDisplayValidationState);

    const rules = {
        ...(required && {
            required: t(VALIDATION_MESSAGE.required),
        }),
        ...registerOptions,
    };
    const formProps = {
        inputComponent,
        label: label ? t(label) : undefined,
        labelComponent: label ? Label : undefined,
        error,
        hint,
        enableRequiredIndicator: required,
    };

    const textAreaProps = {
        id,
        defaultValue,
        required,
        placeholder: placeholder ? t(placeholder) : undefined,
        maxLength,
        minLength,
        showSymbolsCount,
        'aria-invalid': Boolean(error),
        variant,
        validationStatus: isValidationIconVisible ? validationState : undefined,
        rows,
        onClick,
    };

    return shouldUseControllerComponent ? (
        <Controller
            control={control}
            name={id}
            rules={rules}
            render={({ field: { ref, value, ...rest } }): JSX.Element => {
                return (
                    <FormControl
                        {...formProps}
                        inputProps={{
                            ref,
                            value,
                            ...textAreaProps,
                            ...rest,
                        }}
                    />
                );
            }}
        />
    ) : (
        <FormControl
            {...formProps}
            inputProps={{
                ...textAreaProps,
                ...register(id, rules),
            }}
        />
    );
};
