import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 76px 0;

    @media (max-width: ${BREAKPOINT.sm}) {
        margin: 32px 0 8px;
    }
`;

export const StyledImage = styled.img`
    @media (max-width: ${BREAKPOINT.sm}) {
        height: 84px;
    }
`;

export const TextWrapper = styled.span`
    margin-top: 40px;
    font-size: ${SIZE.h5};
    font-weight: ${WEIGHT.bold};
    text-align: center;

    @media (max-width: ${BREAKPOINT.sm}) {
        margin-top: 16px;
        font-size: ${SIZE.h6};
    }
`;
