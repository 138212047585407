import { COMPANIES_CONFIG } from '@config/companies/companiesConfig';
import { Factoid } from '@domains/companies/sellerPage/components/FactoidsSection/components/Factoid';
import type { OwnerStatistics } from '@domains/companies/sellerPage/types/sellerPageOwner';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { getTranslationVariant } from '@domains/shared/helpers/getTranslationVariant';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { OWNER_TYPE } from '@type/ad/ownerType';
import type { AdvertUserType } from '@type/user/userType';
import type { FC } from 'react';
import { Fragment, useContext } from 'react';

import type { StatisticId } from './constants';
import { DEPRECATED_AGENCY_CONFIG, DEPRECATED_DEVELOPER_CONFIG, LABEL, STATISTIC } from './constants';
import { Divider, Wrapper } from './FactoidsSection.theme';

interface Props {
    ownerType: AdvertUserType;
    factoids: OwnerStatistics;
}

export const FactoidsSection: FC<Props> = ({ ownerType, factoids }) => {
    const config = ownerType === OWNER_TYPE.developer ? DEPRECATED_DEVELOPER_CONFIG : DEPRECATED_AGENCY_CONFIG;
    const [t] = useTranslations();
    const { isDesktop } = useContext(RWDContext);
    // BE is missing the createdAt information for some businesses and returns 0-0-0000 as
    // creation date. This is a temporary fix to not show the number 2023 in factoids
    const isYearsStatisticEnabled = factoids.yearsOfActivity !== new Date().getFullYear();

    const getFactoidTranslation = (value: number | '< 1', statisticId: StatisticId): string => {
        if (value === '< 1') {
            return t(LABEL.yearsOfActivity.one);
        }

        return t(getTranslationVariant(value, LABEL[statisticId]));
    };

    if (!COMPANIES_CONFIG.sellerPage.isCitiesFactoidEnabled) {
        config.includes(STATISTIC.citiesWithAds) && config.splice(config.indexOf(STATISTIC.citiesWithAds), 1);
    }

    return (
        <Wrapper>
            {config.map((id, index) => {
                const isYearsOfActivityStatistic = id === STATISTIC.yearsOfActivity;
                const value = isYearsOfActivityStatistic && factoids[id] === 0 ? '< 1' : factoids[id];
                const label = getFactoidTranslation(value, id);

                if (!isYearsStatisticEnabled && id === STATISTIC.yearsOfActivity) {
                    return false;
                }

                return (
                    <Fragment key={id}>
                        <Factoid label={label} value={value} isYearsStatisticEnabled={isYearsStatisticEnabled} />
                        {index !== config.length - 1 && isDesktop && <Divider />}
                    </Fragment>
                );
            })}
        </Wrapper>
    );
};
