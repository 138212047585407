import { SellerBadgesList } from '@domains/companies/sellerPage/components/SellerBadgesList/SellerBadgesList';
import { Wrapper } from '@domains/companies/sellerPage/nexusComponents/AboutUs/AboutUs.theme';
import { Factoids } from '@domains/companies/sellerPage/nexusComponents/Factoids/Factoids';
import { Section } from '@domains/companies/sellerPage/nexusComponents/Section/Section';
import type { OwnerStatistics, SellerPageOwner } from '@domains/companies/sellerPage/types/sellerPageOwner';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { Divider } from '@nexus/lib-react/dist/core/Divider';
import { OWNER_TYPE } from '@type/ad/ownerType';
import type { AdvertUserType } from '@type/user/userType';
import dynamic from 'next/dynamic';

const LazyDescription = dynamic(() => import('@domains/companies/sellerPage/nexusComponents/Description/Description'), {
    ssr: false,
});

interface Props {
    owner: SellerPageOwner;
    ownerStatistics: OwnerStatistics;
}

const checkIfShouldShowFactoids = (ownerStatistics: OwnerStatistics, ownerType: AdvertUserType): boolean => {
    const { sellAds, rentAds, investmentAds, citiesWithAds } = ownerStatistics;

    return ownerType === OWNER_TYPE.agency
        ? !!(sellAds || rentAds || citiesWithAds)
        : !!(sellAds || rentAds || citiesWithAds || investmentAds);
};

export const AboutUs = ({ owner, ownerStatistics }: Props): JSX.Element | null => {
    const [t] = useTranslations();

    const { type, description, sellerBadges } = owner;

    const shouldShowFactoids = checkIfShouldShowFactoids(ownerStatistics, type);

    if (!shouldShowFactoids && !description && sellerBadges?.length === 0) {
        return null;
    }

    return (
        <Section title={t('frontend.companies.about-us.title')}>
            <Wrapper>
                {shouldShowFactoids ? <Factoids ownerStatistics={ownerStatistics} ownerType={type} /> : null}
                {sellerBadges?.length !== 0 && <Divider variant="secondary-light" />}
                <SellerBadgesList sellerBadges={sellerBadges} />
                {description ? <LazyDescription description={description} /> : null}
            </Wrapper>
        </Section>
    );
};
