import {
    getRoomNumberByStringValue,
    getRoomNumberTypologyByStringValue,
} from '@domains/shared/helpers/getRoomNumberByTheString';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { InvestmentUnitsRoomsNumber } from '@type/ad/investment';

export const createUnitsRoomsNumberLabel = (
    t: Translator,
    roomsNumber: InvestmentUnitsRoomsNumber,
    variant: 'numerical' | 'typology' = 'numerical',
): string => {
    const { min, max } = roomsNumber;

    const maxRooms = getRoomNumberByStringValue(max);

    if (maxRooms === 'more') {
        if (variant === 'typology') {
            return t('frontend.widgets.specs-list-investment.units-rooms-typology-more', {
                min: getRoomNumberTypologyByStringValue(min),
            });
        }

        return t('frontend.widgets.specs-list-investment.units-rooms-more', {
            min: getRoomNumberByStringValue(min),
        });
    }

    if (variant === 'typology') {
        return t('frontend.widgets.specs-list-investment.units-rooms-typology', {
            min: getRoomNumberTypologyByStringValue(min),
            max: getRoomNumberTypologyByStringValue(max),
        });
    }

    return t('frontend.widgets.specs-list-investment.units-rooms', {
        min: getRoomNumberByStringValue(min),
        max: getRoomNumberByStringValue(max),
    });
};
