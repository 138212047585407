import type { SortingChangeHandler } from '@domains/search/types/handleSorting';
import type { SortingVariant } from '@domains/shared/components/ListingSorting/helpers/createDropdownOptionsData';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';

import { PopoverContent, PopoverItem, StyledPopover } from './SortingOptionsPopover.theme';

interface Props {
    dataCy?: string;
    handleClosePopover: (event: MouseEvent | TouchEvent) => void;
    handleSortOptionChange: SortingChangeHandler<void>;
    isOpen: boolean;
    positionTo?: 'right' | 'left';
    sortingOptions: SortingVariant[];
    selectedSortingOption: SortingVariant;
}

export const SortingOptionsPopover = ({
    dataCy,
    handleClosePopover,
    handleSortOptionChange,
    isOpen,
    positionTo = 'right',
    selectedSortingOption,
    sortingOptions,
}: Props): JSX.Element => {
    const [t] = useTranslations();

    return (
        <StyledPopover positionTo={positionTo} isOpen={isOpen} onClose={handleClosePopover}>
            <PopoverContent data-cy={dataCy}>
                {sortingOptions.map(({ label, value: by, direction }) => {
                    const translatedLabel = t(label).toLowerCase();

                    return (
                        <PopoverItem
                            isActive={label === selectedSortingOption.label}
                            onClick={(): void => handleSortOptionChange({ direction, by, isButtonInPopover: true })}
                            key={label}
                            aria-label={translatedLabel}
                        >
                            {translatedLabel}
                        </PopoverItem>
                    );
                })}
            </PopoverContent>
        </StyledPopover>
    );
};
