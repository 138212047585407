import type { FilterOption } from './filterOption';

export const ROOMS_NUMBER_OPTIONS: RoomsNumberType = {
    one: {
        label: 'frontend.search.form-roomsnumber-one-fieldvalue',
        value: 'ONE',
        order: 1,
    },
    two: {
        label: 'frontend.search.form-roomsnumber-two-fieldvalue',
        value: 'TWO',
        order: 2,
    },
    three: {
        label: 'frontend.search.form-roomsnumber-three-fieldvalue',
        value: 'THREE',
        order: 3,
    },
    four: {
        label: 'frontend.search.form-roomsnumber-four-fieldvalue',
        value: 'FOUR',
        order: 4,
    },
    five: {
        label: 'frontend.search.form-roomsnumber-five-fieldvalue',
        value: 'FIVE',
        order: 5,
    },
    six_or_more: {
        label: 'frontend.search.form-roomsnumber-six-or-more-fieldvalue',
        value: 'SIX_OR_MORE',
        order: 6,
    },
} as const;

export const SIX_OR_MORE_ROOM_OPTIONS = ['SIX', 'SEVEN', 'EIGHT', 'NINE', 'TEN', 'MORE'];

export const ROOMS_NUMBER_OPTIONS_VALUES = Object.values(ROOMS_NUMBER_OPTIONS);

export const DEFAULT_ROOMS_NUMBER_OPTION = [];

export const VALID_ROOMS_NUMBER_VALUES = ROOMS_NUMBER_OPTIONS_VALUES.map(({ value }) => value) as ReadonlyArray<string>;

export interface RoomsNumberOptionType extends FilterOption {
    order: number;
    value: string;
}

type RoomsNumberType = Record<string, RoomsNumberOptionType>;
