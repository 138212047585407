import { COMPANIES_CONFIG } from '@config/companies/companiesConfig';
import { FactoidsSection } from '@domains/companies/sellerPage/components/FactoidsSection/FactoidsSection';
import { FiltersSection } from '@domains/companies/sellerPage/components/FiltersSection/FiltersSection';
import { IntroSection } from '@domains/companies/sellerPage/components/IntroSection/IntroSection';
import { NavigationSection } from '@domains/companies/sellerPage/components/NavigationSection/NavigationSection';
import { SearchForm } from '@domains/companies/sellerPage/components/SearchForm/SearchForm';
import type { FilterAttributes, FormData } from '@domains/companies/sellerPage/types/filtersData';
import type { OwnerStatistics, SellerPageOwner } from '@domains/companies/sellerPage/types/sellerPageOwner';
import { useModal } from '@domains/companies/shared/hooks/useModal';
import { MapView } from '@domains/search/layouts/MapView/MapView';
import type { SearchMapBoundingBoxData } from '@domains/search/types/SearchMapPins';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import type { FilterLocations } from '@type/location/filterLocation';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { FeatureGeometry } from '@type/search/location/dataItems';
import type { SortingOption } from '@type/sorting/option';
import type { JSX } from 'react';
import { useContext } from 'react';

interface SellerPageProps {
    owner: SellerPageOwner;
    referer: string | null;
    formData: FormData;
    sortingOption: SortingOption;
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    filterAttributes: FilterAttributes;
    filterLocations: FilterLocations<string | string[] | { id: unknown }[]> | null;
    mapBoundingBox: SearchMapBoundingBoxData | null;
    initialArea: FeatureGeometry | null;
    hasActiveAdverts: boolean;
    navigationSectionBackgroundImage: { desktop: string; mobile: string };
    ownerStatistics: OwnerStatistics;
}

export const MapSection = ({
    owner,
    referer,
    formData,
    sortingOption,
    fieldsMetadataExperimentsVariants,
    filterAttributes,
    filterLocations,
    mapBoundingBox,
    initialArea,
    hasActiveAdverts,
    navigationSectionBackgroundImage,
    ownerStatistics,
}: SellerPageProps): JSX.Element => {
    const { isDesktop } = useContext(RWDContext);
    const { open: openContactModal } = useModal();
    const ownerImage = owner.image?.url ?? null;
    const { lang } = useSiteSettings();

    const {
        sellerPage: { defaultBoundingBoxForMap },
    } = COMPANIES_CONFIG;

    const { isVisible: isSearchFormPoppedOut, open: detachSearchForm, close: attachSearchForm } = useModal();

    return (
        <>
            {isDesktop ? (
                <>
                    <NavigationSection
                        companyName={owner.companyName}
                        companyType={owner.type}
                        image={navigationSectionBackgroundImage}
                        referer={referer}
                    />
                    <IntroSection
                        logo={ownerImage}
                        name={owner.companyName}
                        description={owner.description}
                        address={owner.address}
                        phones={owner.phones}
                        onContactButtonClick={openContactModal}
                        sellerBadges={owner.sellerBadges}
                    />
                    <FactoidsSection ownerType={owner.type} factoids={ownerStatistics} />
                    <FiltersSection
                        fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                        ownerId={owner.id}
                        ownerType={owner.type}
                        formData={formData}
                        hasActiveAdverts={hasActiveAdverts}
                        isSearchFormPoppedOut={isSearchFormPoppedOut}
                        attachSearchForm={attachSearchForm}
                    />
                    <MapView
                        lang={lang}
                        filterLocations={filterLocations}
                        filterAttributes={filterAttributes}
                        sortingOption={sortingOption}
                        initialBounds={mapBoundingBox?.boundingBox || defaultBoundingBoxForMap}
                        initialArea={initialArea}
                        handleOpenSearchFormModal={detachSearchForm}
                    />
                </>
            ) : null}

            {isDesktop ? null : (
                <>
                    {hasActiveAdverts && isSearchFormPoppedOut ? (
                        <SearchForm
                            fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                            formData={formData}
                            ownerId={owner.id}
                            ownerType={owner.type}
                            onExitButtonClick={attachSearchForm}
                            onSubmit={attachSearchForm}
                        />
                    ) : null}

                    <MapView
                        lang={lang}
                        filterLocations={filterLocations}
                        filterAttributes={filterAttributes}
                        sortingOption={sortingOption}
                        initialBounds={mapBoundingBox?.boundingBox || defaultBoundingBoxForMap}
                        initialArea={initialArea}
                        handleOpenSearchFormModal={detachSearchForm}
                    />
                </>
            )}
        </>
    );
};
