import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { WEIGHT } from '@lib/styles/partials/typography';

export const Container = styled.div`
    display: flex;
    justify-content: center;

    @media (min-width: ${BREAKPOINT.md}) {
        justify-content: space-between;
    }
`;

export const PageLimitInputWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const PageLimitInputLabel = styled.label`
    margin-right: 8px;
    font-weight: ${WEIGHT.semibold};
`;
