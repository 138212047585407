import { AdGallery } from '@domains/companies/sellerPage/nexusComponents/SellerAds/components/AdvertCard/components/AdGallery/AdGallery';
import { AdInfo } from '@domains/companies/sellerPage/nexusComponents/SellerAds/components/AdvertCard/components/AdInfo/AdInfo';
import type { AdvertItem } from '@domains/companies/sellerPage/types/sellerPageSearchAds';
import { generateAdvertLink } from '@domains/shared/helpers/generateAdvertLink';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { ESTATE } from '@type/search/filters/estate';

import { OpenDaysBanner, SpecialOfferBanner, Wrapper } from './AdvertCard.theme';

interface Props {
    ad: AdvertItem;
}

export const AdvertCard = ({ ad }: Props): JSX.Element => {
    const [t] = useTranslations();

    const { slug, estate, specialOffer, openDays } = ad;

    const isInvestment = estate === ESTATE.investment;
    const advertLink = generateAdvertLink({ slug: slug, isInvestment });

    return (
        <Wrapper>
            {specialOffer && (
                <SpecialOfferBanner
                    discountType={specialOffer.__typename}
                    discountValue={specialOffer.discountValue}
                    displayAdditionalTitlePart={!specialOffer.details.appliesToAllUnits && isInvestment}
                />
            )}
            <AdGallery ad={ad} advertLink={advertLink} />
            <AdInfo ad={ad} />
            {openDays && (
                <OpenDaysBanner>
                    {t('frontend.companies-seller-page.advert-card.open-day')}: {openDays}
                </OpenDaysBanner>
            )}
        </Wrapper>
    );
};
