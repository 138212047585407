import { Button } from '@domains/shared/components/Button/Button';
import styled from '@emotion/styled';

export const StyledButton = styled(Button)`
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    padding: 6px 10px;
    gap: 8px;
`;
