import type { HTMLAttributes, JSX, ReactNode } from 'react';

import { Wrapper } from './Badge.theme';

export type BadgeVariant = 'default' | 'primary' | 'success' | 'info' | 'error';

interface Props extends HTMLAttributes<HTMLSpanElement> {
    variant?: BadgeVariant;
    children: ReactNode;
    shouldReverseColors?: boolean;
    type?: string;
}

export const Badge: (props: Props) => JSX.Element = ({
    variant = 'default',
    shouldReverseColors,
    children,
    ...htmlProps
}) => (
    <Wrapper variant={variant} shouldReverseColors={shouldReverseColors} {...htmlProps}>
        {children}
    </Wrapper>
);
