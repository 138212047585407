import { SITE_CONFIG } from '@config/siteConfig';
import { getFormattedCompanyAddress } from '@domains/companies/sellerPage/components/BasicCompanyInfo/helpers/getFormattedCompanyAddress';
import type { SellerPageOwner } from '@domains/companies/sellerPage/types/sellerPageOwner';
import { PhoneNumberReveal } from '@domains/shared/components/nexus/PhoneNumberReveal/PhoneNumberReveal';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import PhoneIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Telephone';
import { useContext } from 'react';

import {
    BasicInfo,
    CompanyName,
    HQAddress,
    Image,
    LogoWrapper,
    PhoneNumbers,
    PhoneNumbersWrapper,
    StyledTag,
    Wrapper,
} from './BasicInfoWithLogo.theme';

interface Props {
    owner: SellerPageOwner;
    yearsOfActivity: number;
}

const TRANSLATION = {
    addressLabel: 'frontend.companies.basic-info-with-logo.address-label',
    onPlatformSince: 'frontend.companies.basic-info-with-logo.on-platform-since',
    logoPlaceholderAlt: 'frontend.global.image.logo-placeholder',
    logoAlt: 'frontend.global.image.logo',
} as const;

const BasicInfoWithLogo = ({ owner, yearsOfActivity }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { isDesktop } = useContext(RWDContext);
    const {
        metadata: { siteName },
    } = SITE_CONFIG;
    const { staticAssetsPrefix } = getPublicEnvConfig();

    const { companyName, address, image, phones } = owner;

    const iconSize = isDesktop ? 'icon-medium' : 'icon-small';

    const formattedAddress = getFormattedCompanyAddress(address);

    const year = (new Date().getFullYear() - yearsOfActivity).toString();
    const tagContent = t(TRANSLATION.onPlatformSince, { siteName, year });

    const logoSource = image?.url || `${staticAssetsPrefix}/images/companies/companyImagePlaceholder.webp`;
    const altText = image?.url ? `${companyName} ${t(TRANSLATION.logoAlt)}` : t(TRANSLATION.logoPlaceholderAlt);

    const trackNumberRevealClick = (): void => {
        trackEvent('reply_phone_show', { touch_point_button: 'contact_us_header' });
    };

    return (
        <Wrapper>
            <LogoWrapper data-cy="seller-logo">
                <Image alt={altText} src={logoSource} loading="lazy" />
            </LogoWrapper>
            <BasicInfo data-cy="seller-basic-info">
                <CompanyName>{companyName}</CompanyName>
                {phones.length > 0 && (
                    <PhoneNumbersWrapper>
                        <PhoneIcon size={iconSize} />
                        <PhoneNumbers>
                            {phones.map((phone) => (
                                <PhoneNumberReveal
                                    key={phone}
                                    phone={phone}
                                    onReveal={trackNumberRevealClick}
                                    shouldRenderIcon={false}
                                    shouldButtonHavePadding={false}
                                />
                            ))}
                        </PhoneNumbers>
                    </PhoneNumbersWrapper>
                )}
                {formattedAddress && (
                    <HQAddress>
                        <b>{t(TRANSLATION.addressLabel)}</b> {formattedAddress}
                    </HQAddress>
                )}
                <StyledTag content={tagContent} />
            </BasicInfo>
        </Wrapper>
    );
};

export default BasicInfoWithLogo;
