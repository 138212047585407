import { SearchForm } from '@domains/companies/sellerPage/components/SearchForm/SearchForm';
import type { FormData } from '@domains/companies/sellerPage/types/filtersData';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { AdvertUserType } from '@type/user/userType';
import type { JSX } from 'react';
import { useContext } from 'react';

import { StyledTitle, TopSectionWrapper } from './FiltersSection.theme';

interface Props {
    ownerId: string;
    ownerType: AdvertUserType;
    formData: FormData;
    hasActiveAdverts: boolean;
    attachSearchForm: () => void;
    isSearchFormPoppedOut: boolean;
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
}

export const FiltersSection = ({
    ownerId,
    ownerType,
    formData,
    hasActiveAdverts,
    attachSearchForm,
    isSearchFormPoppedOut,
    fieldsMetadataExperimentsVariants,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { isDesktop } = useContext(RWDContext);
    const shouldDisplaySearchForm = isDesktop || isSearchFormPoppedOut;

    return (
        <>
            {hasActiveAdverts ? (
                <TopSectionWrapper>
                    <StyledTitle data-cy="adverts-filters-title">
                        {t('frontend.companies.filters-section.title')}
                    </StyledTitle>
                    {shouldDisplaySearchForm ? (
                        <SearchForm
                            fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                            formData={formData}
                            ownerId={ownerId}
                            ownerType={ownerType}
                            onExitButtonClick={attachSearchForm}
                            onSubmit={attachSearchForm}
                        />
                    ) : null}
                </TopSectionWrapper>
            ) : null}
        </>
    );
};
