import type { FilterOption } from '@domains/companies/sellerPage/config/filters/filterOption';
import { TRANSACTION_OPTIONS } from '@domains/companies/sellerPage/config/filters/transaction';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useCallback } from 'react';

import { ControlledDropdown } from '../components/ControlledDropdown';

export const TransactionField = (): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const handleTrackOnMenuOpen = useCallback(() => {
        trackEvent('category_click');
    }, [trackEvent]);

    const handleTrackOnSelect = useCallback(
        (option: FilterOption | null): void => {
            trackEvent('category_selected', { business: option?.value });
        },
        [trackEvent],
    );

    return (
        <ControlledDropdown
            label={t('frontend.companies.search-form.transaction-label')}
            name="transaction"
            options={TRANSACTION_OPTIONS}
            trackOnMenuOpen={handleTrackOnMenuOpen}
            trackOnSelect={handleTrackOnSelect}
        />
    );
};
