import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LAYER } from '@lib/styles/partials/layers';
import { SIZE } from '@lib/styles/partials/typography';

import { TextButton } from '../TextButton/TextButton';

export const Wrapper = styled.div`
    font-size: ${SIZE.p4};

    @media (min-width: ${BREAKPOINT.md}) {
        font-size: ${SIZE.p3};
    }
`;

export const StyledTextButton = styled(TextButton)`
    display: flex;
    align-items: center;
    margin-top: 12px;
    padding: 12px 0;
`;

export const Label = styled.span`
    padding-right: 8px;
`;

export const ContentWrapper = styled.div<{ shouldTrim: boolean; height: number }>`
    position: relative;
    z-index: ${LAYER.x0};
    height: ${({ shouldTrim, height }): string => (shouldTrim ? `${height}px` : 'auto')};
    overflow: hidden;

    &::after {
        content: '';
        display: ${({ shouldTrim }): string => (shouldTrim ? 'block' : 'none')};
        position: absolute;
        z-index: ${LAYER.x1};
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        transition: opacity 0.2s ease-out;
        background-image: linear-gradient(rgb(255 255 255 / 0%) 0%, rgb(255 255 255) 100%);
        pointer-events: none;
    }
`;
