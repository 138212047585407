import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';
import { useContext } from 'react';

import { CoreContactForm } from '../core/CoreContactForm';
import { useFormEventHandlersFactory } from '../core/hooks/useFormEventHandlersFactory';
import { StyledHeading } from './SidebarContactForm.theme';

interface Props {
    ownerId: string;
}

export const SidebarContactForm = ({ ownerId }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { isDesktop } = useContext(RWDContext);

    const eventTouchPointButton = isDesktop ? 'aside_form' : 'mobile_footer';
    const formEventHandlers = useFormEventHandlersFactory({ eventTouchPointButton });

    return (
        <CoreContactForm
            ownerId={ownerId}
            header={<StyledHeading>{t('frontend.companies.contact-form.sidebar-header-title')}</StyledHeading>}
            onValid={formEventHandlers.handleFormValid}
            onInvalid={formEventHandlers.handleFormInvalid}
            onRequestSuccess={formEventHandlers.handleFormRequestSuccess}
            onRequestFailure={formEventHandlers.handleFormRequestFailure}
            onFieldFocus={formEventHandlers.handleFieldFocus}
            onFieldBlur={formEventHandlers.handleFieldBlur}
        />
    );
};
