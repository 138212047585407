import type { FieldsMetadata } from '@type/search/fields';

export const SEARCH_FIELDS_METADATA: FieldsMetadata = {
    /* eslint-disable @typescript-eslint/naming-convention -- Disabled to keep the backward compatibility */
    estate: {
        default: {
            fieldType: 'select',
            payload: {
                defaultValue: 'ALL',
                options: {
                    DEFAULT: [
                        'ALL',
                        'FLAT',
                        'HOUSE',
                        'INVESTMENT',
                        'ROOM',
                        'TERRAIN',
                        'COMMERCIAL_PROPERTY',
                        'HALL',
                        'GARAGE',
                    ],
                },
            },
        },
    },
    market: {
        default: {
            fieldType: 'select',
            payload: {
                defaultValue: 'ALL',
                options: {
                    DEFAULT: ['ALL', 'PRIMARY', 'SECONDARY'],
                },
            },
        },
    },
    transaction: {
        default: {
            fieldType: 'select',
            payload: {
                defaultValue: 'SELL',
                options: {
                    DEFAULT: ['RENT', 'SELL'],
                    INVESTMENT: ['SELL'],
                    ROOM: ['RENT'],
                },
            },
        },
    },
    distanceRadius: {
        default: {
            fieldType: 'select',
            payload: {
                defaultValue: '0',
                options: {
                    DEFAULT: ['0', '5', '10', '15', '25', '50', '75'],
                    METERS: ['100', '200', '300', '500', '750', '1000'],
                },
            },
        },
    },
    price: {
        default: {
            fieldType: 'range',
            payload: {
                defaultValue: '',
            },
        },
    },
    area: {
        default: {
            fieldType: 'range',
            payload: {
                defaultValue: '',
            },
        },
    },
    roomsNumber: {
        default: {
            fieldType: 'multiCheckbox',
            only: ['FLAT_SELL', 'FLAT_RENT', 'HOUSE_SELL', 'HOUSE_RENT', 'INVESTMENT_SELL', 'COMMERCIAL_PROPERTY_SELL'],
            payload: {
                isInline: true,
                defaultValue: [],
                options: {
                    DEFAULT: ['ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX_OR_MORE'],
                },
            },
        },
    },
    investmentState: {
        default: {
            fieldType: 'select',
            only: ['INVESTMENT_SELL'],
            payload: {
                defaultValue: null,
                options: {
                    INVESTMENT: ['NOT_STARTED', 'IN_BUILDING', 'READY'],
                },
            },
        },
    },
    /* eslint-enable @typescript-eslint/naming-convention */
};
