import { StickyActionButtons } from '@domains/companies/shared/components/StickyActionButtons/StickyActionButtons';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';

interface Props {
    phoneNumber: string;
    onMessageActionClick: () => void;
}

export const StickyContactActions = ({ phoneNumber, onMessageActionClick }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const handleCallActionClick = (): void => {
        trackEvent('reply_phone_call', {
            touch_point_button: 'mobile_footer',
        });
    };

    const handleMessageActionClick = (): void => {
        onMessageActionClick();
        trackEvent('reply_message_form_click', {
            touch_point_button: 'mobile_footer',
        });
    };

    return (
        <StickyActionButtons
            data-cy="sticky-contact-actions"
            actions={[
                {
                    text: t('frontend.global.communication.call'),
                    href: `tel:${phoneNumber}`,
                    onClick: handleCallActionClick,
                    iconProps: { icon: faPhone, flip: 'horizontal' },
                },
                {
                    text: t('frontend.global.communication.write'),
                    onClick: handleMessageActionClick,
                    iconProps: { icon: faEnvelope },
                },
            ]}
        />
    );
};
