import type { Agent } from '@domains/companies/sellerPage/types/agentContact';
import type { ContactPerson } from '@domains/companies/sellerPage/types/contactPerson';

export const formatContactPerson = (contact: Agent): ContactPerson => {
    const { userID, profileID, firstName, lastName, mobile, photoURL } = contact;

    return {
        id: userID,
        profileId: profileID,
        name: {
            firstName,
            lastName,
        },
        phones: [mobile],
        image: {
            url: photoURL,
        },
    };
};
