import type { AdvertItem } from '@domains/companies/sellerPage/types/sellerPageSearchAds';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import {
    checkTimeSinceAdCreationWithinLimit,
    getDaysSinceAdCreation,
} from '@domains/shared/helpers/advertCreationTime';
import { useTracking } from '@lib/tracking/useTracking';
import Gallery from '@widgets/AdvertCard/Gallery';
import { Tag } from '@widgets/AdvertCard/Tag';
import { useState } from 'react';

import { Image, TagsWrapper, Wrapper } from './AdGallery.theme';

interface Props {
    ad: AdvertItem;
    advertLink: string;
}

export const AdGallery = ({ ad, advertLink }: Props): JSX.Element => {
    const [activeSlide, setActiveSlide] = useState(0);
    const { trackEvent } = useTracking();

    const { images, title, isExclusiveOffer, investmentState, dateCreatedFirst, id } = ad;
    const daysSinceAdCreation = getDaysSinceAdCreation(dateCreatedFirst);
    const shouldShowAdvertCreationBadge = checkTimeSinceAdCreationWithinLimit(daysSinceAdCreation);

    const onChangeHandler = (slideIndex: number): void => {
        setActiveSlide(slideIndex);

        trackEvent('gallery_open_swipe', {
            touch_point_button: 'ads',
        });
    };

    const trackAdClick = (): void => {
        trackEvent('ad_click', {
            touch_point_button: 'ads',
            ad_id: id,
        });
    };

    return (
        <Wrapper>
            <TagsWrapper>
                {isExclusiveOffer ? <Tag isExclusiveOffer /> : null}
                {investmentState ? <Tag investmentState={investmentState} /> : null}
                {shouldShowAdvertCreationBadge ? <Tag dateCreated={daysSinceAdCreation} /> : null}
            </TagsWrapper>
            <Gallery controlledActive={activeSlide} onChange={onChangeHandler}>
                {images.map((url, index) => (
                    <LocalLink key={url.medium} href={advertLink} passHref prefetch={false}>
                        <a onClick={trackAdClick}>
                            <Image alt={`${title}-${index}`} src={url.medium} />
                        </a>
                    </LocalLink>
                ))}
            </Gallery>
        </Wrapper>
    );
};
