import { MobileFilters } from '@domains/companies/sellerPage/nexusComponents/MobileFilters/MobileFilters';
import { NexusListingSorting } from '@domains/shared/components/ListingSorting/NexusListingSorting';
import { ViewTypeContext } from '@domains/shared/contexts/ViewTypeContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { Badge } from '@nexus/lib-react/dist/core/Badge';
import FilterIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Filter';
import MapViewIcon from '@nexus/lib-react/dist/core/Icon/icons/default/MapView';
import SortByIcon from '@nexus/lib-react/dist/core/Icon/icons/default/SortBy';
import type { SortingOption } from '@type/sorting/option';
import { SearchActionButtons } from '@widgets/search/SearchActionButtons';
import { type FC, type ReactNode, useContext, useState } from 'react';

import { Wrapper } from './MobileListingButtons.theme';

interface Props {
    filters: ReactNode;
    onFiltersOpen: () => void;
    areFiltersOpen: boolean;
    sortingOption: SortingOption;
    selectedFiltersNumber: number;
}

export const MobileListingButtons: FC<Props> = ({
    filters,
    onFiltersOpen,
    areFiltersOpen,
    sortingOption,
    selectedFiltersNumber,
}) => {
    const [t] = useTranslations();
    const [isSortingOpen, setSortingOpen] = useState(false);
    const { updateViewType } = useContext(ViewTypeContext);

    return (
        <>
            <Wrapper>
                <SearchActionButtons
                    actions={[
                        {
                            icon: FilterIcon,
                            text: t('frontend.companies-seller-page.mobile-listing-buttons.filters'),
                            suffix:
                                selectedFiltersNumber > 0 ? (
                                    <Badge
                                        size="small"
                                        value={selectedFiltersNumber}
                                        data-cy="adverts-filters-selected-badge"
                                    />
                                ) : null,
                            onClick: (): void => {
                                onFiltersOpen();
                            },
                            dataCy: 'adverts-filters-open-button',
                        },
                        {
                            icon: MapViewIcon,
                            text: t('frontend.companies-seller-page.mobile-listing-buttons.map'),
                            onClick: (): void => {
                                updateViewType('map');
                            },
                        },
                        {
                            icon: SortByIcon,
                            text: t('frontend.companies-seller-page.mobile-listing-buttons.sort'),
                            onClick: (): void => {
                                setSortingOpen(true);
                            },
                            dataCy: 'adverts-sorting-open-button',
                        },
                    ]}
                />
            </Wrapper>
            {areFiltersOpen && <MobileFilters filters={filters} />}
            {isSortingOpen && (
                <NexusListingSorting
                    sortingOption={sortingOption}
                    shouldDisplayAsModal
                    onCloseModalClick={() => {
                        setSortingOpen(false);
                    }}
                />
            )}
        </>
    );
};
