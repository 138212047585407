import type { ComponentProps, JSX } from 'react';

import { InlineLabel } from './Label.theme';

interface Props extends Pick<ComponentProps<'label'>, 'children' | 'className' | 'htmlFor'> {
    dataCy?: string;
}

export const Label = ({ children, className, htmlFor, dataCy = 'label' }: Props): JSX.Element => {
    return (
        <InlineLabel className={className} htmlFor={htmlFor} data-cy={dataCy}>
            {children}
        </InlineLabel>
    );
};
