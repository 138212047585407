import { CACHE_PREFIX } from '@domains/shared/consts/cachePrefix';
import { removeStorageItem } from '@domains/shared/helpers/storage';

import {
    STORAGE_KEY_NODES,
    STORAGE_KEY_RECENTS,
    STORAGE_KEY_ROOTS,
    STORAGE_KEY_VERSION,
} from '../../useCache/constants';

const LOCATION_CACHE_ITEMS = [STORAGE_KEY_NODES, STORAGE_KEY_ROOTS, STORAGE_KEY_RECENTS, STORAGE_KEY_VERSION];

export const clearLocationCache = (): void => {
    for (const cacheItem of LOCATION_CACHE_ITEMS) {
        removeStorageItem(cacheItem, { keyPrefix: CACHE_PREFIX });
    }
};
