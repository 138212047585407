import { COMPANIES_CONFIG } from '@config/companies/companiesConfig';
import { ButtonWithMapBackground } from '@domains/companies/sellerPage/components/ButtonWithMapBackground/ButtonWithMapBackground';
import { countFilters } from '@domains/companies/sellerPage/helpers/countFilters';
import { useShowMap } from '@domains/companies/sellerPage/hooks/useShowMap';
import { MobileFilters } from '@domains/companies/sellerPage/nexusComponents/MobileFilters/MobileFilters';
import { MobileListingButtons } from '@domains/companies/sellerPage/nexusComponents/MobileListingButtons/MobileListingButtons';
import { AdsFilters } from '@domains/companies/sellerPage/nexusComponents/SellerAds/components/AdsFilters/AdsFilters';
import { AdsList } from '@domains/companies/sellerPage/nexusComponents/SellerAds/components/AdsList/AdsList';
import type { FilterAttributes, FormData } from '@domains/companies/sellerPage/types/filtersData';
import type { SellerPageSearchAds } from '@domains/companies/sellerPage/types/sellerPageSearchAds';
import { useModal } from '@domains/companies/shared/hooks/useModal';
import { MapView } from '@domains/search/layouts/MapView/MapView';
import type { SearchMapBoundingBoxData } from '@domains/search/types/SearchMapPins';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { getInitialMapArea } from '@domains/shared/helpers/getInitialMapArea';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { FilterLocations } from '@type/location/filterLocation';
import type { SortingOption } from '@type/sorting/option';
import { useContext, useRef } from 'react';

import { ContentWrapper, RowWithMargin, StyledSection } from './SellerAds.theme';

interface Props {
    isAgency: boolean;
    searchAds: SellerPageSearchAds;
    hasActiveAds: boolean;
    formData: FormData;
    sortingOption: SortingOption;
    filterAttributes: FilterAttributes;
    filterLocations: FilterLocations<string | string[] | { id: unknown }[]> | null;
    mapBoundingBox: SearchMapBoundingBoxData | null;
    sellerId: string;
}

export const SellerAds = ({
    isAgency,
    searchAds,
    hasActiveAds,
    formData,
    sortingOption,
    filterAttributes,
    filterLocations,
    mapBoundingBox,
    sellerId,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { isDesktop } = useContext(RWDContext);
    const { isVisible: isSearchFormModalVisible, open: openSearchFormModal, close: closeSearchFormModal } = useModal();

    const sectionTitle = isAgency
        ? t('frontend.companies.ads-list.agency-title')
        : t('frontend.companies.ads-list.developer-title');

    const initialArea = getInitialMapArea(searchAds.geometries);

    const {
        sellerPage: { defaultBoundingBoxForMap },
    } = COMPANIES_CONFIG;

    const { lang } = useSiteSettings();

    const goToMapButtonRef = useRef<HTMLButtonElement>(null);
    const filters = (
        <AdsFilters
            searchAds={searchAds}
            formData={formData}
            isAgency={isAgency}
            closeSearchFormModal={closeSearchFormModal}
            sellerId={sellerId}
        />
    );
    const selectedFiltersNumber = countFilters({ filterAttributes, filterLocations, isAgency });

    const { isMapFullScreen, shouldShowMap } = useShowMap();

    if (isMapFullScreen) {
        return (
            <>
                {isSearchFormModalVisible && <MobileFilters filters={filters} />}
                <MapView
                    lang={lang}
                    filterLocations={filterLocations}
                    filterAttributes={filterAttributes}
                    sortingOption={sortingOption}
                    initialBounds={mapBoundingBox?.boundingBox || defaultBoundingBoxForMap}
                    initialArea={initialArea || null}
                    handleOpenSearchFormModal={openSearchFormModal}
                />
            </>
        );
    }

    return (
        <StyledSection title={sectionTitle} shouldHaveTitleMarginOnMobile={false}>
            <ContentWrapper>
                {isDesktop ? (
                    <RowWithMargin>{filters}</RowWithMargin>
                ) : (
                    <MobileListingButtons
                        filters={filters}
                        onFiltersOpen={openSearchFormModal}
                        areFiltersOpen={isSearchFormModalVisible}
                        sortingOption={sortingOption}
                        selectedFiltersNumber={selectedFiltersNumber}
                    />
                )}
                {shouldShowMap ? (
                    <MapView
                        lang={lang}
                        filterLocations={filterLocations}
                        filterAttributes={filterAttributes}
                        sortingOption={sortingOption}
                        initialBounds={mapBoundingBox?.boundingBox || defaultBoundingBoxForMap}
                        initialArea={initialArea || null}
                        handleOpenSearchFormModal={openSearchFormModal}
                    />
                ) : (
                    <>
                        <RowWithMargin>
                            <ButtonWithMapBackground ref={goToMapButtonRef} />
                        </RowWithMargin>
                        <AdsList
                            items={searchAds.items}
                            pagination={searchAds.pagination}
                            hasActiveAds={hasActiveAds}
                            isAgency={isAgency}
                            sortingOption={sortingOption}
                        />
                    </>
                )}
            </ContentWrapper>
        </StyledSection>
    );
};
