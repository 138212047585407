import type { Breadcrumb } from '@domains/shared/components/Breadcrumbs/types';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import type { ComponentProps, JSX } from 'react';

import {
    Container,
    StyledIcon,
    StyledLastItem,
    StyledLink,
    StyledList,
    StyledListItem,
} from './BreadcrumbNavigation.theme';

interface Props extends ComponentProps<typeof Container> {
    segments: Breadcrumb[];
}

export const BreadcrumbNavigation = ({ segments, ...props }: Props): JSX.Element => {
    return (
        <Container aria-label="Breadcrumb" {...props}>
            <StyledList>
                {segments.map(({ label, url }, currentSegmentIndex) => {
                    const isLastSegment = currentSegmentIndex === segments.length - 1;

                    return isLastSegment ? (
                        <StyledListItem key={label}>
                            <StyledLastItem aria-current="page" data-testid="seller-last-breadcrumb">
                                {label}
                            </StyledLastItem>
                        </StyledListItem>
                    ) : (
                        <StyledListItem key={label}>
                            <StyledLink href={url}>{label}</StyledLink>
                            <StyledIcon icon={faChevronRight} size="xs" />
                        </StyledListItem>
                    );
                })}
            </StyledList>
        </Container>
    );
};
