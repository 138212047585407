import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { LocationLink } from '@type/location/locationLink';
import type { JSX } from 'react';

import { CustomLocationSeoLinks } from './CustomLocationSeoLinks';
import { LocationSeoLinks } from './LocationSeoLinks';
import { MultiColumnSegment, Section, Segment } from './LocationSeoLinks.theme';

interface Props {
    searchLinksRelatedLocations?: LocationLink[];
    propertiesForSell?: LocationLink[];
    propertiesForRent?: LocationLink[];
    propertiesFromPrimaryMarket?: LocationLink[];
    relatedSearchesWithNumberOfRooms?: LocationLink[];
}

export const LocationSeoLinksSection = ({
    searchLinksRelatedLocations,
    propertiesForSell,
    propertiesForRent,
    propertiesFromPrimaryMarket,
    relatedSearchesWithNumberOfRooms,
}: Props): JSX.Element | null => {
    const [t] = useTranslations();
    const hasSearchLinks = searchLinksRelatedLocations && searchLinksRelatedLocations.length > 0;
    const hasPropertiesForSell = propertiesForSell && propertiesForSell.length > 0;
    const hasPropertiesForRent = propertiesForRent && propertiesForRent.length > 0;
    const hasPropertiesFromPrimaryMarket = propertiesFromPrimaryMarket && propertiesFromPrimaryMarket.length > 0;
    const hasRealatedSearchesWithNumberOfRooms =
        relatedSearchesWithNumberOfRooms && relatedSearchesWithNumberOfRooms.length > 0;

    if (
        !hasSearchLinks &&
        !hasPropertiesForSell &&
        !hasPropertiesForRent &&
        !hasPropertiesFromPrimaryMarket &&
        !hasRealatedSearchesWithNumberOfRooms
    ) {
        return null;
    }

    return (
        <Section>
            {hasSearchLinks ? (
                <Segment>
                    <LocationSeoLinks
                        headline={t('frontend.search.location-seo-links.similar-locations')}
                        items={searchLinksRelatedLocations}
                    />
                </Segment>
            ) : null}

            {hasPropertiesForSell || hasPropertiesForRent || hasPropertiesFromPrimaryMarket ? (
                <MultiColumnSegment>
                    {hasPropertiesForSell ? (
                        <CustomLocationSeoLinks
                            items={propertiesForSell}
                            headline={t('frontend.search.location-seo-links.properties-for-sell')}
                        />
                    ) : null}

                    {hasPropertiesForRent ? (
                        <CustomLocationSeoLinks
                            items={propertiesForRent}
                            headline={t('frontend.search.location-seo-links.properties-for-rent')}
                        />
                    ) : null}
                    {hasPropertiesFromPrimaryMarket ? (
                        <CustomLocationSeoLinks
                            items={propertiesFromPrimaryMarket}
                            headline={t('frontend.search.location-seo-links.primary-market-updated')}
                            market={'PRIMARY'}
                        />
                    ) : null}
                </MultiColumnSegment>
            ) : null}

            {hasRealatedSearchesWithNumberOfRooms ? (
                <MultiColumnSegment>
                    <CustomLocationSeoLinks
                        headline={t('frontend.search.location-seo-links.related-searches')}
                        items={relatedSearchesWithNumberOfRooms}
                    />
                </MultiColumnSegment>
            ) : null}
        </Section>
    );
};
