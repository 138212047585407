import styled from '@emotion/styled';
import { LINE_HEIGHT, SIZE, WEIGHT } from '@lib/styles/partials/typography';
import type { Theme } from '@lib/styles/themes/type';

import type { BadgeVariant } from './Badge';

interface Props {
    variant: BadgeVariant;
    shouldReverseColors?: boolean;
}

const getVariantValue: (
    variant: BadgeVariant,
    theme: Theme,
    property: 'backgroundColor' | 'color',
    shouldReverseColors?: boolean,
) => string = (variant: BadgeVariant, theme, property, shouldReverseColors) => {
    const { badge } = theme.deprecated.domains.shared;

    if (shouldReverseColors) {
        return badge.reversedColors[variant][property];
    }

    return badge[variant][property];
};

export const Wrapper = styled.span<Props>`
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: ${({ variant, shouldReverseColors, theme }): string =>
        getVariantValue(variant, theme, 'backgroundColor', shouldReverseColors)};
    color: ${({ variant, shouldReverseColors, theme }): string =>
        getVariantValue(variant, theme, 'color', shouldReverseColors)};
    font-size: ${SIZE.p4};
    font-weight: ${WEIGHT.semibold};
    line-height: ${LINE_HEIGHT.x1};
    text-align: center;
`;
