import type { AdvertItem } from '@domains/companies/sellerPage/types/sellerPageSearchAds';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { ESTATE } from '@type/search/filters/estate';
import { InvestmentSpecsList } from '@widgets/AdvertCard/InvestmentSpecsList';
import { SpecsList } from '@widgets/AdvertCard/SpecsList';
import { useContext } from 'react';

export const SpecsListWrapper = ({ ad }: { ad: AdvertItem }): JSX.Element => {
    const { isDesktop, isTablet } = useContext(RWDContext);
    const isMobile = !isDesktop && !isTablet;
    const {
        estate,
        roomsNumber,
        areaInSquareMeters,
        floorNumber,
        pricePerSquareMeter,
        transaction,
        investmentUnitsAreaInSquareMeters,
        investmentUnitsRoomsNumber,
        investmentEstimatedDelivery,
        investmentUnitsNumber,
    } = ad;

    const isForRent = transaction === 'RENT';
    const isInvestment = estate === ESTATE.investment;

    if (isInvestment) {
        return (
            <InvestmentSpecsList
                estimatedDelivery={investmentEstimatedDelivery}
                unitsRoomsNumber={investmentUnitsRoomsNumber}
                unitsNumber={investmentUnitsNumber}
                unitsAreaInSquareMeters={investmentUnitsAreaInSquareMeters}
                isCompact={isMobile}
            />
        );
    }

    return (
        <SpecsList
            rooms={roomsNumber}
            area={areaInSquareMeters}
            floor={floorNumber}
            pricePerMeter={isForRent ? null : pricePerSquareMeter}
            isCompact={isMobile}
        />
    );
};
