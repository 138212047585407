import { Checkbox } from '@domains/shared/components/Checkbox/Checkbox';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { CheckboxInput, CheckboxLabel } from '@nexus/lib-react/dist/core/CheckboxInput';
import type { TreeNode } from '@type/search/location/pickerTreeNode';
import type { JSX } from 'react';
import { memo } from 'react';

import type { UseLocationPickerService } from '../../hooks/useLocationPickerService/types';
import type { CalledFromOrigin } from '../../types/selectOptions';
import { Label, ListItem, NexusCheckboxWrapper, NexusToggleButton, ToggleButton } from './LocationListItem.theme';

interface Props extends Pick<UseLocationPickerService, 'toggleExpanded' | 'toggleSelectNode'> {
    checkboxPostfix?: string;
    isChecked: boolean;
    isExpanded?: boolean;
    isPartiallyChecked: boolean;
    item: TreeNode;
    caller?: CalledFromOrigin;
    shouldUseNexusTheme?: boolean;
}

const LocationListItemBase = ({
    checkboxPostfix = '',
    isChecked,
    isExpanded,
    item,
    caller = 'location_tree',
    isPartiallyChecked,
    shouldUseNexusTheme,
    toggleExpanded,
    toggleSelectNode,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { id, name, parent, children } = item;
    const checkboxIdPostfix = checkboxPostfix ? `-${checkboxPostfix}` : '';
    const checkboxId = `location-checkbox-${id}${checkboxIdPostfix}`;

    if (shouldUseNexusTheme) {
        return (
            <ListItem role="menuitem">
                <NexusCheckboxWrapper>
                    <CheckboxInput
                        checked={isChecked || isPartiallyChecked}
                        id={checkboxId}
                        onChange={(): void => toggleSelectNode(item, { calledFrom: caller })}
                    />
                    <CheckboxLabel htmlFor={checkboxId} data-cy={`location-label-${checkboxId}`}>
                        {name}
                    </CheckboxLabel>
                </NexusCheckboxWrapper>

                {isExpanded !== undefined && (!parent || children.length > 0) ? (
                    <NexusToggleButton
                        aria-label={t('frontend.search.form.location.expand-region')}
                        onClick={(): void => toggleExpanded(item)}
                        isRegionExpanded={isExpanded}
                    />
                ) : null}
            </ListItem>
        );
    }

    return (
        <ListItem role="menuitem">
            <Checkbox
                checked={isChecked || isPartiallyChecked}
                icon={isPartiallyChecked ? faMinus : undefined}
                id={checkboxId}
                onChange={(): void => toggleSelectNode(item, { calledFrom: caller })}
            />
            <Label htmlFor={checkboxId} data-cy={`location-label-${checkboxId}`}>
                {name}
            </Label>
            {isExpanded !== undefined && (!parent || children.length > 0) ? (
                <ToggleButton
                    aria-label={t('frontend.search.form.location.expand-region')}
                    onClick={(): void => toggleExpanded(item)}
                    isRegionExpanded={isExpanded}
                />
            ) : null}
        </ListItem>
    );
};

export const LocationListItem = memo(LocationListItemBase);
