import type { SortingChangeHandler } from '@domains/search/types/handleSorting';
import {
    SORTING_CATEGORY_NAME,
    SORTING_DIRECTION_NAME,
} from '@domains/shared/components/ListingSorting/constants/sorting';
import type {
    SortingOptionsProps,
    SortingVariant,
} from '@domains/shared/components/ListingSorting/helpers/createDropdownOptionsData';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { checkIsArrayValue } from '@domains/shared/helpers/checkIsArrayValue';
import { logError } from '@domains/shared/helpers/logger';
import { changeSearchQueryParams } from '@domains/shared/helpers/searchQuery';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { SORTING_BUTTON_OPTION } from '@type/sorting/buttonOption';
import type { SortingBy } from '@type/sorting/by';
import { SORTING_BY, SORTING_BY_VALUES } from '@type/sorting/by';
import type { SortingDirection } from '@type/sorting/direction';
import { SORTING_DIRECTION_VALUES } from '@type/sorting/direction';
import type { SortingOption } from '@type/sorting/option';
import type { FC, JSX, PropsWithChildren } from 'react';
import { useCallback, useContext, useState } from 'react';

import { SortingOptionsPopover } from './components/SortingOptionsPopover/SortingOptionsPopover';
import { createDropdownOptionsData } from './helpers/createDropdownOptionsData';
import { getSelectedSortingOption } from './helpers/getSelectedSortingOption';
import {
    Container,
    Label,
    PopoverWrapper,
    StyledButton,
    StyledButtonGroup,
    StyledButtonHiddenOnMobile,
} from './ListingSorting.theme';
import type { ButtonsProps } from './types/button';

interface OnChangeProps {
    by: SortingBy;
    direction: SortingDirection;
    isSortingButton?: boolean | undefined;
    isButtonInPopover?: boolean;
}
interface Props {
    allSortingOptions: SortingOptionsProps;
    buttons: ButtonsProps[];
    containerDataCy?: string;
    sortingOption: SortingOption;
    onSortingChange?: (props: OnChangeProps) => void;
    onSortButtonChange?: (props: OnChangeProps) => void;
    sortingValues: SortingBy[];
    popoverDataCy?: string;
    popoverWrapperElement?: FC<PropsWithChildren>;
}

const getSortButtonValue = (option: SortingVariant): string => `${option.value}-${option.direction}`;

export const ListingSorting = ({
    onSortingChange,
    onSortButtonChange,
    sortingOption,
    buttons,
    sortingValues,
    allSortingOptions,
    popoverWrapperElement: PopoverWrapperElement = PopoverWrapper,
    popoverDataCy,
    containerDataCy,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { isDesktop } = useContext(RWDContext);
    const [isOpen, setIsOpen] = useState(false);

    const sortingOptions = createDropdownOptionsData(allSortingOptions, sortingValues);
    const selectedSortingOption = getSelectedSortingOption(sortingOptions, sortingOption);
    const selectedSortButtonValue = getSortButtonValue(selectedSortingOption);

    const selectedSortingButton =
        Object.values(SORTING_BUTTON_OPTION).find((value) => value === selectedSortButtonValue) ?? SORTING_BY.none;

    const handleSortOptionChange: SortingChangeHandler<void> = useCallback(
        ({ direction, by, isSortingButton = false, isButtonInPopover = false }) => {
            const { direction: currentDirection, by: currentCategory } = sortingOption;

            if (direction === currentDirection && by === currentCategory) {
                setIsOpen((previous) => !previous);

                return;
            }

            onSortingChange?.({ by, direction, isSortingButton, isButtonInPopover });

            changeSearchQueryParams([
                {
                    name: SORTING_CATEGORY_NAME,
                    value: by,
                },
                {
                    name: SORTING_DIRECTION_NAME,
                    value: direction,
                },
            ]);
        },
        [onSortingChange, sortingOption],
    );

    const handleClosePopover = useCallback(
        (event: MouseEvent | TouchEvent): void => {
            if ((event.target as HTMLElement)?.getAttribute('data-popover-trigger')) {
                return;
            }
            setIsOpen(false);
        },
        [setIsOpen],
    );

    const handleSortButtonChange = useCallback(
        (value: string) => {
            const { direction: currentDirection, by: currentCategory } = sortingOption;

            if (value === SORTING_BY.none) {
                setIsOpen((previous: boolean) => !previous);
                onSortButtonChange?.({ by: currentCategory, direction: currentDirection, isSortingButton: true });
            } else {
                const [by, direction] = value.split('-');
                const isValidSorting =
                    checkIsArrayValue(SORTING_DIRECTION_VALUES, direction) && checkIsArrayValue(SORTING_BY_VALUES, by);
                if (!isValidSorting) {
                    logError('Invalid sorting provided in handleSortButtonChange', { by, direction });

                    return;
                }
                if (direction === currentDirection && by === currentCategory) return;

                handleSortOptionChange({ direction, by });
            }
        },
        [sortingOption, handleSortOptionChange, onSortButtonChange],
    );

    return (
        <Container data-cy={containerDataCy}>
            {isDesktop ? <Label>{`${t('frontend.shared.listing-sorting.sort-by')}:`}</Label> : null}
            <StyledButtonGroup value={selectedSortingButton} onChange={handleSortButtonChange}>
                {buttons.map((button: ButtonsProps, index) => {
                    const { value, label, dataPopoverTrigger, dataCy, hideOnMobile, ariaLabel } = button;
                    if (hideOnMobile) {
                        return (
                            <StyledButtonHiddenOnMobile
                                key={index}
                                value={value}
                                data-cy={dataCy}
                                data-popover-trigger={dataPopoverTrigger}
                                aria-label={ariaLabel}
                            >
                                {label}
                            </StyledButtonHiddenOnMobile>
                        );
                    }

                    return (
                        <StyledButton
                            key={index}
                            value={value}
                            data-cy={dataCy}
                            data-popover-trigger={dataPopoverTrigger}
                            aria-label={ariaLabel}
                        >
                            {label}
                        </StyledButton>
                    );
                })}
            </StyledButtonGroup>
            <PopoverWrapperElement>
                <SortingOptionsPopover
                    handleClosePopover={handleClosePopover}
                    handleSortOptionChange={handleSortOptionChange}
                    isOpen={isOpen}
                    positionTo="right"
                    dataCy={popoverDataCy}
                    sortingOptions={sortingOptions}
                    selectedSortingOption={selectedSortingOption}
                />
            </PopoverWrapperElement>
        </Container>
    );
};
