import { SITE_CONFIG } from '@config/siteConfig';
import type { Breadcrumb } from '@domains/shared/components/Breadcrumbs/types';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { AdvertUserType } from '@type/user/userType';
import { useRouter } from 'next/router';

export const useSegmentedRoute = ({
    companyName,
    companyType,
}: {
    companyName: string;
    companyType: AdvertUserType;
}): Breadcrumb[] => {
    const router = useRouter();
    const [t] = useTranslations();

    const { defaultLanguage } = SITE_CONFIG.metadata;
    const isDeveloper = companyType === 'DEVELOPER';
    const defaultTrackingData = {
        eventName: 'navigation_path',
    };

    // Note: First value is omitted because we have an empty string on the left side of `/`.
    const [, lang, route, type] = router.asPath.split('/');

    return [
        {
            id: 'home',
            label: t('frontend.global.navigation.home'),
            url: `/${!lang || lang === defaultLanguage ? '' : lang}`,
            trackingData: {
                ...defaultTrackingData,
                actionType: 'home',
            },
        },
        {
            id: 'owner',
            // Note: This route isn't in new frontend yet so we omit the language.
            label: isDeveloper
                ? t('frontend.companies.breadcrumbs.developers')
                : t('frontend.companies.breadcrumbs.agencies'),
            url: `/${route}/${type}/`,
            trackingData: {
                ...defaultTrackingData,
                actionType: isDeveloper ? 'developers' : 'companies',
            },
        },
        {
            id: 'name',
            label: companyName,
            url: '',
        },
    ];
};
