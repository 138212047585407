import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Phone = styled.a`
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.sizes[14]};
    text-decoration: none;

    @media ${theme.mediaQueries.lgMin} {
        font-size: ${theme.sizes[16]};
    }
`;

export const Wrapper = styled.div`
    display: flex;
    gap: ${theme.space[2]};
    align-items: center;
`;
