import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { ComponentProps, JSX } from 'react';

import { StyledImage, TextWrapper, Wrapper } from './InactiveAdvertsNotice.theme';

type Props = ComponentProps<typeof Wrapper>;

export const InactiveAdvertsNotice = (props: Props): JSX.Element => {
    const [t] = useTranslations();
    const { staticAssetsPrefix } = getPublicEnvConfig();

    return (
        <Wrapper {...props}>
            <StyledImage src={`${staticAssetsPrefix}/images/companies/noAdsImage.svg`} alt="" />
            <TextWrapper>{t('frontend.companies.no-active-ads')}</TextWrapper>
        </Wrapper>
    );
};
