import { TooltipArrow, TooltipDescription } from '@domains/shared/components/TooltipIcon/Tooltip.theme';
import { TooltipIcon } from '@domains/shared/components/TooltipIcon/TooltipIcon';
import styled from '@emotion/styled';
import { LINE_HEIGHT, SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const RefreshedLabel = styled.p`
    grid-area: refreshLabel;
    min-width: 0;
    margin: 0;
    color: ${({ theme }): string => theme.deprecated.domains.shared.adPushedUpTooltip.refreshedLabel.color};
    font-size: ${SIZE.p5};
    font-weight: ${WEIGHT.semibold};
`;

export const TooltipText = styled.span`
    display: inline-block;
    width: 100%;
    text-align: left;
`;

export const StyledTooltip = styled(TooltipIcon)`
    flex: unset;
    margin: 0;
    line-height: ${LINE_HEIGHT.x1};

    ${TooltipArrow} {
        top: unset;
        right: unset;
        bottom: 28px;
        left: 16px;
        transform: translate(-50%, 100%);
    }

    ${TooltipDescription} {
        top: unset;
        bottom: 76px;
        padding: 8px;
        transform: translate(0, 100%);
    }
`;
