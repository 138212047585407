import { useScrollTrap } from '@domains/companies/sellerPage/components/SearchForm/hooks/useScrollTrap';
import type { FC, ReactNode } from 'react';
import { useEffect, useRef } from 'react';

import { Wrapper } from './MobileFilters.theme';

interface Props {
    filters: ReactNode;
}

export const MobileFilters: FC<Props> = ({ filters }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { removeScrollLock } = useScrollTrap(containerRef);

    useEffect(() => {
        return (): void => {
            removeScrollLock();
        };
    }, [removeScrollLock]);

    return <Wrapper ref={containerRef}>{filters}</Wrapper>;
};
