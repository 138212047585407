import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

import { SEARCH_WIDGET_THEME } from '../../../../../theme/[[[SITECODE]]]';
import { LIST_ITEM_LEFT_PADDING, LIST_ITEM_RIGHT_PADDING } from '../../constants/styleVariables';

export const SelectedLocationsContainer = styled.ul`
    margin: ${theme.space[0]};
    padding: ${theme.space[0]} ${theme.space[16]};
    list-style: none;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        margin: ${theme.space[8]} ${theme.space[0]} ${theme.space[0]};
        background: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.borderColor};
    }

    @media ${theme.mediaQueries.lgMin} {
        &::after {
            width: calc(100% - ${LIST_ITEM_RIGHT_PADDING}px - ${LIST_ITEM_LEFT_PADDING}px);
            margin: ${theme.space[8]} ${LIST_ITEM_RIGHT_PADDING}px ${theme.space[0]} ${LIST_ITEM_LEFT_PADDING}px;
        }

        padding: ${theme.space[0]};
    }
`;
