import { MOBILE_CONTACT_FORM_DIALOG_ID } from '@domains/companies/sellerPage/constants';
import { ContactForm } from '@domains/companies/sellerPage/nexusComponents/ContactForm/ContactForm';
import type { JSX } from 'react';

import { StyledDialog, StyledDialogBody } from './DialogContactForm.theme';

interface Props {
    ownerId: string;
}

export const DialogContactForm = ({ ownerId }: Props): JSX.Element => {
    return (
        <StyledDialog id={MOBILE_CONTACT_FORM_DIALOG_ID} data-cy="modal">
            <StyledDialogBody>
                <ContactForm ownerId={ownerId} />
            </StyledDialogBody>
        </StyledDialog>
    );
};
