import { TextButton } from '@domains/shared/components/TextButton/TextButton';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

import { SEARCH_WIDGET_THEME } from '../../../../../theme/[[[SITECODE]]]';

export const MissingLocationFeedbackContainer = styled.div`
    display: flex;
    position: absolute;
    bottom: 72px;
    flex: 0;
    justify-content: space-evenly;
    width: 100%;
    padding: ${theme.space[16]} ${theme.space[4]};
    background: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.missingLocationFeedback.backgroundColor};
    gap: ${theme.space[8]};

    @media ${theme.mediaQueries.lgMin} {
        position: static;
        justify-content: flex-end;
        padding: ${theme.space[10]} ${theme.space[8]};
    }
`;

export const MissingLocationFeedbackText = styled.p`
    margin: ${theme.space[0]};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const MissingLocationFeedbackButton = styled(TextButton)`
    padding: 1px;
    white-space: nowrap;
`;
