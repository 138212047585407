import type { JSX } from 'react';

import { LabelWrapper, StyledLabel } from './Label.theme';

interface Props {
    htmlFor?: string;
    required?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
    appendedChildren?: React.ReactNode;
}

export const Label = ({
    htmlFor,
    required = false,
    disabled,
    children: label,
    appendedChildren,
}: Props): JSX.Element => {
    if (appendedChildren) {
        return (
            <LabelWrapper data-testid="label-wrapper">
                <StyledLabel htmlFor={htmlFor} required={required}>
                    {label}
                </StyledLabel>
                {appendedChildren}
            </LabelWrapper>
        );
    }

    return (
        <StyledLabel htmlFor={htmlFor} required={required} disabled={disabled}>
            {label}
        </StyledLabel>
    );
};
