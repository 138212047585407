import { useClickOutside } from '@domains/shared/hooks/useClickOutside/useClickOutside';
import type { ComponentProps, ForwardRefRenderFunction } from 'react';
import { forwardRef, useCallback, useRef } from 'react';

import { Collapsible, StyledPopover, Wrapper } from './Popover.theme';

interface Props extends ComponentProps<'div'> {
    isOpen: boolean;
    onClose(event: MouseEvent | TouchEvent): void;
    closeOnBlur?: boolean;
    positionTo?: 'right' | 'left';
}

const PopoverElement: ForwardRefRenderFunction<HTMLDivElement, Props> = (
    { children, isOpen, onClose, closeOnBlur = true, positionTo = 'right', ...rest },
    ref,
) => {
    const innerRef = useRef<HTMLDivElement>(null);
    const handleClose = useCallback(
        (event: MouseEvent | TouchEvent): void => {
            if (closeOnBlur) {
                onClose(event);
            }
        },
        [onClose, closeOnBlur],
    );

    useClickOutside(innerRef, handleClose);

    return (
        <Wrapper>
            {isOpen ? (
                <Collapsible ref={ref} positionTo={positionTo}>
                    <StyledPopover aria-label="popover" ref={innerRef} {...rest}>
                        {children}
                    </StyledPopover>
                </Collapsible>
            ) : null}
        </Wrapper>
    );
};

export const Popover = forwardRef<HTMLDivElement, Props>(PopoverElement);
