import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import type { CSSProperties } from 'react';

export const getTextShadow = ({
    isActive,
    theme,
}: {
    isActive?: boolean;
    theme: Theme;
}): CSSProperties['textShadow'] => {
    const color = theme.deprecated.domains.shared.sortingDropdown.buttonGroup.button[isActive ? 'hover' : 'base'].color;

    return `0 0.2px 0 ${color}, 0 -0.2px 0 ${color}, 0.2px 0 0 ${color}, -0.2px 0 0 ${color};`;
};

export const getActiveButtonCss = (theme: Theme): SerializedStyles => css`
    border-color: ${theme.deprecated.domains.shared.sortingDropdown.buttonGroup.button.base.borderColor};
    background-color: ${theme.deprecated.domains.shared.sortingDropdown.buttonGroup.button.hover.backgroundColor};
    color: ${theme.deprecated.domains.shared.sortingDropdown.buttonGroup.button.hover.color};
    text-shadow: ${getTextShadow({ isActive: true, theme })};
`;

export const getStandardButtonCss = (theme: Theme): SerializedStyles => css`
    border-color: ${theme.deprecated.domains.shared.sortingDropdown.buttonGroup.button.base.borderColor};
    background-color: ${theme.deprecated.domains.shared.sortingDropdown.buttonGroup.button.base.backgroundColor};
    color: ${theme.deprecated.domains.shared.sortingDropdown.buttonGroup.button.base.color};
    text-shadow: none;
`;
