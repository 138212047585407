import { AddToFavorites } from '@domains/shared/components/AdvertItem/AddToFavorites/AddToFavorites';
import { Badge } from '@domains/shared/components/dataDisplay/Badge/Badge';
import { H3 } from '@domains/shared/components/Heading/Heading.theme';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LAYER } from '@lib/styles/partials/layers';
import { LINE_HEIGHT, SIZE, WEIGHT } from '@lib/styles/partials/typography';

const LAYOUT_WITH_LABEL = `
    'refreshLabel'
    'titleContainer' 
    'location'
    'meta'
`;

const LAYOUT = `
    'titleContainer' 
    'location'
    'meta'
`;

const DESKTOP_CONTAINER_HEIGHT = '208px';

export const Container = styled.li`
    list-style: none;

    & + & {
        margin-top: 16px;
    }
`;

interface MainContentWrapperProps {
    isAdRefreshed?: boolean;
}

export const MainImage = styled.img`
    display: block;
    position: relative;
    width: auto;
    min-width: 100%;
    max-width: 100%;
    height: 180px;
    max-height: 180px;
    aspect-ratio: 30 / 22;
    object-fit: cover;
    object-position: left bottom;
    transition: 0.2s filter;

    @media (min-width: ${BREAKPOINT.md}) {
        height: ${DESKTOP_CONTAINER_HEIGHT};
        max-height: ${DESKTOP_CONTAINER_HEIGHT};
    }
`;

export const ImageContainer = styled.aside`
    position: relative;
    grid-area: image;
    height: 180px;

    @media (min-width: ${BREAKPOINT.md}) {
        height: ${DESKTOP_CONTAINER_HEIGHT};
    }

    @media (min-width: ${BREAKPOINT.lg}) {
        margin: 0;
        overflow: visible;
    }
`;

export const MobileAddToFav = styled(AddToFavorites)`
    display: grid;
    place-items: center;
    visibility: visible;
    position: absolute;
    z-index: ${LAYER.x0};
    right: 18px;
    bottom: 16px;
    width: 48px;
    height: 48px;
    margin: 0 auto;
    border-radius: 50%;
    background: ${({ theme }): string =>
        theme.deprecated.domains.companies.advertItem.addToFavIcon.secondary.backgroundColor};

    @media (min-width: ${BREAKPOINT.md}) {
        visibility: hidden;
    }
`;

export const ExclusiveOfferBadge = styled(Badge)`
    position: absolute;
    top: 12px;
    left: 12px;
`;

export const OpenDays = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 4px;
    background-color: ${({ theme }): string => theme.deprecated.domains.companies.advertItem.openDays.backgroundColor};
    color: ${({ theme }): string => theme.deprecated.domains.companies.advertItem.openDays.color};
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.semibold};
    text-align: center;
`;

export const MainContentWrapper = styled.div<MainContentWrapperProps>`
    display: grid;
    position: relative;
    grid-area: offer;
    grid-template-areas: ${({ isAdRefreshed }): string => (isAdRefreshed ? LAYOUT_WITH_LABEL : LAYOUT)};
    padding: 16px;
    background-color: ${({ theme }): string => theme.deprecated.domains.companies.advertItem.backgroundColor};

    @media (min-width: ${BREAKPOINT.md}) {
        grid-column-gap: 8px;
        grid-template-columns: minmax(0, 1.6fr);
        grid-template-rows: ${({ isAdRefreshed }): string =>
            isAdRefreshed ? '18px 28px 24px 36px 1fr' : '28px 24px 36px 1fr'};
        height: 208px;
    }
`;

export const AdvertLink = styled.a`
    contain-intrinsic-size: 200px;
    display: grid;
    grid-template-areas:
        'image'
        'offer';
    grid-template-columns: 1fr;
    color: ${({ theme }): string => theme.deprecated.domains.companies.advertItem.link.color};
    text-decoration: none;

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-areas: 'image offer';
        grid-template-columns: 280px 1fr;
    }

    &:hover img {
        filter: none;
    }
`;

export const TitleWrapper = styled.div`
    display: grid;
    grid-area: titleContainer;
    grid-template-areas: 'title icon';
    grid-template-columns: 1fr;

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-columns: 1fr 24px;
    }
`;

export const Title = styled(H3)`
    grid-area: title;
    padding-right: 4px;
    overflow: hidden;
    font-size: ${SIZE.p2};
    font-weight: ${WEIGHT.bold};
    line-height: ${LINE_HEIGHT.x2};
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &:hover {
        text-decoration: underline;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        font-size: ${SIZE.p2};
    }
`;

export const LocationLabel = styled.p`
    grid-area: location;
    margin: 0;
    padding: 8px 0;
    font-size: ${SIZE.p4};
    font-weight: ${WEIGHT.semibold};
    line-height: ${SIZE.p3};

    @media (min-width: ${BREAKPOINT.md}) {
        padding: 0;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        display: inline;
    }
`;
