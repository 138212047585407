import { Container as PageContentSizedContainer } from '@domains/shared/components/Container/Container';
import { GoBackButton } from '@domains/shared/components/GoBackButton/GoBackButton';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';

import { BreadcrumbNavigation } from './components/BreadcrumbNavigation';

export const Container = styled.div`
    display: grid;
`;

export const StyledPicture = styled.picture`
    display: flex;
    grid-area: 1/-1;

    img {
        width: 100%;
        object-fit: cover;
    }
`;

export const GradientOverlay = styled.div`
    grid-area: 1/-1;
    background: linear-gradient(180deg, #1c2637 0%, rgb(28 38 55 / 30%) 46.14%, rgb(28 38 55 / 0%) 77.72%);
`;

export const NavigationWrapper = styled(PageContentSizedContainer)`
    display: flex;
    grid-area: 1/-1;
    align-items: center;
    align-self: start;
    margin-top: 30px;
    gap: 16px;
`;

export const StyledImg = styled.img`
    height: 136px;

    @media (min-width: ${BREAKPOINT.md}) {
        height: 250px;
    }
`;

export const StyledBreadcrumbNavigation = styled(BreadcrumbNavigation)`
    @media (max-width: ${BREAKPOINT.sm}) {
        display: none;
    }
`;

export const StyledGoBackButton = styled(GoBackButton)`
    margin-right: 16px;
`;
