import type { FormData } from '@domains/companies/sellerPage/types/filtersData';
import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { useTracking } from '@lib/tracking/useTracking';
import { OWNER_TYPE } from '@type/ad/ownerType';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { AdvertUserType } from '@type/user/userType';
import { SubmitButton } from '@widgets/search/SearchFormSubmitButton';
import type { JSX, MouseEvent } from 'react';
import { useContext, useRef } from 'react';
import { FormProvider } from 'react-hook-form';

import { ResetButton } from './components/ResetButton';
import { AreaField } from './fields/AreaField';
import { EstateField } from './fields/EstateField';
import { LocationField } from './fields/LocationField';
import { MarketField } from './fields/MarketField';
import { PriceField } from './fields/PriceField';
import { RoomsNumberField } from './fields/RoomsNumberField';
import { TransactionField } from './fields/TransactionField';
import { useScrollTrap } from './hooks/useScrollTrap';
import { useSearchForm } from './hooks/useSearchForm';
import {
    ActionButtonsContainer,
    Container,
    ExitButton,
    FieldsContainerDivider,
    Header,
    PrimaryFieldsContainer,
    SecondaryFieldsContainer,
    StyledForm,
    StyledSpecialOffersSection,
    Title,
    VerticalDivider,
} from './SearchForm.theme';

interface Props {
    ownerType: AdvertUserType;
    formData: FormData;
    onExitButtonClick: () => void;
    onSubmit?: () => void;
    onClear?: () => void;
    ownerId: string;
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
}

export const SearchForm = ({
    ownerType,
    formData,
    ownerId,
    onExitButtonClick,
    onSubmit,
    onClear,
    fieldsMetadataExperimentsVariants,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { isDesktop } = useContext(RWDContext);
    const form = useSearchForm({ formData, isAgency: ownerType === OWNER_TYPE.agency });
    const { removeScrollLock } = useScrollTrap(containerRef);
    const { trackEvent } = useTracking();
    const isDeveloper = ownerType === OWNER_TYPE.developer;
    const {
        featureFlags: { isSpecialOfferEnabled },
    } = useSiteSettings();
    const shouldShowSpecialOffersFilter = Boolean(isSpecialOfferEnabled) && isDeveloper;

    const handleSearchButtonClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();
        trackEvent('search');

        await form.submit();
        onSubmit?.();
    };

    const handleClearButtonClick = (): void => {
        trackEvent('clear_filters', { touch_point_button: 'searchbox' });

        form.clear();
        onClear?.();
    };

    const handleExitButtonClick = (): void => {
        removeScrollLock();
        onExitButtonClick();
    };

    return (
        <Container ref={containerRef} data-cy="adverts-filters">
            {isDesktop ? null : (
                <Header>
                    <Title>{t('frontend.search.listing.filters')}</Title>
                    <ExitButton
                        aria-label={t('frontend.companies.search-form.exit-button-label')}
                        onClick={handleExitButtonClick}
                        data-cy="adverts-filters-exit-button"
                    >
                        <Icon icon={faTimes} size="lg" />
                    </ExitButton>
                </Header>
            )}

            <FormProvider {...form}>
                <StyledForm shouldShowSpecialOffersFilter={shouldShowSpecialOffersFilter} data-testid="search-form">
                    <PrimaryFieldsContainer shouldShowSpecialOffersFilter={shouldShowSpecialOffersFilter}>
                        <EstateField />
                        <TransactionField />
                        {isDeveloper ? <RoomsNumberField /> : <MarketField />}
                        <LocationField locations={formData.locations} />
                        {isDesktop && shouldShowSpecialOffersFilter ? (
                            <>
                                <VerticalDivider />
                                <StyledSpecialOffersSection
                                    shouldDisplayTopMargin={false}
                                    trackingEventName="special_offer_selected"
                                />
                            </>
                        ) : null}
                    </PrimaryFieldsContainer>

                    {isDesktop || shouldShowSpecialOffersFilter ? <FieldsContainerDivider /> : null}

                    {!isDesktop && shouldShowSpecialOffersFilter && (
                        <StyledSpecialOffersSection
                            shouldDisplayTopMargin={false}
                            trackingEventName="special_offer_selected"
                        />
                    )}

                    {!isDesktop && shouldShowSpecialOffersFilter ? <FieldsContainerDivider /> : null}

                    <SecondaryFieldsContainer>
                        <PriceField />
                        {isDesktop ? <VerticalDivider /> : null}
                        <AreaField />
                    </SecondaryFieldsContainer>

                    <ActionButtonsContainer>
                        <ResetButton
                            type="button"
                            onClick={handleClearButtonClick}
                            data-cy="adverts-filters-reset-button"
                        >
                            {t('frontend.filters-box.actions.reset')}
                        </ResetButton>
                        {isDesktop ? <VerticalDivider /> : null}
                        <SubmitButton
                            fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                            variant={ButtonVariant.Primary}
                            onClick={handleSearchButtonClick}
                            sellerId={ownerId}
                        />
                    </ActionButtonsContainer>
                </StyledForm>
            </FormProvider>
        </Container>
    );
};
