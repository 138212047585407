/* eslint-disable @typescript-eslint/naming-convention -- Polish alphabet */
const SPECIAL_CHARS_MAP: Record<string, string> = {
    // Polish
    ą: 'a',
    ć: 'c',
    ę: 'e',
    ł: 'l',
    ń: 'n',
    ó: 'o',
    ś: 's',
    ź: 'z',
    ż: 'z',

    // Romanian
    ă: 'a',
    â: 'a',
    î: 'i',
    ş: 's',
    ș: 's',
    ţ: 't',
    ț: 't',

    // Portuguese
    ã: 'a',
    á: 'a',
    ç: 'c',
    é: 'e',
    ê: 'e',
    í: 'i',
    ô: 'o',
    õ: 'o',
    ú: 'u',

    // Spanish
    ñ: 'n',

    // French
    à: 'a',
    è: 'e',
    ë: 'e',
    ï: 'i',
    û: 'u',

    // German
    ä: 'a',
    ö: 'o',
    ü: 'u',
    ß: 'ss',

    // Italian
    ì: 'i',
    ò: 'o',
    ù: 'u',

    // Swedish
    å: 'a',

    // Danish and Norwegian
    æ: 'ae',
    ø: 'o',

    // Icelandic
    ð: 'd',
    þ: 'th',

    // Czech and Slovak
    č: 'c',
    ď: 'd',
    ě: 'e',
    ň: 'n',
    ř: 'r',
    š: 's',
    ť: 't',
    ů: 'u',
    ž: 'z',
    ý: 'y',

    // Hungarian
    ő: 'o',
    ű: 'u',

    // Latvian
    ā: 'a',
    ē: 'e',
    ģ: 'g',
    ī: 'i',
    ķ: 'k',
    ļ: 'l',
    ņ: 'n',
    ū: 'u',

    // Turkish
    ı: 'i',
    ğ: 'g',
} as const;
/* eslint-enable @typescript-eslint/naming-convention */

const specialCharsToStandardLatin = (input: string): string =>
    [...input.toLowerCase().replace(/'/g, '').replace(/\s/g, '-')]
        .map((letter) => SPECIAL_CHARS_MAP[letter] || letter)
        .join('');

export const convertFullNameToSlug = ({ firstName, lastName }: { firstName: string; lastName: string }): string => {
    const standardLatinFirstName = specialCharsToStandardLatin(firstName).toLowerCase();
    const standardLatinLastName = specialCharsToStandardLatin(lastName).toLowerCase();

    return `${standardLatinFirstName}-${standardLatinLastName}`;
};
