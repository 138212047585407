import type { JSX, ReactNode } from 'react';

import { Button } from './UnderlinedButton.theme';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    shouldButtonHavePadding?: boolean;
}

export const UnderlinedButton = ({ children, shouldButtonHavePadding, ...rest }: Props): JSX.Element => {
    return (
        <Button shouldButtonHavePadding={!!shouldButtonHavePadding} {...rest}>
            {children}
        </Button>
    );
};
