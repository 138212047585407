import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { TextInput } from '@nexus/lib-react/dist/core/TextInput';
import { P4 } from '@nexus/lib-react/dist/core/Typography';

export const InputsWraper = styled.div`
    display: flex;
    width: 100%;

    & > div:nth-last-of-type(1) {
        flex-grow: 1;
    }
`;

export const StyledCountryCodeInput = styled(TextInput)`
    width: 68px;

    + div > p {
        width: 68px;
    }

    & input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const StyledPhoneNumberInput = styled(TextInput)`
    width: 100%;

    & input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`;

export const ErrorMessage = styled(P4)`
    margin-bottom: ${theme.space['8']};
    color: ${theme.colors['text-global-error']};
`;
