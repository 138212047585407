import { SpecialOfferHeader } from '@domains/shared/components/AdvertItem/SpecialOfferHeader/SpecialOfferHeader';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Wrapper = styled.article`
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    overflow: hidden;
    border-radius: ${theme.radii[4]};
    cursor: pointer;

    @media (${theme.mediaQueries.lgMin}) {
        flex-flow: row wrap;
    }
`;

export const SpecialOfferBanner = styled(SpecialOfferHeader)`
    flex: 0 0 100%;
    height: ${theme.space[30]};
`;

export const OpenDaysBanner = styled.div`
    display: flex;
    flex: 0 0 100%;
    align-items: center;
    justify-content: center;
    min-height: ${theme.space[22]};
    margin-top: ${theme.space[8]};
    background-color: ${theme.colors['background-brand-secondary']};
    color: ${theme.colors['text-global-inverse']};
    font-size: ${theme.fontSizes[14]};
    font-weight: ${theme.fontWeights[600]};

    @media (${theme.mediaQueries.smMin}) {
        margin-top: ${theme.space[16]};
    }

    @media (${theme.mediaQueries.lgMin}) {
        margin-top: 0;
    }
`;
