import { useState } from 'react';

export const useModal = (isInitiallyVisible = false): { isVisible: boolean; open: () => void; close: () => void } => {
    const [isVisible, setIsVisible] = useState(isInitiallyVisible);

    const open = (): void => {
        setIsVisible(true);
    };

    const close = (): void => {
        setIsVisible(false);
    };

    return { isVisible, open, close };
};
