import { useSegmentedRoute } from '@domains/companies/sellerPage/hooks/useSegmentedRoute';
import type { AdvertUserType } from '@type/user/userType';
import type { JSX } from 'react';

import {
    Container,
    GradientOverlay,
    NavigationWrapper,
    StyledBreadcrumbNavigation,
    StyledGoBackButton,
    StyledImg,
    StyledPicture,
} from './NavigationSection.theme';

interface Props {
    companyName: string;
    companyType: AdvertUserType;
    image: {
        desktop: string;
        mobile: string;
    };
    referer: string | null;
}

export const NavigationSection = ({ companyName, companyType, image, referer }: Props): JSX.Element => {
    const segments = useSegmentedRoute({ companyName, companyType });

    return (
        <Container>
            <StyledPicture data-cy="seller-background-image">
                <source srcSet={image.mobile} media={'(max-width: 720px)'} />
                <source srcSet={image.desktop} />
                <StyledImg src={image.desktop} alt="" />
            </StyledPicture>
            <GradientOverlay />
            <NavigationWrapper>
                <StyledGoBackButton referer={referer} data-cy="navigation-go-back-button" />
                <StyledBreadcrumbNavigation segments={segments} data-cy="navigation-breadcrumbs" />
            </NavigationWrapper>
        </Container>
    );
};
