import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const LabelWrapper = styled.span`
    display: flex;
    align-items: flex-start;
    column-gap: ${theme.space['4']};

    > div {
        display: flex;
    }
`;

const REQUIRED_LABEL_STYLES = css`
    &::after {
        content: '*';
        font-size: ${theme.typography.p3.fontSize};
    }
`;

export const StyledLabel = styled.label<{ required?: boolean; disabled?: boolean }>`
    display: flex;
    width: max-content;
    margin-bottom: ${theme.space['2']};
    color: ${({ disabled }): CSSProperties['color'] =>
        disabled ? theme.colors['text-global-disabled'] : theme.colors['text-global-primary']};
    font-size: ${theme.typography.p3.fontSize};
    line-height: ${theme.typography.p3.lineHeight};

    ${({ required }): SerializedStyles | null => (required ? REQUIRED_LABEL_STYLES : null)}
`;
