import type { GoogleSearchType } from '@type/search/google';
import { GOOGLE_SEARCH_TYPE } from '@type/search/google';
import type { LocationPickerContent } from '@widgets/search/LocationPicker/components/LocationPickerTree/types/locationPickerContent';
import { LOCATION_PICKER_CONTENT } from '@widgets/search/LocationPicker/components/LocationPickerTree/types/locationPickerContent';
import type { SuggestionsType } from '@widgets/search/LocationPicker/components/LocationPickerTree/types/suggestions';

export const getLocationPickerContent = ({
    googleSearchType,
    suggestions,
}: {
    googleSearchType: GoogleSearchType | null;
    suggestions: SuggestionsType;
}): LocationPickerContent => {
    if (googleSearchType === GOOGLE_SEARCH_TYPE.street) {
        return LOCATION_PICKER_CONTENT.street;
    } else if (suggestions && suggestions.length > 0) {
        return LOCATION_PICKER_CONTENT.suggestions;
    }

    return LOCATION_PICKER_CONTENT.tree;
};
