import type { TransactionVariant } from '@type/search/transactionVariant';
import { TRANSACTION_VARIANT } from '@type/search/transactionVariant';

export const TRANSLATIONS: Record<TransactionVariant, string> = {
    [TRANSACTION_VARIANT.flatSell]: 'frontend.search.item.flat-for-sell',
    [TRANSACTION_VARIANT.flatRent]: 'frontend.search.item.flat-for-rent',
    [TRANSACTION_VARIANT.studioFlatSell]: 'frontend.search.item.studio-flat-for-sell',
    [TRANSACTION_VARIANT.studioFlatRent]: 'frontend.search.item.studio-flat-for-rent',
    [TRANSACTION_VARIANT.houseSell]: 'frontend.search.item.house-for-sell',
    [TRANSACTION_VARIANT.houseRent]: 'frontend.search.item.house-for-rent',
    [TRANSACTION_VARIANT.investment]: 'frontend.search.item.investment',
    [TRANSACTION_VARIANT.roomRent]: 'frontend.search.item.room-for-rent',
    [TRANSACTION_VARIANT.terrainSell]: 'frontend.search.item.terrain-for-sell',
    [TRANSACTION_VARIANT.terrainRent]: 'frontend.search.item.terrain-for-rent',
    [TRANSACTION_VARIANT.commercialPropertySell]: 'frontend.search.item.commercial-property-for-sell',
    [TRANSACTION_VARIANT.commercialPropertyRent]: 'frontend.search.item.commercial-property-for-rent',
    [TRANSACTION_VARIANT.hallSell]: 'frontend.search.item.hall-for-sell',
    [TRANSACTION_VARIANT.hallRent]: 'frontend.search.item.hall-for-rent',
    [TRANSACTION_VARIANT.garageSell]: 'frontend.search.item.garage-for-sell',
    [TRANSACTION_VARIANT.garageRent]: 'frontend.search.item.garage-for-rent',
};
