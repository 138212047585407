import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { LocationSearch } from '@widgets/search/LocationSearch';

export const FieldLabel = styled.label`
    display: block;
    margin-bottom: ${theme.space[4]};
    color: ${theme.colors['text-global-primary']};
    font-size: ${theme.fontSizes[14]};
    font-weight: ${theme.fontWeights[400]};
    line-height: ${theme.lineHeights[18]};
`;

export const StyledLocationSearch = styled(LocationSearch)`
    display: grid;
    grid-template-columns: 1fr 150px;
`;
