import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const Section = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 32px;
    padding-bottom: 32px;
`;

export const Segment = styled.div`
    display: flex;
    width: 100%;
    padding: 32px 0;
    border-top: 1px solid;
    border-color: ${({ theme }): string => theme.deprecated.domains.shared.locationSeoLinks.base.borderColor};
`;

export const MultiColumnSegment = styled(Segment)`
    display: grid;
    grid-gap: 32px 16px;
    grid-template-columns: 1fr;

    &:last-child {
        padding-bottom: 0;
    }

    @media (min-width: ${BREAKPOINT.sm}) {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const InnerWrapper = styled.div`
    width: 100%;
`;

export const SectionHeadline = styled.h3`
    margin: 0;
    padding-bottom: 16px;
    color: ${({ theme }): string => theme.deprecated.domains.shared.locationSeoLinks.base.color};
    font-size: ${SIZE.p2};
    font-weight: ${WEIGHT.bold};
`;

export const ListContainer = styled.ul`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr;
    width: 100%;
    margin: 0;
    padding: 0;
    color: ${({ theme }): string => theme.deprecated.domains.shared.locationSeoLinks.count.color};
    font-size: ${SIZE.p3};
    list-style: none;

    @media (min-width: ${BREAKPOINT.sm}) {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const OneColumnListContainer = styled(ListContainer)`
    @media (min-width: ${BREAKPOINT.sm}) {
        grid-template-columns: 1fr;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-columns: 1fr;
    }
`;

export const Link = styled.a`
    color: ${({ theme }): string => theme.deprecated.domains.shared.locationSeoLinks.base.color};
    font-weight: ${WEIGHT.semibold};
`;

export const ShowAllButton = styled.button`
    padding: 0;
    border: none;
    background: transparent;
    color: ${({ theme }): string => theme.deprecated.domains.shared.locationSeoLinks.base.color};
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.regular};

    &:hover {
        cursor: pointer;
    }
`;
