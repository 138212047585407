import { COMPANIES_CONFIG } from '@config/companies/companiesConfig';
import type { FC } from 'react';

import { Label, Value, Wrapper } from './Factoid.theme';

interface Props {
    label: string;
    value: number | string;
    isYearsStatisticEnabled?: boolean;
}

export const Factoid: FC<Props> = ({ label, value, isYearsStatisticEnabled = true }) => {
    const { isCitiesFactoidEnabled } = COMPANIES_CONFIG.sellerPage;

    const hasEvenNumberOfStatistic =
        (isCitiesFactoidEnabled && isYearsStatisticEnabled) || (!isCitiesFactoidEnabled && !isYearsStatisticEnabled);

    return (
        <Wrapper hasEvenNumberOfStatistics={hasEvenNumberOfStatistic}>
            <Value>{value}</Value>
            <Label>{label}</Label>
        </Wrapper>
    );
};
