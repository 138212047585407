import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { getEstateTranslationKeyPrefix } from '@domains/shared/helpers/getEstateTranslationKeyPrefix';
import { getTransactionTranslationKeyPrefix } from '@domains/shared/helpers/getTransactionTranslationKeyPrefix';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { FilterLocations } from '@type/location/filterLocation';
import type { LocationLink } from '@type/location/locationLink';
import { buildSearchUrl } from '@widgets/search/buildSearchUrl';
import type { JSX } from 'react';
import { useState } from 'react';

import { InnerWrapper, Link, ListContainer, SectionHeadline, ShowAllButton } from './LocationSeoLinks.theme';

interface Props {
    headline?: string;
    items: LocationLink[];
    filterLocations?: FilterLocations<string | string[] | { id: unknown }[]> | null;
}

const MAX_VISIBLE_ITEMS = 15;

export const LocationSeoLinks = ({ headline, items }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { lang } = useSiteSettings();
    const [shouldShowAllLinks, setShouldShowAllLinks] = useState(items.length <= MAX_VISIBLE_ITEMS);
    const { trackEvent } = useTracking();

    return (
        <InnerWrapper>
            {headline ? <SectionHeadline>{headline}</SectionHeadline> : null}

            <ListContainer>
                {items.map(({ name, estate, transaction, location }, i) => {
                    const isHidden = !shouldShowAllLinks && i >= MAX_VISIBLE_ITEMS;
                    const estateLabelKey = getEstateTranslationKeyPrefix(estate);
                    const transactionLabelKey = getTransactionTranslationKeyPrefix(transaction);

                    const estateLabel = t(estateLabelKey);
                    const transactionLabel = t(transactionLabelKey);
                    const locationLabel = t('frontend.shared.location-seo-links.link-label', {
                        estateLabel,
                        transactionLabel,
                        location: name,
                    });

                    return (
                        <li key={`location-seo-link-${name}`} hidden={isHidden}>
                            <LocalLink
                                passHref
                                href={buildSearchUrl({
                                    lang,
                                    transaction,
                                    estate,
                                    location,
                                })}
                            >
                                <Link onClick={(): void => trackEvent('seo_link_click')}>{locationLabel}</Link>
                            </LocalLink>
                        </li>
                    );
                })}
                {shouldShowAllLinks ? null : (
                    <li>
                        <ShowAllButton onClick={(): void => setShouldShowAllLinks(true)}>
                            {t('frontend.shared.seoLinks.all')}
                        </ShowAllButton>
                    </li>
                )}
            </ListContainer>
        </InnerWrapper>
    );
};
