import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { H4 } from '@nexus/lib-react/dist/core/Typography';

export const Wrapper = styled.section`
    padding: ${theme.space[16]};
    background-color: ${theme.colors['background-global-primary']};

    @media ${theme.mediaQueries.mdMin} {
        padding: ${theme.space[24]};
    }

    @media ${theme.mediaQueries.lgMin} {
        border-radius: ${theme.radii[4]};
    }
`;

export const Title = styled(H4)<{ shouldHaveMarginOnMobile: boolean }>`
    margin-bottom: ${({ shouldHaveMarginOnMobile }): CSSProperties['marginBottom'] =>
        shouldHaveMarginOnMobile ? theme.space[16] : 0};
    color: ${theme.colors['text-global-highlight']};

    @media ${theme.mediaQueries.lgMin} {
        margin-bottom: ${theme.space[24]};
    }
`;
