import { SubmitButton } from '@widgets/search/SearchFormSubmitButton';
import type { FC, MouseEvent } from 'react';

import { Wrapper } from './MobileFiltersFooter.theme';

interface Props {
    sellerId: string;
    submitButtonOnClick: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
}

export const MobileFiltersFooter: FC<Props> = ({ sellerId, submitButtonOnClick }) => {
    return (
        <Wrapper>
            <SubmitButton
                fieldsMetadataExperimentsVariants={[]}
                sellerId={sellerId}
                shouldUseNexusTheme
                onClick={submitButtonOnClick}
            />
        </Wrapper>
    );
};
