import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P2 } from '@nexus/lib-react/dist/core/Typography';

export const Wrapper = styled(P2)`
    color: ${theme.colors['text-global-secondary']};
`;

export const Total = styled.b`
    color: ${theme.colors['text-global-primary']};
`;
