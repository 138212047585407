import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useContext } from 'react';

import { Total, Wrapper } from './SearchResultCount.theme';

export const SearchResultCount = ({ totalItems }: { totalItems: number }): JSX.Element => {
    const { isDesktop } = useContext(RWDContext);
    const [t] = useTranslations();

    const label = isDesktop
        ? t('frontend.companies-seller-page.ads-list.search-results-count')
        : t('frontend.companies-seller-page.ads-list.search-results-count-mobile');

    return (
        <Wrapper data-cy="adverts-result-count">
            {`${label}: `}
            <Total>{totalItems}</Total>
        </Wrapper>
    );
};
