import { Button } from '@domains/shared/components/Button/Button';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import ChevronRight from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronRight';
import { SEARCH_WIDGET_THEME } from '@widgets/search/theme/[[[SITECODE]]]';

import { LIST_ITEM_LEFT_PADDING, LIST_ITEM_RIGHT_PADDING } from '../../constants/styleVariables';

export const Container = styled.div`
    padding: ${theme.space[0]} ${theme.space[16]};

    @media ${theme.mediaQueries.lgMin} {
        padding: ${theme.space[0]} ${LIST_ITEM_RIGHT_PADDING}px ${theme.space[0]} ${LIST_ITEM_LEFT_PADDING}px;
    }
`;

export const StyledChevronRight = styled(ChevronRight)`
    margin-left: auto;
    color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.color};
`;

export const LabelButton = styled(Button)`
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    margin: ${theme.space[8]} ${theme.space[0]};
    padding: ${theme.space[0]};
    border: none;
    background: none;
    font-weight: ${theme.fontWeights.regular};
    cursor: pointer;

    &:focus,
    &:active,
    &:hover {
        background-color: transparent;
    }
`;

export const Text = styled.div`
    padding-left: ${theme.space[16]};
    font-size: ${theme.fontSizes[16]};
`;

export const Separator = styled.div`
    border-bottom: ${theme.borderWidths[1]} ${theme.borderStyles.solid};
    border-color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.borderColor};
`;
