import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Wrapper = styled.div`
    position: fixed;
    z-index: 1;
    padding: ${theme.space[16]};
    border-top: 1px solid ${theme.colors['borders-global-tertiary']};
    background-color: ${theme.colors['background-global-primary']};
    inset: auto 0 0;
`;
