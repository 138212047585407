import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Wrapper = styled.div`
    display: grid;
    gap: ${theme.space[16]};
    width: 100%;
    padding: ${theme.sizes[72]} ${theme.space[16]} ${theme.sizes[80]};

    @media ${theme.mediaQueries.lgMin} {
        grid-template-columns: calc(40% - 4px) calc(60% - 4px);
        gap: ${theme.space[8]};
        padding: 0;
    }
`;

export const InvestmentStateFieldWrapper = styled.div`
    flex: 1 0 auto;
`;
