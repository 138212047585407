import type { RoomsNumberOptionType } from '@domains/companies/sellerPage/config/filters/roomsNumber';
import { ROOMS_NUMBER_OPTIONS_VALUES } from '@domains/companies/sellerPage/config/filters/roomsNumber';
import type { FormData } from '@domains/companies/sellerPage/types/filtersData';
import { Dropdown } from '@domains/shared/components/Dropdown/Dropdown';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { components } from 'react-select';

import { FieldLabel } from '../components/FieldLabel';
import { StyledValue } from './RoomsNumberField.theme';

export const RoomsNumberField = (): JSX.Element => {
    const [t] = useTranslations();
    const { control } = useFormContext<FormData>();
    const { trackEvent } = useTracking();

    const inputId = 'rooms-number-field';

    const handleTrackMenuOpen = useCallback(() => {
        trackEvent('nb_rooms_click');
    }, [trackEvent]);

    const handleTrackOnSelect = useCallback(
        (option: RoomsNumberOptionType | null) => {
            trackEvent('nb_rooms_selected', {
                nb_rooms: option?.order ? [option.order] : null,
            });
        },
        [trackEvent],
    );

    return (
        <div>
            <FieldLabel htmlFor={inputId}>{t('frontend.companies.search-form.roomsnumber-label')}</FieldLabel>
            <Controller
                control={control}
                name="roomsNumber"
                render={({ field: { value, ...field } }): JSX.Element => (
                    <Dropdown
                        {...field}
                        isMulti
                        isSearchable={false}
                        instanceId={inputId}
                        inputId={inputId}
                        placeholder={t('frontend.search.form-roomsnumber-placeholder')}
                        options={ROOMS_NUMBER_OPTIONS_VALUES}
                        value={value?.sort((a, b) => {
                            if (a.order < b.order) return -1;
                            if (a.order > b.order) return 1;

                            return 0;
                        })}
                        components={{
                            MultiValue: ({ children }): JSX.Element => {
                                return <StyledValue>{children}</StyledValue>;
                            },
                            DropdownIndicator: (props): JSX.Element | null => {
                                const hasSelectedValues = props.getValue().length > 0;

                                if (hasSelectedValues) {
                                    return null;
                                }

                                return <components.DropdownIndicator {...props} />;
                            },
                        }}
                        styles={{
                            valueContainer: (base) => ({
                                ...base,
                                flexWrap: 'nowrap',
                                whiteSpace: 'nowrap',
                            }),
                        }}
                        trackOnMenuOpen={handleTrackMenuOpen}
                        trackOnSelect={handleTrackOnSelect}
                    />
                )}
            />
        </div>
    );
};
