import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import SearchIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Search';
import type { CSSProperties } from 'react';

interface Props {
    isDropdownOpened: boolean;
}

export const NexusDropdownTrigger = styled.button<{ withClearIcon: boolean }>`
    display: flex;
    align-items: center;
    gap: ${theme.space[8]};
    width: 100%;
    min-width: 0;
    height: ${theme.sizes[48]};
    padding: ${theme.space[12]} ${theme.space[16]};
    border: none;
    border-bottom: ${theme.borderWidths[2]} solid transparent;
    border-radius: ${({ withClearIcon }): CSSProperties['borderRadius'] =>
        withClearIcon ? `${theme.radii[4]} 0 0 ${theme.radii[4]}` : theme.radii[4]};
    outline: none;
    background-color: ${theme.colors['background-global-secondary']};
    color: ${theme.colors['text-global-primary']};
    font-size: ${theme.fontSizes[16]};

    &:focus {
        border-bottom: ${theme.borderWidths[2]} solid;
        border-radius: ${theme.radii[4]} ${theme.radii[4]} 0 0;
        border-color: ${theme.colors['background-brand-primary']};
    }
`;

export const NexusListWrapper = styled.div<Props>`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: ${theme.space[0]};
    overflow: auto;

    @media ${theme.mediaQueries.lgMin} {
        max-height: 60vh;
        padding: ${theme.space[4]} ${theme.space[0]} ${theme.space[0]};
        border-top: ${theme.borderWidths[2]} solid ${theme.colors['background-brand-primary']};
    }
`;

export const NexusContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: ${theme.radii[4]};
    box-shadow: 0 0 ${theme.sizes[6]} 0 rgb(0 0 0 / 30%);

    @media ${theme.mediaQueries.lgMin} {
        background-color: ${theme.colors['background-global-secondary']};
    }
`;

export const StyledSearchIcon = styled(SearchIcon)`
    flex-shrink: 0;
`;
