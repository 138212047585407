import { Name, SeeProfileLink, Wrapper } from '@domains/companies/sellerPage/nexusComponents/Team/ContactPerson.theme';
import { PhoneReveal } from '@domains/companies/sellerPage/nexusComponents/Team/PhoneReveal';
import type { ContactPerson as ContactPersonType } from '@domains/companies/sellerPage/types/contactPerson';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { getAgentProfileUrl } from '@domains/shared/helpers/getAgentProfileUrl';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { Avatar } from '@nexus/lib-react/dist/core/Avatar/Avatar';
import { type JSX, useRef } from 'react';

export const ContactPerson = ({
    contact,
    isAgency,
}: {
    contact: ContactPersonType;
    isAgency: boolean;
}): JSX.Element => {
    const [t] = useTranslations();
    const { lang } = useSiteSettings();
    const { trackEvent } = useTracking();
    const teamMemberRef = useRef<HTMLDivElement | null>(null);
    const {
        name: { firstName, lastName },
        id,
        image,
        phones,
        profileId,
    } = contact;

    const [phone] = phones;

    const profileUrl = profileId ? getAgentProfileUrl({ firstName, lastName, id: profileId, lang }) : undefined;

    const trackProfileLinkClick = (): void => {
        trackEvent('seller_profile_click', { touch_point_button: 'team' });
    };

    useTrackImpression(teamMemberRef, () => {
        trackEvent('team_member_impression', { touch_point_button: 'team', seller_id: id });
    });

    return (
        <Wrapper ref={teamMemberRef} key={id}>
            <Avatar src={image?.url} size={72} />
            <Name>
                {firstName} {lastName}
            </Name>
            {isAgency && profileUrl ? (
                <LocalLink passHref href={profileUrl}>
                    <SeeProfileLink variant="link" onClick={trackProfileLinkClick}>
                        {t('frontend.companies-seller-page.team.see-profile')}
                    </SeeProfileLink>
                </LocalLink>
            ) : null}
            {!isAgency && phone ? <PhoneReveal phone={phone} /> : null}
        </Wrapper>
    );
};
