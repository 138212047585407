import { AdHeading } from '@domains/companies/sellerPage/nexusComponents/SellerAds/components/AdvertCard/components/AdHeading/AdHeading';
import { SpecsListWrapper } from '@domains/companies/sellerPage/nexusComponents/SellerAds/components/AdvertCard/components/SpecsListWrapper/SpecsListWrapper';
import type { AdvertItem } from '@domains/companies/sellerPage/types/sellerPageSearchAds';
import { Description } from '@domains/search/components/AdvertCard/components/Description/Description';
import { extractLocationDetails } from '@domains/search/components/AdvertCard/helpers/extractLocationDetails';
import { generateAdvertLink } from '@domains/shared/helpers/generateAdvertLink';
import { useTracking } from '@lib/tracking/useTracking';
import { ESTATE } from '@type/search/filters/estate';
import { Address } from '@widgets/AdvertCard/Address';
import { Anchor, InteractiveIsland } from '@widgets/AdvertCard/Navigation';
import { Title } from '@widgets/AdvertCard/Title';

import { Content, Wrapper } from './AdInfo.theme';

export const AdInfo = ({ ad }: { ad: AdvertItem }): JSX.Element => {
    const { trackEvent } = useTracking();
    const { shortDescription, location, estate, slug, title, id } = ad;
    const { address, radius, locations } = extractLocationDetails(location);
    const isInvestment = estate === ESTATE.investment;
    const advertLink = generateAdvertLink({ slug: slug, isInvestment });

    const handleAdClick = (): void => {
        trackEvent('ad_click', {
            touch_point_button: 'ads',
            ad_id: id,
        });
    };

    return (
        <Wrapper>
            <InteractiveIsland shouldAffectEntireContainer shouldStopEventPropagation>
                <Content>
                    <AdHeading ad={ad} />
                    <div>
                        <Anchor href={advertLink} onClick={handleAdClick}>
                            <Title>{title}</Title>
                        </Anchor>
                        <Address radius={radius} street={address.street} locations={locations} />
                    </div>
                    <div>
                        <SpecsListWrapper ad={ad} />
                        {shortDescription && shortDescription.length > 0 ? (
                            <Description description={shortDescription} adId={id} />
                        ) : null}
                    </div>
                </Content>
            </InteractiveIsland>
        </Wrapper>
    );
};
