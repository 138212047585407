import { AdvertItem } from '@domains/companies/sellerPage/components/AdvertItem/AdvertItem';
import { ButtonWithMapBackground } from '@domains/companies/sellerPage/components/ButtonWithMapBackground/ButtonWithMapBackground';
import { SidebarContactForm } from '@domains/companies/sellerPage/components/ContactForm/sidebar/SidebarContactForm';
import { FiltersSection } from '@domains/companies/sellerPage/components/FiltersSection/FiltersSection';
import { InactiveAdvertsNotice } from '@domains/companies/sellerPage/components/InactiveAdvertsNotice/InactiveAdvertsNotice';
import { LocationSeoLinksSection } from '@domains/companies/sellerPage/components/LocationSeoLinks/LocationSeoLinksSection';
import { Pagination } from '@domains/companies/sellerPage/components/Pagination/Pagination';
import type { ContactPerson } from '@domains/companies/sellerPage/types/contactPerson';
import type { FormData } from '@domains/companies/sellerPage/types/filtersData';
import type { AdvertItem as AdvertItemType } from '@domains/companies/sellerPage/types/sellerPageSearchAds';
import { useModal } from '@domains/companies/shared/hooks/useModal';
import { STANDARD_SORTING_OPTIONS } from '@domains/shared/components/ListingSorting/constants/standardSortingOptions';
import { ListingSorting } from '@domains/shared/components/ListingSorting/ListingSorting';
import type { ButtonsProps } from '@domains/shared/components/ListingSorting/types/button';
import { NoSearchResults } from '@domains/shared/components/NoSearchResults/NoSearchResults';
import { SearchResultCount } from '@domains/shared/components/SearchResultCount/SearchResultCount';
import { URL_LOCATION_FILLER } from '@domains/shared/consts/urlLocationFiller';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { SearchLinksRelatedLocations } from '@type/location/locationLink';
import type { SellerPagePagination } from '@type/pagination/sellerPagePagination';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { Estate } from '@type/search/filters/estate';
import type { Transaction } from '@type/search/filters/transaction';
import { SORTING_BUTTON_OPTION } from '@type/sorting/buttonOption';
import type { SortingBy } from '@type/sorting/by';
import { SORTING_BY, STANDARD_SORTING_VALUES } from '@type/sorting/by';
import type { SortingDirection } from '@type/sorting/direction';
import type { SortingOption } from '@type/sorting/option';
import type { AdvertUserType } from '@type/user/userType';
import { useGetLocationLinks } from '@widgets/search/SeoLinks';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { useCallback, useContext, useRef } from 'react';

import {
    AdvertListingAndContactSectionWrapper,
    AdvertListingWrapper,
    AdvertsList,
    AsideSectionWrapper,
    AsideStickyContainer,
    BottomSectionWrapper,
    FilterAndSortWrapper,
    FiltersButton,
    InternalLinkingBlockWrapper,
    MainContentTopBar,
    MainContentWrapper,
    MapButtonWrapper,
} from './DiscoverySection.theme';

const LazyOurTeamSection = dynamic(
    () => import('@domains/companies/sellerPage/components/OurTeamSection/OurTeamSection'),
);

interface Props {
    ownerId: string;
    ownerType: AdvertUserType;
    adverts: AdvertItemType[];
    pagination: SellerPagePagination;
    formData: FormData;
    seoLinks: SearchLinksRelatedLocations;
    hasActiveAdverts: boolean;
    sortingOption: SortingOption;
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    contactPeople: ContactPerson[];
}

const FILTER_BUTTON_VALUE = 'FILTER_BUTTON';

export const DiscoverySection = ({
    ownerId,
    ownerType,
    adverts,
    pagination,
    formData,
    seoLinks,
    hasActiveAdverts,
    sortingOption,
    fieldsMetadataExperimentsVariants,
    contactPeople,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { isDesktop } = useContext(RWDContext);
    const { open: detachSearchForm, isVisible: isSearchFormPoppedOut, close: attachSearchForm } = useModal();
    const { trackEvent } = useTracking();
    const goToMapButtonRef = useRef<HTMLButtonElement>(null);

    const { propertiesForSell, propertiesForRent } = useGetLocationLinks({
        location: URL_LOCATION_FILLER.allLocations.label,
        estate: formData.estate?.value as Estate,
        transaction: formData.transaction?.value as Transaction,
    });

    const areFiltersTooRestrictive = pagination.totalItems === 0;
    const shouldDisplaySeoLinks = seoLinks?.items?.length > 0;

    const sortingButtons: ButtonsProps[] = [
        {
            label: t('frontend.shared.sorting.latest-asc'),
            value: SORTING_BUTTON_OPTION.latest,
        },
        {
            label: t('frontend.shared.sorting.price-asc'),
            value: SORTING_BUTTON_OPTION.price,
            hideOnMobile: true,
        },
        {
            label: '...',
            value: SORTING_BY.none,
            dataPopoverTrigger: 'data-popover-trigger',
            ariaLabel: t('frontend.shared.sorting.more-options'),
        },
    ];

    const handleSortingChange = useCallback(
        ({ by, direction }: { by: SortingBy; direction: SortingDirection }) => {
            trackEvent('sort_order_change', {
                order_by: `${by.toLowerCase()}_${direction.toLowerCase()}`,
            });
        },
        [trackEvent],
    );

    const handleSortButtonChange = useCallback(() => {
        trackEvent('sort_order_click');
    }, [trackEvent]);

    // For now BE does not return any estate for seoLinks for category ALL which throws Incorrect label in URL segment: [estate]: ALL
    // For now we want to return seoLinks with FLAT category for ALL estate; if in the future BE returns this data for ALL estate, this should be deleted
    const searchLinksRelatedLocations = seoLinks.items.map((item) =>
        item.estate === 'ALL' ? { ...item, estate: 'FLAT', name: item.name } : item,
    );

    return (
        <>
            <FiltersSection
                fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                ownerId={ownerId}
                ownerType={ownerType}
                formData={formData}
                hasActiveAdverts={hasActiveAdverts}
                isSearchFormPoppedOut={isSearchFormPoppedOut}
                attachSearchForm={attachSearchForm}
            />

            <BottomSectionWrapper>
                <MainContentWrapper>
                    {hasActiveAdverts ? (
                        <MainContentTopBar>
                            <SearchResultCount total={pagination.totalItems} data-cy="adverts-result-count" />
                            <FilterAndSortWrapper>
                                {isDesktop ? null : (
                                    <FiltersButton
                                        value={FILTER_BUTTON_VALUE}
                                        onClick={detachSearchForm}
                                        data-cy="adverts-filters-open-button"
                                    >
                                        {t('frontend.search.listing.filters')}
                                    </FiltersButton>
                                )}
                                <ListingSorting
                                    sortingOption={sortingOption}
                                    buttons={sortingButtons}
                                    allSortingOptions={STANDARD_SORTING_OPTIONS}
                                    sortingValues={STANDARD_SORTING_VALUES}
                                    onSortingChange={handleSortingChange}
                                    onSortButtonChange={handleSortButtonChange}
                                    containerDataCy="adverts-sorting-container"
                                />
                            </FilterAndSortWrapper>
                        </MainContentTopBar>
                    ) : null}

                    <AdvertListingAndContactSectionWrapper>
                        <AdvertListingWrapper>
                            {hasActiveAdverts ? (
                                <>
                                    <MapButtonWrapper>
                                        <ButtonWithMapBackground ref={goToMapButtonRef} />
                                    </MapButtonWrapper>
                                    {areFiltersTooRestrictive ? (
                                        <NoSearchResults
                                            description="frontend.companies.discovery-section.no-search-results"
                                            data-cy="no-adverts-result-fallback-message"
                                        />
                                    ) : (
                                        <>
                                            <AdvertsList data-cy="adverts-list-container">
                                                {adverts.map((advert) => (
                                                    <AdvertItem key={advert.id} {...advert} />
                                                ))}
                                            </AdvertsList>
                                            <Pagination
                                                currentPage={pagination.currentPage}
                                                itemsPerPage={pagination.itemsPerPage}
                                                totalItems={pagination.totalItems}
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <InactiveAdvertsNotice data-cy="inactive-adverts-fallback-message" />
                            )}
                        </AdvertListingWrapper>

                        <AsideSectionWrapper hasTopMargin={!hasActiveAdverts} data-cy="sidebar-contact-section">
                            <AsideStickyContainer>
                                <SidebarContactForm ownerId={ownerId} />
                            </AsideStickyContainer>
                        </AsideSectionWrapper>
                    </AdvertListingAndContactSectionWrapper>

                    {contactPeople.length > 0 ? <LazyOurTeamSection contactPeople={contactPeople} /> : null}
                </MainContentWrapper>

                {shouldDisplaySeoLinks ? (
                    <InternalLinkingBlockWrapper data-cy="location-seo-links">
                        <LocationSeoLinksSection
                            searchLinksRelatedLocations={searchLinksRelatedLocations}
                            propertiesForSell={propertiesForSell}
                            propertiesForRent={propertiesForRent}
                        />
                    </InternalLinkingBlockWrapper>
                ) : null}
            </BottomSectionWrapper>
        </>
    );
};
