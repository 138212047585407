import { SITE_CONFIG } from '@config/siteConfig';
import { PhoneNumber } from '@domains/shared/components/PhoneNumber/PhoneNumber';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import type { JSX } from 'react';

import { Container, StyledIcon, StyledList } from './PhoneNumbersList.theme';

interface Props {
    numbers: string[];
    onReveal?: (number: string) => void;
}

export const PhoneNumersList = ({ numbers, onReveal }: Props): JSX.Element => {
    return (
        <Container>
            <StyledIcon icon={faPhone} flip="horizontal" size="lg" />
            <StyledList>
                {numbers.map((number) => (
                    <li key={number}>
                        <PhoneNumber
                            number={number}
                            country={SITE_CONFIG.phoneNumbers.country}
                            nationalCountryCode={SITE_CONFIG.phoneNumbers.countryCode}
                            onRevealCallback={(): void => onReveal?.(number)}
                        />
                    </li>
                ))}
            </StyledList>
        </Container>
    );
};
