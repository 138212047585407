import { SITE_CONFIG } from '@config/siteConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useCallback } from 'react';

import { MinMaxInput } from '../components/MinMaxInput';

export const PriceField = (): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const handleBlur = useCallback(
        (fromValue: number | null, toValue: number | null) => {
            trackEvent('price_selected', { from_price: fromValue, to_price: toValue });
        },
        [trackEvent],
    );

    return (
        <MinMaxInput
            label={t('frontend.companies.search-form.price-label')}
            name="price"
            appendix={SITE_CONFIG.defaultUnits.currency}
            onBlur={handleBlur}
        />
    );
};
