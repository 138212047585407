import type { FormData } from '@domains/companies/sellerPage/types/filtersData';
import { Dropdown } from '@domains/shared/components/Dropdown/Dropdown';
import type { JSX } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { Options } from 'react-select';

import { FieldLabel } from './FieldLabel';

type EligibleFields = keyof Pick<FormData, 'estate' | 'transaction' | 'market'>;

interface Props {
    label: string;
    name: EligibleFields;
    options: Options<NonNullable<FormData[EligibleFields]>>;
    trackOnMenuOpen?: () => void;
    trackOnSelect?: (option: FormData[EligibleFields]) => void;
}

export const ControlledDropdown = ({ label, name, options, trackOnMenuOpen, trackOnSelect }: Props): JSX.Element => {
    const { control } = useFormContext<FormData>();

    const id = `${name}-field`;

    return (
        <div>
            <FieldLabel htmlFor={id}>{label}</FieldLabel>
            <Controller
                control={control}
                name={name}
                render={({ field }): JSX.Element => (
                    <Dropdown
                        {...field}
                        inputId={id}
                        instanceId={id}
                        options={options}
                        trackOnMenuOpen={trackOnMenuOpen}
                        trackOnSelect={trackOnSelect}
                    />
                )}
            />
        </div>
    );
};
