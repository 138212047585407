import { SELLER_BADGES_LIST_ITEM } from '@domains/companies/sellerPage/components/constants/sellerBadges';
import { logError } from '@domains/shared/helpers/logger';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { SELLER_BADGE_ID, type SellerBadge } from '@type/sellerBadge/sellerBadge';
import type { JSX } from 'react';

import { BadgeIcon, BadgeItem, ListWrapper } from './SellerBadges.theme';

interface Props {
    sellerBadges?: SellerBadge[];
}

const POSSIBLE_BADGES = new Set(Object.values(SELLER_BADGE_ID));

export const SellerBadgesList = ({ sellerBadges = [] }: Props): JSX.Element | null => {
    const [t] = useTranslations();
    const { featureFlags } = useSiteSettings();
    const { isBadgesFeatureEnabled } = featureFlags;

    const badgesToShow = sellerBadges.filter(({ id }) => POSSIBLE_BADGES.has(id));

    if (badgesToShow.length !== sellerBadges.length) {
        logError(`Invalid seller badge id found in the list: ${JSON.stringify(sellerBadges)}`);
    }

    if (badgesToShow.length === 0 || !isBadgesFeatureEnabled) {
        return null;
    }

    return (
        <ListWrapper data-testid="sellerBadgesSection">
            {badgesToShow.map(({ id: badgeId }) => {
                const { title, icon } = SELLER_BADGES_LIST_ITEM[badgeId];

                return (
                    <BadgeItem key={badgeId}>
                        <BadgeIcon src={icon} alt={title} />
                        {t(title)}
                    </BadgeItem>
                );
            })}
        </ListWrapper>
    );
};
