import styled from '@emotion/styled';

export const ButtonGroupWrapper = styled.div`
    display: inline-flex;

    * + * {
        margin-left: -1px;
    }
    > *:first-of-type {
        margin-left: 0;
    }
    > *:not(:first-of-type) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    > *:not(:last-of-type) {
        margin-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`;
