import type { PropsWithChildren, Ref } from 'react';

import { Title, Wrapper } from './Section.theme';

interface Props extends PropsWithChildren {
    title: string;
    className?: string;
    shouldHaveTitleMarginOnMobile?: boolean;
    innerRef?: Ref<HTMLDivElement>;
}

export const Section = ({
    title,
    children,
    className,
    shouldHaveTitleMarginOnMobile = true,
    innerRef,
}: Props): JSX.Element => {
    return (
        <Wrapper ref={innerRef} className={className}>
            <Title shouldHaveMarginOnMobile={shouldHaveTitleMarginOnMobile}>{title}</Title>
            {children}
        </Wrapper>
    );
};
