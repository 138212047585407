import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { theme } from '@lib/styles/themes/nexus';

export const ListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: ${theme.space[48]};
    width: 100%;
    padding: ${theme.space[24]} 0;

    @media (max-width: ${BREAKPOINT.md}) {
        justify-content: space-between;
        gap: 0;
        padding: ${theme.space[24]} 0;
    }

    @media (max-width: ${BREAKPOINT.xs}) {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: ${theme.space['40']} 0;
    }
`;

export const BadgeItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
    gap: ${theme.space[8]};

    @media (max-width: ${BREAKPOINT.xs}) {
        width: 144px;
    }
`;

export const BadgeIcon = styled.img`
    width: auto;
    height: ${theme.sizes[24]};
    object-fit: contain;
`;
