import type { DistanceRadiusType } from '@type/search/filters/distanceRadius';
import type { SearchPageVariant } from '@type/search/pageVariant';
import { SEARCH_FORM_UNIVERSAL_FIELD } from '@type/search/searchFormUniversalField';
import type { JSX } from 'react';
import { useState } from 'react';

import type { LocationPickerProps } from '../types/locationPicker';
import { DistanceRadius } from './components/DistanceRadius/DistanceRadius';
import { LocationPickerTree } from './components/LocationPickerTree/LocationPickerTree';
import { LocationPickerContainer } from './LocationPicker.theme';

interface Props extends LocationPickerProps {
    distanceRadiusOptions?: Record<DistanceRadiusType, number[]>;
    defaultDistanceRadius?: Record<DistanceRadiusType, number>;
    viewType?: SearchPageVariant;
    distanceRadiusDataCy?: string;
    hasSmallRadiusPicker?: boolean;
    shouldUseFixedDistanceRadiusInput?: boolean;
}

/** @deprecated This location picker won't have any significant updates in the future. Consider using `LocationSearch`. */
export const LocationPicker = ({
    distanceRadiusOptions,
    defaultDistanceRadius,
    viewType,
    distanceRadiusDataCy = `search-form--field--${SEARCH_FORM_UNIVERSAL_FIELD.distanceRadius}`,
    shouldUseNexusTheme,
    hasSmallRadiusPicker = false,
    shouldUseFixedDistanceRadiusInput = false,
    ...pickerEntryProps
}: Props): JSX.Element => {
    const { initialSelectedGoogleSuggestion } = pickerEntryProps;
    const [distanceRadiusType, setDistanceRadiusType] = useState<DistanceRadiusType>(
        initialSelectedGoogleSuggestion ? 'm' : 'km',
    );

    const isDistanceRadiusDataSet =
        viewType === 'listing' && distanceRadiusOptions && distanceRadiusOptions[distanceRadiusType].length > 0;

    return (
        <LocationPickerContainer
            shouldUseNexusTheme={shouldUseNexusTheme}
            hasSmallRadiusPicker={hasSmallRadiusPicker}
            shouldUseFixedDistanceRadiusInput={shouldUseFixedDistanceRadiusInput}
        >
            <LocationPickerTree
                {...pickerEntryProps}
                changeDistanceRadiusType={setDistanceRadiusType}
                shouldUseNexusTheme={shouldUseNexusTheme}
            />

            {isDistanceRadiusDataSet ? (
                <>
                    {shouldUseNexusTheme ? <div style={{ width: 8 }} /> : null}
                    <DistanceRadius
                        dataCy={distanceRadiusDataCy}
                        options={distanceRadiusOptions}
                        selectedDistanceType={distanceRadiusType}
                        shouldUseNexusTheme={shouldUseNexusTheme}
                        defaultDistanceRadius={defaultDistanceRadius}
                    />
                </>
            ) : null}
        </LocationPickerContainer>
    );
};
