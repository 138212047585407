export const DEFAULT_CURRENT_PAGE = 1;
export const MIN_CURRENT_PAGE = 1;

export const ITEMS_PER_PAGE_VALUES = [24, 36, 48, 72] as const;

export const DEFAULT_ITEMS_PER_PAGE = ITEMS_PER_PAGE_VALUES[0];
export const MIN_ITEMS_PER_PAGE = ITEMS_PER_PAGE_VALUES[0];
export const MAX_ITEMS_PER_PAGE = ITEMS_PER_PAGE_VALUES[ITEMS_PER_PAGE_VALUES.length - 1];

export const NEXUS_AGENCY_ITEMS_PER_PAGE = 6;
export const NEXUS_DEVELOPER_ITEMS_PER_PAGE = 3;
