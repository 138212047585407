import { COMPANIES_CONFIG } from '@config/companies/companiesConfig';
import { GET_ORGANISATION_MEMBERS } from '@domains/companies/sellerPage/graphql/queries/GetOrganisationMembers';
import { formatContactPerson } from '@domains/companies/sellerPage/helpers/formatContactPerson';
import type { GetOrganisationMembersQueryResult } from '@domains/companies/sellerPage/types/agentContact';
import type { ContactPerson } from '@domains/companies/sellerPage/types/contactPerson';
import type { SellerPageOwner } from '@domains/companies/sellerPage/types/sellerPageOwner';
import { useHandleGraphqlError } from '@lib/graphql/hooks/useHandleGraphqlError';
import { OWNER_TYPE } from '@type/ad/ownerType';
import { useQuery } from 'urql';

export const useFetchAgencyContacts = ({
    owner,
    isMapDisplayed,
}: {
    owner: SellerPageOwner;
    isMapDisplayed: boolean;
}): ContactPerson[] | undefined => {
    const [{ data, error, operation }] = useQuery<GetOrganisationMembersQueryResult, { sellerId: string }>({
        query: GET_ORGANISATION_MEMBERS,
        variables: {
            sellerId: owner.id,
        },
        pause: !COMPANIES_CONFIG.sellerPage.hasAgentAccounts || owner.type !== OWNER_TYPE.agency || isMapDisplayed,
    });

    useHandleGraphqlError({ descriptor: 'GetOrganisationMembersQuery', error, operation });

    if (!data || !Array.isArray(data.organisationMembers.organisationMembers)) {
        return undefined;
    }

    return data.organisationMembers?.organisationMembers.map(formatContactPerson);
};
