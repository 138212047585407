import { MARKET_OPTIONS } from '@domains/companies/sellerPage/config/filters/market';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';

import { ControlledDropdown } from '../components/ControlledDropdown';

export const MarketField = (): JSX.Element => {
    const [t] = useTranslations();

    return (
        <ControlledDropdown
            label={t('frontend.companies.search-form.market-label')}
            name="market"
            options={MARKET_OPTIONS}
        />
    );
};
