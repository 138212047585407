import { Button } from '@domains/shared/components/Button/Button';
import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { TextButton } from '@domains/shared/components/TextButton/TextButton';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import type { JSX, PropsWithChildren } from 'react';
import { useContext } from 'react';

interface Props {
    type: 'button' | 'submit' | 'reset';
    onClick: () => void;
}

export const ResetButton = (props: PropsWithChildren<Props>): JSX.Element => {
    const { isDesktop } = useContext(RWDContext);

    if (isDesktop) {
        return <TextButton variant="secondary" {...props} />;
    }

    return <Button variant={ButtonVariant.Ghost} {...props} />;
};
