import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Wrapper = styled.div`
    position: fixed;
    inset: 0;

    /* main app bar has z-index 600 */
    z-index: 700;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: ${theme.colors['background-global-primary']};
`;
