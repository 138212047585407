export const TRANSACTION_METADATA = {
    rent: {
        label: 'frontend.global.search.transaction-rent' as string,
        value: 'RENT',
    },
    sell: {
        label: 'frontend.global.search.transaction-sell' as string,
        value: 'SELL',
    },
} as const;

export const TRANSACTION_OPTIONS = Object.values(TRANSACTION_METADATA);

export const DEFAULT_TRANSACTION_OPTION = TRANSACTION_METADATA.sell;

export const VALID_TRANSACTION_VALUES = TRANSACTION_OPTIONS.map(({ value }) => value) as ReadonlyArray<string>;
