import type { Address } from '@type/location/address';

export const getFormattedCompanyAddress = ({ city, street }: Address): string | null => {
    if (!city) {
        return null;
    }

    if (!street) {
        return city.name;
    }

    if (!street.number) {
        return `${city.name}, ${street.name}`;
    }

    return `${city.name}, ${street.name} ${street.number}`;
};
