import { useLocalStorageCache } from '@domains/shared/hooks/useLocalStorageCache/useLocalStorageCache';
import type { TreeNode } from '@type/search/location/pickerTreeNode';
import { useCallback } from 'react';

import { LOC_VERSIONS } from '../../constants/locationVersion';
import { type JsonTreeNode, checkIsJsonTreeNode } from '../../types/locations';
import { STORAGE_KEY_NODES, STORAGE_KEY_RECENTS, STORAGE_KEY_ROOTS, STORAGE_KEY_VERSION } from './constants';
import { treeNodeToJsonTreeNode } from './helpers/treeNodeToJsonTreeNode';

type LocVersionType = ObjectValues<typeof LOC_VERSIONS>;
const checkIsLocVersionType = (data: unknown): data is LocVersionType =>
    Object.values(LOC_VERSIONS).includes(data as LocVersionType);

const checkIsArrayOfStrings = (data: unknown): data is string[] =>
    Array.isArray(data) && data.every((item) => typeof item === 'string');
const checkIsNodesCache = (data: unknown): data is [string, JsonTreeNode][] =>
    Array.isArray(data) && data.every((item) => typeof item[0] === 'string' && checkIsJsonTreeNode(item[1]));

interface UseCache {
    saveCache(options: { roots?: TreeNode[]; nodes?: Map<string, TreeNode>; recent?: TreeNode[] }): void;
    // roots, tree, recent, version
    readCache(): [TreeNode[] | null, Map<string, TreeNode> | null, TreeNode[], LocVersionType | null];
}

export const useCache = (): UseCache => {
    //<string[]>
    const [getCacheRoots, saveCacheRoots] = useLocalStorageCache(STORAGE_KEY_ROOTS);
    //[string, JsonTreeNode][]
    const [getCacheNodes, saveCacheNodes] = useLocalStorageCache(STORAGE_KEY_NODES);
    const [getCacheRecent, saveCacheRecent] = useLocalStorageCache(STORAGE_KEY_RECENTS);
    const [getCacheVersion, saveCacheVersion] = useLocalStorageCache(STORAGE_KEY_VERSION);

    const saveCache = useCallback<UseCache['saveCache']>(
        ({ roots, nodes, recent }) => {
            const storageCacheVersion = getCacheVersion();
            const currentCacheVersion = checkIsLocVersionType(storageCacheVersion) ? storageCacheVersion : undefined;

            if (roots !== undefined) {
                saveCacheRoots(roots.map((item) => item.id));
            }
            if (nodes || roots) {
                saveCacheNodes(nodes ? [...nodes.values()].map(treeNodeToJsonTreeNode) : []);
            }
            if (recent) {
                saveCacheRecent(recent.map((item) => item.id));
            }
            if (!currentCacheVersion) {
                saveCacheVersion(LOC_VERSIONS.domainIds);
            }
        },
        [getCacheVersion, saveCacheNodes, saveCacheRecent, saveCacheRoots, saveCacheVersion],
    );

    const readCache = useCallback<UseCache['readCache']>(() => {
        const rootsCache = getCacheRoots();
        const roots = checkIsArrayOfStrings(rootsCache) ? rootsCache : null;

        const nodesCache = getCacheNodes();
        const nodes = checkIsNodesCache(nodesCache) ? nodesCache : null;

        const recentCache = getCacheRecent();
        const recent = checkIsArrayOfStrings(recentCache) ? recentCache : [];

        const storageCacheVersion = getCacheVersion();
        const currentCacheVersion = checkIsLocVersionType(storageCacheVersion) ? storageCacheVersion : null;

        const isCacheEmpty = !roots || !nodes || !currentCacheVersion;

        if (isCacheEmpty) {
            return [null, null, [], null];
        }

        const map = new Map<string, TreeNode>(nodes as unknown as [string, TreeNode][]);

        // add missing fields (parent, map children)
        for (const [, item] of map as unknown as Map<string, JsonTreeNode>) {
            const treeNodeItem = item as unknown as TreeNode;
            const children = item.children.map((id) => map.get(id)).filter((item): item is TreeNode => !!item);

            // set children parents
            for (const child of children) child.parent = treeNodeItem;
            // update children references
            treeNodeItem.children = children;
        }

        return [
            roots.map((id) => map.get(id)).filter((item): item is TreeNode => !!item),
            map,
            recent.map((id) => map.get(id)).filter((item): item is TreeNode => !!item),
            currentCacheVersion,
        ];
    }, [getCacheRoots, getCacheNodes, getCacheRecent, getCacheVersion]);

    return { readCache, saveCache };
};
