import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

interface Props {
    shouldUseNexusTheme?: boolean;
    hasSmallRadiusPicker?: boolean;
    shouldUseFixedDistanceRadiusInput?: boolean;
}

const getRadiusPickerWidth = (
    { shouldUseNexusTheme, hasSmallRadiusPicker, shouldUseFixedDistanceRadiusInput }: Props,
    isLargeScreen?: boolean,
): CSSProperties['minWidth'] => {
    if (shouldUseFixedDistanceRadiusInput) {
        return '136px';
    }

    if (hasSmallRadiusPicker) {
        return '112px';
    }

    if (shouldUseNexusTheme) {
        return isLargeScreen ? theme.sizes[192] : theme.sizes[128];
    }

    return theme.sizes[96];
};

const getRadiusPickerMarginLeft = (
    { shouldUseNexusTheme, shouldUseFixedDistanceRadiusInput }: Props,
    isLargeScreen?: boolean,
): CSSProperties['marginLeft'] => {
    if (shouldUseFixedDistanceRadiusInput) {
        return '0';
    }
    if (shouldUseNexusTheme) {
        return isLargeScreen ? '-4px' : '0';
    }

    return '-1px';
};

export const LocationPickerContainer = styled.div<Props>`
    display: flex;

    & > button:first-of-type {
        flex: 1;
    }

    & > div:last-of-type {
        min-width: ${(props): CSSProperties['minWidth'] => getRadiusPickerWidth(props)};
        margin-left: ${(props): CSSProperties['marginLeft'] => getRadiusPickerMarginLeft(props)};
    }

    @media ${theme.mediaQueries.lgMin} {
        position: relative;

        & > div:last-of-type {
            min-width: ${(props): CSSProperties['minWidth'] => getRadiusPickerWidth(props, true)};
            margin-left: ${(props): CSSProperties['marginLeft'] => getRadiusPickerMarginLeft(props, true)};
        }
    }
`;
