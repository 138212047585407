import type { FormData } from '@domains/companies/sellerPage/types/filtersData';
import { getDistanceRadiusOptions } from '@domains/shared/helpers/getDistanceRadiusOptions';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { LocationsObjects } from '@type/search/location/dataItems';
import { SEARCH_FIELDS_METADATA } from '@widgets/search/types/fieldsMetadata';
import type { FC } from 'react';

import { FieldLabel, StyledLocationSearch } from './LocationFilter.theme';

interface Props {
    locationsObjects: LocationsObjects[] | null;
    formData: FormData;
}

const DISTANCE_RADIUS_OPTIONS = getDistanceRadiusOptions(SEARCH_FIELDS_METADATA);
const DEFAULT_DISTANCE_RADIUS_OPTION = {
    km: 0,
    m: 300,
};

export const LocationFilter: FC<Props> = ({ formData, locationsObjects }) => {
    const [t] = useTranslations();

    return (
        <div>
            <FieldLabel>{t('frontend.companies-seller-page.location-filter.label')}</FieldLabel>
            <StyledLocationSearch
                initial={{
                    locations: locationsObjects,
                    distanceRadius: formData.distanceRadius ? Number(formData.distanceRadius) : null,
                }}
                distanceRadiusOptions={DISTANCE_RADIUS_OPTIONS}
                distanceRadiusDefaultValues={DEFAULT_DISTANCE_RADIUS_OPTION}
                config={{
                    multiple: true,
                    enableSearchFromList: true,
                    recentSuggestions: {
                        enabled: true,
                        localStorageKey: 'LocationSearchRecentLocations',
                        locationsToRemember: 3,
                    },
                    tracking: {
                        entryInputTouchPointButton: 'filters',
                        touchPointPage: 'seller_listing',
                    },
                }}
                dataCy={{
                    distanceRadius: 'adverts-filters-distance-radius',
                    entryInput: 'adverts-filters-entry-input',
                }}
            />
        </div>
    );
};
