import { Button } from '@domains/shared/components/Button/Button';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

import { SEARCH_WIDGET_THEME } from '../../../theme/[[[SITECODE]]]';

const getDropdownWrapperPadding = (shouldUseNexusTheme?: boolean): CSSProperties['padding'] => {
    return shouldUseNexusTheme
        ? `${theme.sizes[56]} ${theme.space[0]} ${theme.sizes[72]} ${theme.space[0]}`
        : `52px ${theme.space[0]} ${theme.sizes[72]} ${theme.space[0]}`;
};

interface Props {
    isDropdownOpened: boolean;
}

export const DropdownTrigger = styled.button<{ withClearIcon: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: ${theme.space[2]} ${theme.space[8]};
    overflow: hidden;
    border: ${theme.borderWidths[1]} ${theme.borderStyles.solid};
    border-radius: 2px 0 0 2px;
    border-color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.borderColor};
    outline: none;
    background-color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.backgroundColor};
    color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.color};
    font-size: ${theme.fontSizes.p3};
    cursor: pointer;

    &:hover,
    &:focus {
        z-index: 1;
        border-right: ${theme.borderWidths[1]} ${theme.borderStyles.solid};
        border-color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.hover.borderColor};
    }

    ${({ withClearIcon }): string => (withClearIcon ? 'border-right: 0;' : '')}
`;

export const Placeholder = styled.div<{ shouldHaveLightColor: boolean }>`
    flex-grow: 1;
    overflow: hidden;
    color: ${({ shouldHaveLightColor }): CSSProperties['color'] =>
        shouldHaveLightColor ? theme.colors['text-global-secondary'] : 'inherit'};
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const DropdownWrapper = styled.div<{ shouldUseNexusTheme?: boolean }>`
    display: flex;
    position: fixed;
    z-index: 800;
    inset: 0;
    flex-direction: column;
    padding: ${({ shouldUseNexusTheme }): CSSProperties['padding'] => getDropdownWrapperPadding(shouldUseNexusTheme)};
    overflow-y: auto;
    background-color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.backgroundColor};

    @media ${theme.mediaQueries.lgMin} {
        position: absolute;
        padding: ${theme.space[0]};
        overflow-y: unset;
    }
`;

export const ListWrapper = styled.div<Props>`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: ${theme.space[0]};
    overflow: auto;
    background-color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.backgroundColor};

    @media ${theme.mediaQueries.lgMin} {
        max-height: 60vh;
        padding: ${theme.space[4]} ${theme.space[0]} ${theme.space[0]};
        border-top: ${({ isDropdownOpened }): string =>
            isDropdownOpened ? ` ${theme.borderWidths[1]} ${theme.borderStyles.solid}` : ''};
        border-color: ${({ isDropdownOpened }): string =>
            isDropdownOpened ? `${SEARCH_WIDGET_THEME.searchForm.locationPicker.borderColor}` : ''};
    }
`;

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    @media ${theme.mediaQueries.lgMin} {
        border: ${theme.borderWidths[1]} ${theme.borderStyles.solid};
        border-radius: 2px;
        border-color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.active.borderColor};
        box-shadow: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.active.boxShadow};
    }
`;

export const MobileApplyFilters = styled.div<{ shouldUseNexusTheme?: boolean }>`
    display: grid;
    position: fixed;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
    grid-column-gap: ${theme.space[16]};
    grid-template-columns: 1fr 1fr;
    padding: ${theme.space[16]};
    background-color: ${theme.colors['background-global-primary']};

    ${({ shouldUseNexusTheme }): SerializedStyles | null =>
        shouldUseNexusTheme ? css(`border-top: 1px solid ${theme.colors['borders-global-tertiary']}`) : null}
`;

const FiltersButton = styled(Button)`
    min-width: 128px;

    @media ${theme.mediaQueries.lgMin} {
        width: 100%;
    }
`;

export const ApplyLocationButton = FiltersButton;
export const ClearFiltersButton = FiltersButton;
