import { Button } from '@domains/shared/components/Button/Button';
import { Container } from '@domains/shared/components/Container/Container';
import {
    getActiveButtonCss,
    getStandardButtonCss,
    getTextShadow,
} from '@domains/shared/helpers/styles/getStylesForListingTopBarButtons';
import type { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LETTER_SPACING, WEIGHT } from '@lib/styles/partials/typography';
import type { CSSProperties } from 'react';

const ASIDE_SECTION_MAX_WIDTH = 300;
const LISTING_MARGIN_RIGHT = 28;

export const BottomSectionWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    padding-bottom: 24px;
    background-color: ${({ theme }): string =>
        theme.deprecated.domains.companies.discoverySection.bottomSection.backgroundColor};
`;

export const MainContentWrapper = styled(Container)`
    position: relative;
    flex-direction: column;
`;

export const MainContentTopBar = styled.div`
    display: flex;
    position: sticky;
    z-index: 2;
    top: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
    background-color: ${({ theme }): string =>
        theme.deprecated.domains.companies.discoverySection.bottomSection.backgroundColor};
    gap: 12px;

    @media (min-width: ${BREAKPOINT.md}) {
        position: relative;
        z-index: initial;
        width: calc(100% - ${LISTING_MARGIN_RIGHT}px - ${ASIDE_SECTION_MAX_WIDTH}px);
        margin: 24px 0;
    }
`;

export const MapButtonWrapper = styled.div`
    margin-bottom: 28px;
    padding-top: 20px;
    border-top: 1px solid ${({ theme }): string => theme.deprecated.domains.search.listing.title.borderColor};
`;

export const FilterAndSortWrapper = styled.div`
    display: flex;
`;

export const AdvertListingAndContactSectionWrapper = styled.div`
    display: flex;

    @media (max-width: ${BREAKPOINT.md}) {
        flex-direction: column;
    }
`;

export const AdvertListingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: ${LISTING_MARGIN_RIGHT}px;

    & > * + * {
        margin-top: 24px;
    }

    @media (max-width: ${BREAKPOINT.md}) {
        margin-right: 0;
    }
`;

export const AdvertsList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const AsideSectionWrapper = styled.aside<{ hasTopMargin: boolean }>`
    display: flex;
    position: relative;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    max-width: ${ASIDE_SECTION_MAX_WIDTH}px;
    margin-top: ${({ hasTopMargin }): string => (hasTopMargin ? '24px' : '0')};

    @media (max-width: ${BREAKPOINT.md}) {
        max-width: none;
        margin-top: 24px;
    }
`;

export const AsideStickyContainer = styled.div`
    position: sticky;
    top: 28px;

    @media (max-width: ${BREAKPOINT.md}) {
        position: static;
    }
`;

export const InternalLinkingBlockWrapper = styled(Container)`
    margin-top: 4px;
`;

export const FiltersButton = styled(Button)<{ isActive?: boolean }>`
    width: fit-content;
    min-width: 32px;
    margin-right: 0;
    padding: 8px 16px;
    transition: 0.2s color ease-in-out;
    border: 1px solid;
    border-right: 0;
    border-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    ${({ theme }): SerializedStyles => getStandardButtonCss(theme)};
    font-weight: ${WEIGHT.regular};
    letter-spacing: ${LETTER_SPACING.x1};
    white-space: nowrap;

    &:hover,
    &:focus {
        border-color: ${({ theme }): CSSProperties['borderColor'] =>
            theme.deprecated.domains.shared.sortingDropdown.buttonGroup.button.base.borderColor};
        background-color: ${({ theme }): CSSProperties['backgroundColor'] =>
            theme.deprecated.domains.shared.sortingDropdown.buttonGroup.button.hover.backgroundColor};
        color: ${({ theme }): CSSProperties['color'] =>
            theme.deprecated.domains.shared.sortingDropdown.buttonGroup.button.hover.color};
    }

    &:focus {
        text-shadow: ${({ theme }): CSSProperties['textShadow'] => getTextShadow({ isActive: true, theme })};
    }

    ${({ isActive, theme }): SerializedStyles | string => (isActive ? getActiveButtonCss(theme) : '')}
`;
