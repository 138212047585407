import { gql } from 'urql';

export const GET_ORGANISATION_MEMBERS = gql`
    query OrganisationMembers($sellerId: ID!) {
        organisationMembers(ownerID: $sellerId) {
            __typename
            ... on OrganisationMembersResponse {
                organisationMembers {
                    userID
                    profileID
                    firstName
                    lastName
                    photoURL
                    mobile
                }
            }

            ... on ErrorNotFound {
                code
                message
            }
        }
    }
`;
