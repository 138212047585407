import { logError } from '@domains/shared/helpers/logger';
import type { ComponentProps, ForwardRefRenderFunction } from 'react';
import { Children, cloneElement, forwardRef, isValidElement, useCallback, useState } from 'react';

import { ButtonGroupWrapper } from './ButtonGroup.theme';

interface Props extends Omit<ComponentProps<'div'>, 'onChange' | 'defaultValue'> {
    value?: string | number;
    defaultValue?: string | number;
    onChange?(value: string | number): void;
    testId?: string;
    WrapperComponent?: typeof ButtonGroupWrapper;
}

type ButtonActiveType = ComponentProps<'button'> & { isActive: boolean };

const checkIsReadOnlyArrayOrUndefined = (value: unknown): value is Readonly<unknown[]> | unknown[] | undefined =>
    !value || Array.isArray(value);

const ButtonGroupElement: ForwardRefRenderFunction<HTMLDivElement, Props> = (
    {
        children,
        value,
        onChange,
        defaultValue = '',
        testId = 'buttongroup',
        WrapperComponent = ButtonGroupWrapper,
        ...rest
    },
    ref,
) => {
    const [selected, setSelected] = useState(defaultValue);

    const handleOnClick = useCallback(
        (value: string | Readonly<unknown[]> | unknown[] | number | undefined) => (): void => {
            if (checkIsReadOnlyArrayOrUndefined(value)) {
                logError('[ButtonGroup] unsupported value', { value });

                return;
            }
            onChange ? onChange(value) : setSelected(value);
        },
        [onChange, setSelected],
    );

    const decoratedChildren = Children.map(children, (child) => {
        if (!isValidElement<ButtonActiveType>(child)) {
            return child;
        }

        return cloneElement(child, {
            role: 'radio',
            onClick: handleOnClick(child.props.value),
            isActive: (value ?? selected) === child.props.value,
            'aria-checked': (value ?? selected) === child.props.value,
        });
    });

    return (
        <WrapperComponent role="radiogroup" ref={ref} data-testid={testId} {...rest}>
            {decoratedChildren?.map((child) => child)}
        </WrapperComponent>
    );
};

const ButtonGroup = forwardRef<HTMLDivElement, Props>(ButtonGroupElement);

export { ButtonGroup };

export { ButtonGroupWrapper } from './ButtonGroup.theme';
