import type { FormData } from '@domains/companies/sellerPage/types/filtersData';
import { URL_LOCATION_FILLER } from '@domains/shared/consts/urlLocationFiller';
import type { SearchLinksRelatedLocations } from '@type/location/locationLink';
import { ESTATE } from '@type/search/filters/estate';
import { TRANSACTION } from '@type/search/filters/transaction';
import { SeoLinksSection, useGetLocationLinks } from '@widgets/search/SeoLinks';
import type { FC } from 'react';

import { Wrapper } from './SeoLinks.theme';

interface Props {
    seoLinks: SearchLinksRelatedLocations;
    formData: FormData;
}

export const SeoLinks: FC<Props> = ({ seoLinks, formData }) => {
    // For now BE does not return any estate for seoLinks for category ALL which throws Incorrect label in URL segment: [estate]: ALL
    // For now we want to return seoLinks with FLAT category for ALL estate; if in the future BE returns this data for ALL estate, this should be deleted
    const searchLinksRelatedLocations = seoLinks.items.map((item) =>
        item.estate === 'ALL' ? { ...item, estate: 'FLAT', name: item.name } : item,
    );

    const { propertiesForSell, propertiesForRent, propertiesFromPrimaryMarket } = useGetLocationLinks({
        location: URL_LOCATION_FILLER.allLocations.label,
        estate: formData.estate?.value ?? ESTATE.flat,
        transaction: formData.transaction?.value ?? TRANSACTION.sell,
    });

    if (searchLinksRelatedLocations.length === 0) {
        return null;
    }

    return (
        <Wrapper data-cy="location-seo-links">
            <SeoLinksSection
                searchLinksRelatedLocations={searchLinksRelatedLocations}
                propertiesForSell={propertiesForSell}
                propertiesForRent={propertiesForRent}
                propertiesFromPrimaryMarket={propertiesFromPrimaryMarket}
            />
        </Wrapper>
    );
};
