import type { FilterAttributes } from '@domains/companies/sellerPage/types/filtersData';
import type { FilterLocations } from '@type/location/filterLocation';
import { ESTATE } from '@type/search/filters/estate';
import { TRANSACTION } from '@type/search/filters/transaction';

const compareArrays = <T>(array1: T[], array2: T[]): boolean => {
    if (array1.length !== array2.length) {
        return false;
    }

    return array1.every((item) => array2.includes(item));
};

export const countFilters = ({
    filterAttributes,
    filterLocations,
    isAgency,
}: {
    filterAttributes: Omit<FilterAttributes, 'sellerId'>;
    filterLocations: FilterLocations<string | string[] | { id: unknown }[]> | null;
    isAgency: boolean;
}): number => {
    const filterLocationCount = filterLocations === null ? 0 : 1;
    const defaultFilterAttributes: typeof filterAttributes = {
        market: 'ALL',
        estate: isAgency ? ESTATE.all : ESTATE.investment,
        transaction: TRANSACTION.sell,
        distanceRadius: 0,
        areaMax: null,
        areaMin: null,
        hasDiscount: null,
        investmentState: null,
        priceMax: null,
        priceMin: null,
        roomsNumber: [],
    };

    const foundFilters = (Object.keys(defaultFilterAttributes) as (keyof typeof filterAttributes)[]).filter((key) => {
        const defaultAttribute = defaultFilterAttributes[key];
        const filterAttribute = filterAttributes[key];

        if (Array.isArray(defaultAttribute) && Array.isArray(filterAttribute)) {
            return !compareArrays(defaultAttribute, filterAttribute);
        }

        return defaultAttribute !== filterAttribute;
    });

    return foundFilters.length + filterLocationCount;
};
