import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { ComponentProps, JSX } from 'react';
import { useContext } from 'react';

import { Container, Count, Label } from './SearchResultCount.theme';

interface Props extends ComponentProps<typeof Container> {
    total: number;
}

export const SearchResultCount = ({ total, ...props }: Props): JSX.Element => {
    const { isDesktop } = useContext(RWDContext);
    const [t] = useTranslations();

    const label = isDesktop
        ? t('frontend.search.listing-panel.label.ads-number')
        : t('frontend.search.listing-panel.label.ads-number-mobile');

    return (
        <Container {...props}>
            <Label>{`${label}: `}</Label>
            <Count>{total}</Count>
        </Container>
    );
};
