import { Skeleton } from '@domains/shared/components/Skeleton/Skeleton';
import type { JSX } from 'react';

export const SubregionsSkeletons = (): JSX.Element => {
    return (
        <div
            style={{
                padding: '5px 25px 20px 25px',
                textAlign: 'right',
            }}
            data-testid="subregions-skeletons"
        >
            <Skeleton width="90%" height="30px" />
            <Skeleton width="90%" height="30px" />
            <Skeleton width="90%" height="30px" />
            <Skeleton width="90%" height="30px" />
            <Skeleton width="90%" height="30px" />
            <Skeleton width="90%" height="30px" />
            <Skeleton width="90%" height="30px" />
            <Skeleton width="90%" height="30px" />
            <Skeleton width="90%" height="30px" />
            <Skeleton width="90%" height="30px" />
        </div>
    );
};
