import type { LocationDataItem } from '@type/search/location/dataItem';
import type { TreeNode } from '@type/search/location/pickerTreeNode';

import type { GqlRootNodeAndLeaf } from '../../../types/locations';

export const parseGqlRootNode = (item: GqlRootNodeAndLeaf): [LocationDataItem['id'], TreeNode] => {
    const { id, name, detailedLevel } = item;

    return [id, { id, name, parent: null, children: [], detailedLevel }];
};

export const parseGqlNodeFactory =
    (parent: TreeNode) =>
    ({ id, name, detailedLevel }: GqlRootNodeAndLeaf): TreeNode => ({
        id,
        name,
        parent,
        detailedLevel,
        children: [],
    });
