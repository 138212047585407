import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Wrapper = styled.div`
    min-width: 0;

    @media (${theme.mediaQueries.lgMin}) {
        flex: 0 0 60%;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${theme.space[8]} ${theme.space[8]} 0;
    gap: ${theme.space[24]};

    @media (${theme.mediaQueries.smMin}) {
        padding: ${theme.space[16]} ${theme.space[16]} 0;
    }

    @media (${theme.mediaQueries.lgMin}) {
        padding: ${theme.space[16]} 0 ${theme.space[16]} ${theme.space[16]};
    }
`;
