import { SITE_CONFIG } from '@config/siteConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Address } from '@type/location/address';
import type { AdvertUserType } from '@type/user/userType';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { JSX } from 'react';

import { getCanonicalUrl } from './helpers/getCanonicalUrl';

interface Props {
    type: AdvertUserType;
    address: Address;
    companyName: string;
}

export const SeoMetadata = ({ type, address, companyName }: Props): JSX.Element => {
    const [t] = useTranslations();
    const router = useRouter();

    const cityName = address.city?.name;
    const isUrlWithQueryParam = router.asPath.includes('?');
    const isDeveloper = type === 'DEVELOPER';

    const ownerForTitle = isDeveloper
        ? t('frontend.companies.head.title-developer')
        : t('frontend.companies.head.title-agency');

    const titleSecondPart = cityName ? `${cityName} - ${companyName}` : `- ${companyName}`;

    const ownerForDescription = isDeveloper
        ? t('frontend.companies.head.description-developer')
        : t('frontend.companies.head.description-agency');

    // TODO: Use translations with params here.
    const title = `${ownerForTitle} ${titleSecondPart} | ${SITE_CONFIG.metadata.siteName}`;
    const description = `${t('frontend.companies.head.description')} ${ownerForDescription} ${companyName} ${t(
        'frontend.companies.head.description-operating-in',
    )} ${cityName}.`;

    const canonicalUrl = getCanonicalUrl(router.asPath, isUrlWithQueryParam);
    const shouldIndex = !isUrlWithQueryParam;

    return (
        <Head>
            <title>{title}</title>
            <meta property="og:title" content={title} key="meta_property_og:title" />
            <meta name="description" content={description} key="meta_name_description" />
            <meta property="og:description" content={description} key="meta_name_og:description" />
            <link rel="canonical" href={canonicalUrl} key="link_canonical" />
            <meta name="robots" content={`${shouldIndex ? 'index' : 'noindex'}, follow`} key="meta_name_robots" />
        </Head>
    );
};
