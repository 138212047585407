import type { AdvertItem } from '@domains/companies/sellerPage/types/sellerPageSearchAds';
import { getDiscountedMoney } from '@domains/shared/helpers/price/getDiscountedPrice';
import { ESTATE } from '@type/search/filters/estate';
import { TRANSACTION } from '@type/search/filters/transaction';
import { Price } from '@widgets/AdvertCard/Price';

export const AdPrice = ({ ad }: { ad: AdvertItem }): JSX.Element => {
    const { totalPrice, hidePrice, estate, specialOffer, priceFromPerSquareMeter, transaction, rentPrice } = ad;

    const isInvestment = estate === ESTATE.investment;
    const isPriceHidden = !!hidePrice;

    if (isInvestment) {
        const discount = specialOffer?.discountValue ?? 0;
        const beforeDiscountPrice = discount ? priceFromPerSquareMeter : null;
        const maybeDiscountedPrice = priceFromPerSquareMeter
            ? getDiscountedMoney(priceFromPerSquareMeter, discount)
            : null;

        return (
            <Price
                total={maybeDiscountedPrice}
                beforeDiscount={beforeDiscountPrice}
                isEstimated
                isHidden={isPriceHidden}
                isPerMeter
            />
        );
    }

    if (transaction === TRANSACTION.rent) {
        return <Price total={totalPrice} rent={rentPrice} isHidden={isPriceHidden} />;
    }

    return <Price total={totalPrice} isHidden={isPriceHidden} />;
};
