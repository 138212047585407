import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import type { JSX } from 'react';

import { Container, StyledIcon } from './CompanyAddress.theme';

interface Props {
    formattedAddress: string;
}

export const CompanyAddress = ({ formattedAddress }: Props): JSX.Element => {
    return (
        <Container>
            <StyledIcon icon={faMapMarkerAlt} aria-hidden="true" width="11" height="14" />
            {formattedAddress}
        </Container>
    );
};
