import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { SELLER_BADGE_ID } from '@type/sellerBadge/sellerBadge';

const { staticAssetsPrefix } = getPublicEnvConfig();

export const SELLER_BADGES_LIST_ITEM = {
    [SELLER_BADGE_ID.leader]: {
        icon: `${staticAssetsPrefix}/images/sellerBadges/leader.svg`,
        title: 'frontend.global.seller-badge.leader',
    },
    [SELLER_BADGE_ID.leaderPro]: {
        icon: `${staticAssetsPrefix}/images/sellerBadges/qualityLeader.svg`,
        title: 'frontend.global.seller-badge.leader-pro',
    },
    [SELLER_BADGE_ID.otodomAnalytics]: {
        icon: `${staticAssetsPrefix}/images/sellerBadges/otodomAnalytics.svg`,
        title: 'frontend.companies.seller-badge.otodom-analytics',
    },
    [SELLER_BADGE_ID.pzfdMember]: {
        icon: `${staticAssetsPrefix}/images/sellerBadges/pzfdMember.png`,
        title: 'frontend.companies.seller-badge.pzfd-member',
    },
} as const;
