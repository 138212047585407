import { SITE_CONFIG } from '@config/siteConfig';
import type { FormData } from '@domains/companies/sellerPage/nexusComponents/ContactForm/types';
import { FormInput } from '@domains/shared/components/nexus/FormInput/FormInput';
import { VALID_COUNTRY_CODE_REGEX, VALID_PHONE_NUMBER_REGEX } from '@domains/shared/components/PhoneInput/helpers';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';
import { useFormContext } from 'react-hook-form';

import { ErrorMessage, InputsWraper, StyledCountryCodeInput, StyledPhoneNumberInput } from './PhoneInput.theme';

interface Props {
    phoneInputOnClick?: () => void;
}

export const PhoneInput = ({ phoneInputOnClick }: Props): JSX.Element => {
    const [t] = useTranslations();
    const {
        formState: { errors },
    } = useFormContext<FormData>();

    return (
        <>
            <InputsWraper>
                <FormInput
                    id="countryCode"
                    input={StyledCountryCodeInput}
                    ariaLabel={t('frontend.companies-seller-page.contact-form.country-code')}
                    registerOptions={{
                        required: t('frontend.shared.phone-input.error-country-code'),
                        pattern: {
                            value: VALID_COUNTRY_CODE_REGEX,
                            message: t('frontend.shared.phone-input.error-country-code'),
                        },
                    }}
                    shouldHandleError={false}
                    defaultValue={`+${SITE_CONFIG.phoneNumbers.countryCode}`}
                />
                <FormInput
                    id="phone"
                    input={StyledPhoneNumberInput}
                    ariaLabel={t('frontend.companies-seller-page.contact-form.phone')}
                    placeholder={`${t('frontend.companies-seller-page.contact-form.phone')}*`}
                    registerOptions={{
                        required: t('frontend.shared.phone-input.error-required'),
                        pattern: {
                            value: VALID_PHONE_NUMBER_REGEX,
                            message: t('frontend.shared.phone-input.error-format'),
                        },
                    }}
                    shouldUseControllerComponent
                    shouldHandleError={false}
                    validationStatus={errors.phone ? 'hasError' : undefined}
                    onClick={phoneInputOnClick}
                />
            </InputsWraper>
            {errors?.countryCode?.message ? <ErrorMessage>{errors.countryCode.message}</ErrorMessage> : null}
            {errors?.phone?.message ? <ErrorMessage>{errors.phone.message}</ErrorMessage> : null}
        </>
    );
};
