import type { TreeNode } from '@type/search/location/pickerTreeNode';
import type { FC } from 'react';
import { Fragment } from 'react';

import type { UseLocationPickerService } from '../../hooks/useLocationPickerService/types';
import { LocationListItem } from '../LocationListItem/LocationListItem';
import { SubregionsSkeletons } from './components/SubregionsSkeletons/SubregionsSkeletons';
import { Subregions } from './LocationListSection.theme';

interface Props
    extends Pick<
        UseLocationPickerService,
        'checkIsAnyChildSelected' | 'checkIsNodeSelected' | 'checkIsExpanded' | 'toggleExpanded' | 'toggleSelectNode'
    > {
    checkboxPrefix?: string;
    isExpanded: boolean;
    item: TreeNode;
    shouldUseNexusTheme?: boolean;
}

export const LocationListSection: FC<Props> = ({
    checkIsAnyChildSelected,
    checkIsNodeSelected,
    checkIsExpanded,
    checkboxPrefix,
    item,
    isExpanded,
    shouldUseNexusTheme,
    toggleExpanded,
    toggleSelectNode,
}) => {
    const { id, children } = item;
    const isChecked = checkIsNodeSelected(item);
    const isPartiallyChecked = !isChecked && checkIsAnyChildSelected(item);

    const shouldDisplaySubregions = isExpanded && children.length > 0;
    const shouldDisplaySkeletons = isExpanded && children.length === 0;

    return (
        <Fragment key={id}>
            <LocationListItem
                checkboxPostfix={checkboxPrefix}
                isChecked={isChecked}
                isExpanded={isExpanded}
                isPartiallyChecked={isPartiallyChecked}
                item={item}
                toggleExpanded={toggleExpanded}
                toggleSelectNode={toggleSelectNode}
                shouldUseNexusTheme={shouldUseNexusTheme}
            />
            {shouldDisplaySubregions ? (
                <Subregions>
                    {children.map((child) => (
                        <LocationListSection
                            checkIsAnyChildSelected={checkIsAnyChildSelected}
                            checkIsExpanded={checkIsExpanded}
                            checkIsNodeSelected={checkIsNodeSelected}
                            isExpanded={checkIsExpanded(child)}
                            item={child}
                            key={child.id}
                            toggleExpanded={toggleExpanded}
                            toggleSelectNode={toggleSelectNode}
                            shouldUseNexusTheme={shouldUseNexusTheme}
                        />
                    ))}
                </Subregions>
            ) : null}
            {shouldDisplaySkeletons ? <SubregionsSkeletons /> : null}
        </Fragment>
    );
};
