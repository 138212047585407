import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { checkIfInternalReferrer } from '@lib/pages/checkIfInternalReferrer';
import { useRouter } from 'next/router';
import type { ComponentProps, JSX, MouseEventHandler } from 'react';

import { StyledButton } from './GoBackButton.theme';

interface Props extends ComponentProps<typeof StyledButton> {
    referer: string | null;
    textTranslationKey?: string;
}

export const GoBackButton = ({
    referer,
    textTranslationKey = 'frontend.global.navigation.go-back',
    onClick,
    ...props
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const router = useRouter();

    const isInternalReferer = referer && checkIfInternalReferrer(referer);

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        onClick?.(event);

        if (isInternalReferer) {
            router.back();
        } else {
            router.push('/');
        }
    };

    return (
        <StyledButton variant={ButtonVariant.Ghost} onClick={handleClick} {...props}>
            <Icon icon={faArrowLeft} />
            {t(textTranslationKey)}
        </StyledButton>
    );
};
