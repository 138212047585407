import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Wrapper = styled.div`
    width: 100%;
    height: 210px;
    background-color: ${theme.colors.$02_gray};

    @media (${theme.mediaQueries.lgMin}) {
        flex: 0 0 40%;
        height: auto;
    }
`;

export const TagsWrapper = styled.div`
    display: flex;
    position: absolute;
    z-index: 1;
    flex-wrap: wrap;
    padding: ${theme.space[16]};
    pointer-events: none;
    gap: ${theme.space[8]};
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
`;
