import type { FormData } from '@domains/companies/sellerPage/types/filtersData';
import { NumberInput } from '@domains/shared/components/NumberInput/NumberInput';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';
import { useFormContext } from 'react-hook-form';

import { FieldLabel } from './FieldLabel';
import { Divider, InputGroup, InputWrapper } from './MinMaxInput.theme';

type MinMaxInputName = keyof Pick<FormData, 'priceMin' | 'priceMax' | 'areaMin' | 'areaMax'>;
interface Props {
    label: string;
    name: 'price' | 'area';
    appendix: string;
    onBlur?: (fromValue: number | null, toValue: number | null) => void;
}

const TRANSLATIONS = {
    min: {
        price: 'frontend.companies.search-form.price-min',
        area: 'frontend.companies.search-form.area-min',
    },
    max: {
        price: 'frontend.companies.search-form.price-max',
        area: 'frontend.companies.search-form.area-max',
    },
} as const;

export const MinMaxInput = ({ label, name, onBlur, appendix }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { getValues, register, setValue, clearErrors, control } = useFormContext<FormData>();
    const getDefaultValue = (type: 'Min' | 'Max'): string | undefined => {
        // Note: NumberInput component requires string as value, not number.
        return control.defaultValuesRef.current[`${name}${type}`]?.toString();
    };

    const minInputId = `${name}-min-field`;
    const maxInputId = `${name}-max-field`;
    const minInputName: MinMaxInputName = `${name}Min`;
    const maxInputName: MinMaxInputName = `${name}Max`;

    return (
        <div>
            <FieldLabel htmlFor={minInputId} aria-hidden>
                {label}
            </FieldLabel>
            <InputGroup>
                <InputWrapper>
                    <FieldLabel htmlFor={minInputId} isVisuallyHidden>
                        {label}, min
                    </FieldLabel>
                    <NumberInput
                        inputId={minInputId}
                        name={minInputName}
                        register={register}
                        setFormValue={setValue}
                        clearFormErrors={clearErrors}
                        inputAppend={appendix}
                        defaultValue={getDefaultValue('Min')}
                        onBlur={(): void => onBlur?.(getValues(minInputName), getValues(maxInputName))}
                        placeholder={t(TRANSLATIONS.min[name])}
                    />
                </InputWrapper>
                <Divider />
                <InputWrapper>
                    <FieldLabel htmlFor={maxInputId} isVisuallyHidden>
                        {label}, max
                    </FieldLabel>
                    <NumberInput
                        inputId={maxInputId}
                        name={maxInputName}
                        register={register}
                        setFormValue={setValue}
                        clearFormErrors={clearErrors}
                        inputAppend={appendix}
                        defaultValue={getDefaultValue('Max')}
                        onBlur={(): void => onBlur?.(getValues(minInputName), getValues(maxInputName))}
                        placeholder={t(TRANSLATIONS.max[name])}
                    />
                </InputWrapper>
            </InputGroup>
        </div>
    );
};
