import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';

export const Container = styled.div`
    grid-column: span 2;

    @media (min-width: ${BREAKPOINT.md}) {
        grid-column: initial;
    }
`;
