import { CompanyImage } from '@domains/companies/sellerPage/components/CompanyImage/CompanyImage';
import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { ComponentProps, JSX } from 'react';

import { Container, StyledContactButton } from './BusinessCard.theme';
import { PhoneNumersList } from './components/PhoneNumersList';

interface Props extends ComponentProps<typeof Container> {
    imgSrc?: string | null;
    companyName: string;
    phoneNumbers: string[];
    onContactButtonClick: () => void;
    onPhoneNumberReveal?: (number: string) => void;
}

export const BusinessCard = ({
    imgSrc,
    companyName,
    phoneNumbers,
    onContactButtonClick,
    onPhoneNumberReveal,
    ...containerProps
}: Props): JSX.Element => {
    const [t] = useTranslations();

    return (
        <Container {...containerProps}>
            <CompanyImage src={imgSrc} companyName={companyName} width="252" height="136" />
            <PhoneNumersList numbers={phoneNumbers} onReveal={onPhoneNumberReveal} />
            <StyledContactButton variant={ButtonVariant.Ghost} onClick={onContactButtonClick}>
                {t('frontend.global.ad.contact-owner')}
            </StyledContactButton>
        </Container>
    );
};
