import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const BackgroundGradient = styled.div<{ isAgency: boolean }>`
    background: ${({ isAgency }): CSSProperties['background'] =>
        isAgency
            ? `linear-gradient(to bottom, ${theme.colors['$04_blue_tint_dark']} 250px, transparent 250px)`
            : `linear-gradient(to bottom, ${theme.colors['$05_blue_primary']} 250px, transparent 250px)`};
`;

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding-top: 72px;

    @media ${theme.mediaQueries.lgMin} {
        padding: 66px ${theme.space[24]} 0 ${theme.space[24]};
    }

    @media ${theme.mediaQueries.xlMin} {
        max-width: 1280px;
    }
`;

const CONTENT_WRAPPER_GAP = theme.space[16];

export const ContentWrapper = styled.div<{ shouldRenderAsOneColumn: boolean }>`
    display: flex;
    flex-direction: column;
    margin-top: ${theme.space[16]};
    column-gap: ${CONTENT_WRAPPER_GAP};

    @media ${theme.mediaQueries.lgMin} {
        flex-direction: ${({ shouldRenderAsOneColumn }): CSSProperties['flexDirection'] =>
            shouldRenderAsOneColumn ? 'column' : 'row'};
    }
`;

export const PrimarySection = styled.section<{ isFullWidth: boolean }>`
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    gap: ${theme.space[16]};

    @media ${theme.mediaQueries.lgMin} {
        flex: 0 0 ${({ isFullWidth }): CSSProperties['flexBasis'] => (isFullWidth ? '100%' : '70%')};
        max-width: ${({ isFullWidth }): CSSProperties['maxWidth'] => (isFullWidth ? '100%' : '70%')};
    }

    margin-bottom: ${theme.space[16]};
`;

export const SecondarySection = styled.aside`
    flex: 1 1 100%;
    width: 100%;
    min-width: 0;
    height: fit-content;
    border-radius: ${theme.radii['4']};
    background-color: ${theme.colors['background-global-primary']};

    @media ${theme.mediaQueries.lgMin} {
        flex: 0 0 calc(30% - ${CONTENT_WRAPPER_GAP});
        width: 100%;
    }
`;
