import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { getDescriptionParameter } from '@domains/shared/helpers/getDescriptionParameter';
import { getEstateType } from '@domains/shared/helpers/getEstateType';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { LocationLink } from '@type/location/locationLink';
import type { Market } from '@type/search/filters/market';
import { SEO_ESTATE } from '@type/search/filters/seoEstate';
import { COMMERCIAL_PROPERTY_USE_TYPE } from '@type/search/filters/useTypes';
import { buildSearchUrl } from '@widgets/search/buildSearchUrl';
import type { JSX } from 'react';

import { InnerWrapper, Link, OneColumnListContainer, SectionHeadline } from './LocationSeoLinks.theme';

interface Props {
    headline?: string;
    items: LocationLink[];
    market?: Market;
}

export const CustomLocationSeoLinks = ({ headline, items, market }: Props): JSX.Element => {
    const { lang } = useSiteSettings();
    const { trackEvent } = useTracking();
    const [t] = useTranslations();

    return (
        <InnerWrapper>
            {headline ? <SectionHeadline>{headline}</SectionHeadline> : null}

            <OneColumnListContainer>
                {items.map(({ name, estate, transaction, location, roomsNumber }) => {
                    return (
                        <li key={`${estate}-${transaction}-${name}`}>
                            <LocalLink
                                passHref
                                href={buildSearchUrl({
                                    lang,
                                    transaction,
                                    estate: getEstateType(estate),
                                    location,
                                    market,
                                    roomsNumber,
                                    useTypes:
                                        estate === SEO_ESTATE.office
                                            ? [COMMERCIAL_PROPERTY_USE_TYPE.office]
                                            : undefined,
                                    queryParameters: { description: getDescriptionParameter(estate, t) },
                                })}
                            >
                                <Link onClick={(): void => trackEvent('seo_link_click')}>{name}</Link>
                            </LocalLink>
                        </li>
                    );
                })}
            </OneColumnListContainer>
        </InnerWrapper>
    );
};
