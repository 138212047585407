import {
    AGENCY_FACTOIDS_CONFIG,
    DEVELOPER_FACTOIDS_CONFIG,
    LABEL,
} from '@domains/companies/sellerPage/components/FactoidsSection/constants';
import { Factoid } from '@domains/companies/sellerPage/nexusComponents/Factoids/Factoid';
import { FactoidsWrapper } from '@domains/companies/sellerPage/nexusComponents/Factoids/Factoid.theme';
import type { OwnerStatistics } from '@domains/companies/sellerPage/types/sellerPageOwner';
import { getTranslationVariant } from '@domains/shared/helpers/getTranslationVariant';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { OWNER_TYPE } from '@type/ad/ownerType';
import type { AdvertUserType } from '@type/user/userType';
import type { FC } from 'react';

interface Props {
    ownerType: AdvertUserType;
    ownerStatistics: OwnerStatistics;
}

export const Factoids: FC<Props> = ({ ownerType, ownerStatistics }) => {
    const [t] = useTranslations();
    const isAgency = ownerType === OWNER_TYPE.agency;
    const factoids = isAgency ? AGENCY_FACTOIDS_CONFIG : DEVELOPER_FACTOIDS_CONFIG;

    return (
        <FactoidsWrapper data-testid="factoids">
            {factoids
                .filter((id) => !!ownerStatistics[id]) // filter out when value is zero
                .map((id) => {
                    const value = ownerStatistics[id];

                    const label = t(getTranslationVariant(value, LABEL[id]));

                    return <Factoid key={id} label={label} value={value} />;
                })}
        </FactoidsWrapper>
    );
};
