import { NexusListingSorting } from '@domains/shared/components/ListingSorting/NexusListingSorting';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const SortingDropdownWrapper = styled.div`
    display: flex;
    gap: ${theme.space[8]};

    p {
        color: ${theme.colors['text-global-secondary']};
        white-space: nowrap;
    }
`;

export const SortingDropdown = styled(NexusListingSorting)`
    width: 100%;

    div.react-select__control {
        align-items: flex-start;
        height: auto;
        min-height: unset;
        padding: 0;
        border: none;
        box-shadow: none;
        font-weight: ${theme.fontWeights.bold};

        &--menu-is-open,
        &--is-focused {
            border-bottom: none;
            border-radius: 0;
        }

        .react-select__indicators {
            transform: rotate(0deg) scale(0.7);
        }

        &--menu-is-open {
            .react-select__indicators {
                transform: rotate(180deg) scale(0.7);
            }
        }
    }

    div.react-select__menu {
        left: 100%;
        width: 287px;
        transform: translateX(-100%);
        background-color: ${theme.colors['background-global-secondary']};
        box-shadow: 0 1px 4px rgb(0 0 0 / 16%);

        ::after {
            box-shadow: none;
        }
    }
`;
