import { buildOptionsFromValues } from '@domains/shared/helpers/buildOptionsFromValues';
import { GET_NON_GENERIC_TRANSLATION_KEY } from '@domains/shared/helpers/getNonGenericTranslationKey';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { dispatchPlatformEvent } from '@lib/events/dispatchPlatformEvent';
import { listenForPlatformEvent } from '@lib/events/listenForPlatformEvent';
import { unmountPlatformEvent } from '@lib/events/unmountPlatformEvent';
import { useTracking } from '@lib/tracking/useTracking';
import type { DropdownOption } from '@type/search/dropdownOption';
import type { DistanceRadiusType } from '@type/search/filters/distanceRadius';
import { SEARCH_FORM_UNIVERSAL_FIELD } from '@type/search/searchFormUniversalField';
import type { Dispatch, JSX, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { DropdownSelect } from '../../../core/components/DropdownSelect';

const FIELD_NAME = SEARCH_FORM_UNIVERSAL_FIELD.distanceRadius;
const DISTANCE_RADIUS_CHANGED_EVENT = 'DISTANCE_RADIUS_CHANGED';

interface Props {
    options: Record<DistanceRadiusType, number[]>;
    defaultDistanceRadius?: Record<DistanceRadiusType, number>;
    dataCy: string;
    selectedDistanceType?: DistanceRadiusType;
    shouldUseNexusTheme?: boolean;
}

export const DistanceRadius = ({
    options,
    dataCy,
    selectedDistanceType = 'km',
    shouldUseNexusTheme,
    defaultDistanceRadius,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const dropdownOptions = useMemo(
        () =>
            buildOptionsFromValues({
                name: selectedDistanceType === 'km' ? FIELD_NAME : `${FIELD_NAME}meters`,
                options: options[selectedDistanceType].map(String),
                t,
            }),
        [options, selectedDistanceType, t],
    );

    const label = t(GET_NON_GENERIC_TRANSLATION_KEY.label(FIELD_NAME));

    const trackOnDistanceRadiusExpand = useCallback(() => {
        trackEvent('filter_distance_click', { touch_point_button: 'filters' });
    }, [trackEvent]);

    const trackOnDistanceRadiusSelect = useCallback(
        (option: DropdownOption | null): void => {
            trackEvent('filter_distance_selected', {
                touch_point_button: 'filters',
                // 'distance_filt' is on purpose. That's how data team stores this parameter in their db
                distance_filt: option?.value,
            });
        },
        [trackEvent],
    );

    const { setValue } = useFormContext();

    useEffect(() => {
        dispatchPlatformEvent(DISTANCE_RADIUS_CHANGED_EVENT, {});
    }, [dropdownOptions]);

    const getEventListeners = useMemo(
        () =>
            (
                setDropdownOptions: Dispatch<SetStateAction<readonly DropdownOption<string>[]>>,
            ): {
                runEventListener: () => void;
                unbindEventListener: () => void;
            } => {
                const eventCallback = (): void => {
                    const newDropdownOptions = dropdownOptions as DropdownOption[];
                    setDropdownOptions(newDropdownOptions);
                    setValue(FIELD_NAME, null);
                };

                const runEventListener = (): void => {
                    listenForPlatformEvent(DISTANCE_RADIUS_CHANGED_EVENT, eventCallback);
                };

                const unbindEventListener = (): void => {
                    unmountPlatformEvent(DISTANCE_RADIUS_CHANGED_EVENT, eventCallback);
                };

                return {
                    runEventListener,
                    unbindEventListener,
                };
            },
        [dropdownOptions, setValue],
    );

    const placeholder =
        dropdownOptions.find(({ value }) => value === defaultDistanceRadius?.[selectedDistanceType].toString())
            ?.label ?? dropdownOptions[0].label;

    return (
        <DropdownSelect
            name={FIELD_NAME}
            inputId={FIELD_NAME}
            instanceId={FIELD_NAME}
            trackOnMenuOpen={trackOnDistanceRadiusExpand}
            trackOnSelect={trackOnDistanceRadiusSelect}
            dataCy={dataCy}
            options={dropdownOptions}
            onValueChange={(): null => null}
            ariaLabel={label}
            placeholder={placeholder}
            getEventListeners={getEventListeners}
            shouldUseNexusTheme={shouldUseNexusTheme}
        />
    );
};
