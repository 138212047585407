import { ITEMS_PER_PAGE_VALUES } from '@domains/companies/sellerPage/config/pagination';
import { Dropdown } from '@domains/shared/components/Dropdown/Dropdown';
import { PaginationControls } from '@domains/shared/components/PaginationControls/PaginationControls';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useRouter } from 'next/router';
import type { ComponentProps, JSX } from 'react';
import { useContext } from 'react';
import type { SingleValue } from 'react-select';

import { convertToDropdownOptions } from './helpers/convertToDropdownOptions';
import { getDropdownOptionFromValue } from './helpers/getDropdownOptionFromValue';
import { Container, PageLimitInputLabel, PageLimitInputWrapper } from './Pagination.theme';
import type { PageLimitOption } from './types/pageLimit';

interface Props extends ComponentProps<typeof Container> {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
}

export const Pagination = ({ currentPage, itemsPerPage, totalItems, ...props }: Props): JSX.Element => {
    const { isDesktop } = useContext(RWDContext);
    const [t] = useTranslations();
    const router = useRouter();

    const handleCurrentPageChange = async (page: number): Promise<void> => {
        await router.push({
            href: router.route,
            query: { ...router.query, currentPage: page },
        });
    };

    const handlePageLimitChange = async (limit: SingleValue<PageLimitOption>): Promise<void> => {
        await router.push({
            href: router.route,
            query: { ...router.query, ...(limit && { pageLimit: limit.value }) },
        });
    };

    return (
        <Container {...props}>
            <PaginationControls
                activePage={currentPage}
                perPage={itemsPerPage}
                entriesCount={totalItems}
                onPageChange={handleCurrentPageChange}
                dataCy="adverts-pagination"
            />
            {isDesktop ? (
                <PageLimitInputWrapper data-cy="adverts-page-limit">
                    <PageLimitInputLabel htmlFor="page-limit-dropdown">
                        {t('frontend.companies.pagination.items-per-page')}
                    </PageLimitInputLabel>
                    <Dropdown
                        inputId="page-limit-dropdown"
                        value={getDropdownOptionFromValue(itemsPerPage)}
                        options={convertToDropdownOptions(ITEMS_PER_PAGE_VALUES)}
                        shouldTranslateLabels={false}
                        onChange={handlePageLimitChange}
                    />
                </PageLimitInputWrapper>
            ) : null}
        </Container>
    );
};
