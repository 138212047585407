import get from 'get-value';
import type { DeepMap, FieldError, FieldValues, Path } from 'react-hook-form';

export const parseError = <T extends FieldValues>(id: Path<T>, errors: DeepMap<T, FieldError>): string | undefined => {
    const errorById = get(errors, id);

    /**
     * As useFormContext is of generic type here, TS complained that errors might be of FieldError[] type e.g. passed from fields from useFieldsArray so we check that error.message is of type string here;
     * if You plan to use this as a nested field/fields array this should be enhanced
     */
    return !Array.isArray(errorById) && typeof errorById?.message === 'string' ? errorById?.message : undefined;
};
