import { Section } from '@domains/companies/sellerPage/nexusComponents/Section/Section';
import { ContactPerson } from '@domains/companies/sellerPage/nexusComponents/Team/ContactPerson';
import type { ContactPerson as ContactPersonType } from '@domains/companies/sellerPage/types/contactPerson';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { Container } from '@nexus/lib-react/dist/core/Layout';
import { type JSX, useRef } from 'react';

import { StyledSlider } from './Team.theme';

interface Props {
    contacts: ContactPersonType[];
    isAgency: boolean;
}

export const Team = ({ contacts, isAgency }: Props): JSX.Element | null => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const teamSectionRef = useRef<HTMLDivElement | null>(null);

    useTrackImpression(teamSectionRef, () => {
        trackEvent('seller_team', { touch_point_button: 'team' });
    });

    if (contacts.length === 0) {
        return null;
    }

    return (
        <Section innerRef={teamSectionRef} title={t('frontend.companies-seller-page.team.title')}>
            <Container containerWidth="fluid">
                <StyledSlider
                    items={contacts}
                    renderItem={(contact): JSX.Element => <ContactPerson isAgency={isAgency} contact={contact} />}
                    buttonTopPosition={-64}
                    shouldAllowUnequalItems
                    trackingTouchPointButton="team"
                    arrowsVisibility="desktop"
                />
            </Container>
        </Section>
    );
};

export default Team;
