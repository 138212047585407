import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { ViewTypeContext } from '@domains/shared/contexts/ViewTypeContext';
import { useContext } from 'react';

export const useShowMap = (): { isMapFullScreen: boolean; shouldShowMap: boolean } => {
    const { isDesktop } = useContext(RWDContext);
    const { viewType } = useContext(ViewTypeContext);

    const shouldShowMap = viewType === 'map';
    const isMapFullScreen = viewType === 'map' && !isDesktop;

    return { isMapFullScreen, shouldShowMap };
};
