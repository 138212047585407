import { BasicCompanyInfo } from '@domains/companies/sellerPage/components/BasicCompanyInfo/BasicCompanyInfo';
import { CompanyImage } from '@domains/companies/sellerPage/components/CompanyImage/CompanyImage';
import { useTracking } from '@lib/tracking/useTracking';
import type { Address } from '@type/location/address';
import type { SellerBadge } from '@type/sellerBadge/sellerBadge';
import type { JSX } from 'react';

import { BasicCompanyInfoWrapper, Container, SmallCompanyImageWrapper, StyledBusinessCard } from './IntroSection.theme';

interface Props {
    logo: string | null;
    name: string;
    description: string | null;
    address: Address;
    phones: string[];
    onContactButtonClick: () => void;
    sellerBadges?: SellerBadge[];
}

export const IntroSection = ({
    logo,
    name,
    description,
    address,
    phones,
    onContactButtonClick,
    sellerBadges,
}: Props): JSX.Element => {
    const { trackEvent } = useTracking();

    const handleContactButtonClick = (): void => {
        onContactButtonClick();
        trackEvent('reply_message_form_click', {
            touch_point_button: 'contact_us_header',
        });
    };

    const handlePhoneNumberReveal = (): void => {
        trackEvent('reply_phone_show', {
            touch_point_button: 'contact_us_header',
        });
    };

    return (
        <Container>
            <SmallCompanyImageWrapper>
                <CompanyImage src={logo} companyName={name} width="80" height="56" data-cy="seller-small-logo" />
            </SmallCompanyImageWrapper>

            <BasicCompanyInfoWrapper>
                <BasicCompanyInfo
                    name={name}
                    description={description}
                    address={address}
                    sellerBadges={sellerBadges}
                    data-cy="seller-basic-info"
                />
            </BasicCompanyInfoWrapper>

            <StyledBusinessCard
                imgSrc={logo}
                companyName={name}
                phoneNumbers={phones}
                onContactButtonClick={handleContactButtonClick}
                onPhoneNumberReveal={handlePhoneNumberReveal}
                data-cy="seller-business-card"
            />
        </Container>
    );
};
