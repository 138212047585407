import { PhoneNumberLink, Wrapper } from '@domains/companies/sellerPage/nexusComponents/Team/PhoneReveal.theme';
import { usePhoneNumber } from '@domains/shared/hooks/usePhoneNumber/usePhoneNumber';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { Button } from '@nexus/lib-react/dist/core/Button';
import Call from '@nexus/lib-react/dist/core/Icon/icons/default/Call';

export const PhoneReveal = ({ phone }: { phone: string }): JSX.Element | null => {
    const [t] = useTranslations();

    const { trackEvent } = useTracking();

    const trackNumberRevealClick = (): void => {
        trackEvent('reply_phone_show', { touch_point_button: 'team' });
    };

    const { phoneNumber, revealNumber, shouldRevealNumber } = usePhoneNumber({
        number: phone,
        onRevealCallback: trackNumberRevealClick,
    });

    return (
        <Wrapper>
            <Call size="icon-small" />
            {shouldRevealNumber ? (
                <PhoneNumberLink href={`tel:${phoneNumber}`}>{phoneNumber}</PhoneNumberLink>
            ) : (
                <Button variant="tertiary" size="small" onClick={revealNumber}>
                    {t('frontend.shared.phone-number.reveal-phone-number-button')}
                </Button>
            )}
        </Wrapper>
    );
};
