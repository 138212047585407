import { ESTATE_OPTIONS } from '@domains/companies/sellerPage/config/filters/estate';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useCallback } from 'react';

import { ControlledDropdown } from '../components/ControlledDropdown';
import { Container } from './EstateField.theme';

export const EstateField = (): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const handleTrackOnMenuOpen = useCallback(() => {
        trackEvent('category_click');
    }, [trackEvent]);

    const handleTrackOnSelect = useCallback((): void => {
        trackEvent('category_selected');
    }, [trackEvent]);

    return (
        <Container>
            <ControlledDropdown
                label={t('frontend.companies.search-form.estate-label')}
                name="estate"
                options={ESTATE_OPTIONS}
                trackOnMenuOpen={handleTrackOnMenuOpen}
                trackOnSelect={handleTrackOnSelect}
            />
        </Container>
    );
};
