import { UnderlinedButton } from '@domains/shared/components/nexus/UnderlinedButton/UnderlinedButton';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { usePhoneNumber } from '@domains/shared/hooks/usePhoneNumber/usePhoneNumber';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import PhoneIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Telephone';
import type { JSX } from 'react';
import { useContext } from 'react';

import { Phone, Wrapper } from './PhoneNumberReveal.theme';

interface Props {
    phone: string;
    onReveal?: () => void;
    shouldRenderIcon?: boolean;
    shouldButtonHavePadding?: boolean;
}

export const PhoneNumberReveal = ({
    phone,
    onReveal,
    shouldRenderIcon = true,
    shouldButtonHavePadding = true,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { isDesktop } = useContext(RWDContext);
    const iconSize = isDesktop ? 'icon-medium' : 'icon-small';

    const { phoneNumber, firstThreeDigits, revealNumber, shouldRevealNumber } = usePhoneNumber({
        number: phone,
        onRevealCallback: onReveal,
    });

    return (
        <Wrapper>
            {shouldRenderIcon && <PhoneIcon size={iconSize} />}
            {shouldRevealNumber ? (
                <Phone href={`tel:${phoneNumber}`}>{phoneNumber}</Phone>
            ) : (
                <>
                    <Phone as="span">{firstThreeDigits}</Phone>
                    <UnderlinedButton onClick={revealNumber} shouldButtonHavePadding={shouldButtonHavePadding}>
                        {t('frontend.shared.phone-number.reveal-phone-number-button')}
                    </UnderlinedButton>
                </>
            )}
        </Wrapper>
    );
};
