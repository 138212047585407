import { Section } from '@domains/companies/sellerPage/nexusComponents/Section/Section';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RowWithMargin = styled.div`
    margin-bottom: ${theme.space[16]};

    @media ${theme.mediaQueries.mdMin} {
        margin-bottom: ${theme.space[24]};
    }
`;

export const StyledSection = styled(Section)`
    padding-bottom: 24px;
`;
