import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Wrapper = styled.div`
    position: sticky;
    z-index: 2;
    top: 0;
    padding-block: ${theme.space[16]};
    background-color: ${theme.colors['background-global-primary']};
`;
