import { Button } from '@domains/shared/components/Button/Button';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    padding-bottom: ${theme.space[24]};

    & > * + * {
        margin-top: ${theme.space[24]};
    }
`;

export const StyledContactButton = styled(Button)`
    width: 100%;
`;
