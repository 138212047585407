import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Wrapper = styled.div`
    max-width: 100%;

    @media ${theme.mediaQueries.xsMin} {
        max-width: 328px;
    }

    @media ${theme.mediaQueries.smMin} {
        max-width: 544px;
    }

    @media ${theme.mediaQueries.mdMin} {
        max-width: 560px;
    }
`;
