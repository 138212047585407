import type { JSX } from 'react';

import type { UseLocationPickerService } from '../../hooks/useLocationPickerService/types';
import type { SuggestionsType } from '../../types/suggestions';
import { SuggestionsListItem } from '../SuggestionsListItem/SuggestionsListItem';
import { Container } from './LocationSuggestions.theme';

interface Props extends Pick<UseLocationPickerService, 'checkIsIdSelected' | 'toggleSelectSuggestion'> {
    suggestions: SuggestionsType;
    suggestionSearchQuery: string;
    minLength: number;
    shouldUseNexusTheme?: boolean;
}

export const LocationSuggestions = ({
    checkIsIdSelected,
    minLength,
    suggestions,
    suggestionSearchQuery,
    toggleSelectSuggestion,
    shouldUseNexusTheme,
}: Props): JSX.Element => (
    <Container>
        {suggestions.map((suggestion) => {
            return (
                <SuggestionsListItem
                    isSelected={checkIsIdSelected(suggestion)}
                    item={suggestion}
                    key={suggestion.id}
                    minLength={minLength}
                    suggestionSearchQuery={suggestionSearchQuery}
                    toggleSelectSuggestion={toggleSelectSuggestion}
                    shouldUseNexusTheme={shouldUseNexusTheme}
                />
            );
        })}
    </Container>
);
