import { AdvertCard } from '@domains/companies/sellerPage/nexusComponents/SellerAds/components/AdvertCard/AdvertCard';
import { NoResultsInfo } from '@domains/companies/sellerPage/nexusComponents/SellerAds/components/NoResultsInfo/NoResultsInfo';
import { SearchResultCount } from '@domains/companies/sellerPage/nexusComponents/SellerAds/components/SearchResultCount/SearchResultCount';
import { Sorting } from '@domains/companies/sellerPage/nexusComponents/SellerAds/components/Sorting/Sorting';
import type { AdvertItem } from '@domains/companies/sellerPage/types/sellerPageSearchAds';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { Divider } from '@nexus/lib-react/dist/core/Divider';
import { Pagination } from '@nexus/lib-react/dist/core/Pagination';
import type { SellerPagePagination } from '@type/pagination/sellerPagePagination';
import type { SortingOption } from '@type/sorting/option';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import { Header, ItemWrapper, Wrapper } from './AdsList.theme';

interface Props {
    items: AdvertItem[];
    pagination: SellerPagePagination;
    hasActiveAds: boolean;
    isAgency: boolean;
    sortingOption: SortingOption;
}

export const AdsList = ({ items, pagination, hasActiveAds, isAgency, sortingOption }: Props): JSX.Element => {
    const router = useRouter();
    const { isDesktop } = useContext(RWDContext);

    if (items.length === 0) {
        return <NoResultsInfo hasActiveAds={hasActiveAds} isAgency={isAgency} />;
    }

    const { totalPages, currentPage, totalItems } = pagination;

    const handlePageChange = async ({ page }: { page: number }): Promise<void> => {
        await router.push({
            href: router.route,
            query: { ...router.query, currentPage: page },
        });
    };

    return (
        <>
            <Header>
                <SearchResultCount totalItems={totalItems} />
                {isDesktop && <Sorting sortingOption={sortingOption} />}
            </Header>
            <Wrapper data-testid="ads-list" data-cy="adverts-list-container">
                {items.map((item) => (
                    <ItemWrapper key={item.id}>
                        <AdvertCard key={item.id} ad={item} />
                        {totalPages > 1 ? <Divider variant="secondary-light" /> : null}
                    </ItemWrapper>
                ))}
            </Wrapper>
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                data-cy="adverts-pagination"
            />
        </>
    );
};
