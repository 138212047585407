import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';
import { Divider } from '@nexus/lib-react/dist/core/Divider';
import { Snackbar } from '@nexus/lib-react/dist/core/Snackbar';
import { Textarea } from '@nexus/lib-react/dist/core/Textarea';
import { TextInput } from '@nexus/lib-react/dist/core/TextInput';
import type { CSSProperties } from 'react';

export const StyledForm = styled.form<{ isBottomPageContactForm?: boolean }>`
    display: flex;
    flex-direction: column;
    height: fit-content;
    min-height: 100%;
    gap: ${theme.space['8']};
    padding: ${({ isBottomPageContactForm }): CSSProperties['padding'] =>
        isBottomPageContactForm
            ? theme.space['16']
            : `${theme.space['24']} ${theme.space['24']} ${theme.space['0']} ${theme.space['24']}`};

    @media ${theme.mediaQueries.mdMin} {
        padding: ${theme.space['24']} ${theme.space['24']} ${theme.space['0']} ${theme.space['24']};
        padding-bottom: ${({ isBottomPageContactForm }): CSSProperties['paddingBottom'] =>
            isBottomPageContactForm ? theme.space['24'] : theme.space['0']};
    }

    @media ${theme.mediaQueries.lgMin} {
        padding: ${theme.space['24']};
    }
`;

export const StyledTextInput = styled(TextInput)`
    width: 100%;
`;

export const StyledSubmitButton = styled(Button)`
    align-content: center;
    width: 100%;
    margin-top: auto;
    padding: ${theme.space['16']} ${theme.space['32']};
`;

export const Heading = styled.h1`
    margin: ${theme.space['0']};
    padding: ${theme.space['0']};
`;

export const StyledDivider = styled(Divider)`
    margin: ${theme.space['8']} ${theme.space['0']};
`;

export const StyledTextarea = styled(Textarea)`
    > textarea {
        min-width: 100%;
    }

    & + div {
        justify-content: right;
    }
`;

export const StyledSnackbar = styled(Snackbar)`
    /* AdvertCards have z-index 1 */
    z-index: 2;
`;
