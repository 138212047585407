import { SITE_CONFIG } from '@config/siteConfig';
import { useContactForm } from '@domains/companies/sellerPage/components/ContactForm/core/hooks/useContactForm';
import { SNACKBAR_ID } from '@domains/companies/sellerPage/constants';
import { PhoneInput } from '@domains/companies/sellerPage/nexusComponents/ContactForm/components/PhoneInput/PhoneInput';
import { useContactFormTracking } from '@domains/companies/sellerPage/nexusComponents/ContactForm/hooks/useContactFormTracking';
import { GdprNote } from '@domains/shared/components/GdprNote/GdprNote';
import { transformGdprDetails } from '@domains/shared/components/GdprNote/helpers/transformGdprDetails';
import { FormInput } from '@domains/shared/components/nexus/FormInput/FormInput';
import { TextArea } from '@domains/shared/components/nexus/TextArea/TextArea';
import { EMAIL_REGULAR_EXPRESSION } from '@domains/shared/consts/regularExpressions';
import { VALIDATION_MESSAGE } from '@domains/shared/consts/validationMessages';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import { type FC, useContext, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import {
    Heading,
    StyledDivider,
    StyledForm,
    StyledSnackbar,
    StyledSubmitButton,
    StyledTextarea,
    StyledTextInput,
} from './ContactForm.theme';

interface Props {
    ownerId: string;
    isBottomPageContactForm?: boolean;
}

export const ContactForm: FC<Props> = ({ ownerId, isBottomPageContactForm = false }) => {
    const [t] = useTranslations();
    const { user } = useLazyUser();
    const { isDesktop } = useContext(RWDContext);
    const { mailInputOnClickHandler, messageInputOnClickHandler, nameInputOnClickHandler, phoneInputOnClickHandler } =
        useContactFormTracking();

    const form = useContactForm({
        ownerId,
    });
    const { submit, formState, setValue } = form;

    useEffect(() => {
        if (user) {
            setValue('email', user.email);
        }
    }, [user, setValue]);

    const shouldDisableEmailInput = !!user?.email;

    return (
        <FormProvider {...form}>
            <StyledForm
                onSubmit={submit}
                isBottomPageContactForm={isBottomPageContactForm}
                data-cy="sidebar-contact-section"
            >
                <Heading>{t('frontend.companies-seller-page.contact-form.title')}</Heading>
                <FormInput
                    id="name"
                    input={StyledTextInput}
                    ariaLabel={t('frontend.companies-seller-page.contact-form.name')}
                    placeholder={`${t('frontend.companies-seller-page.contact-form.name')}*`}
                    registerOptions={{ required: t('frontend.companies-seller-page.contact-form.name-required-error') }}
                    validationStatus={formState.errors.name ? 'hasError' : undefined}
                    shouldUseControllerComponent
                    onClick={nameInputOnClickHandler}
                />
                <FormInput
                    id="email"
                    input={StyledTextInput}
                    disabled={shouldDisableEmailInput}
                    ariaLabel={t('frontend.companies-seller-page.contact-form.email')}
                    placeholder={`${t('frontend.companies-seller-page.contact-form.email')}*`}
                    type="email"
                    registerOptions={{
                        required: t('frontend.companies-seller-page.contact-form.email-required-error'),
                        pattern: {
                            message: t(VALIDATION_MESSAGE.invalidEmail),
                            value: EMAIL_REGULAR_EXPRESSION,
                        },
                    }}
                    validationStatus={formState.errors.email ? 'hasError' : undefined}
                    shouldUseControllerComponent
                    onClick={mailInputOnClickHandler}
                />
                <PhoneInput phoneInputOnClick={phoneInputOnClickHandler} />
                <StyledDivider variant="secondary-light" />
                <TextArea
                    id="message"
                    placeholder={t('frontend.companies-seller-page.contact-form.message')}
                    variant="dark"
                    minLength={1}
                    maxLength={2000}
                    showSymbolsCount
                    rows={7}
                    inputComponent={StyledTextarea}
                    registerOptions={{
                        required: t('frontend.companies-seller-page.contact-form.message-required-error'),
                    }}
                    shouldDisplayValidationState
                    shouldUseControllerComponent
                    onClick={messageInputOnClickHandler}
                />
                <GdprNote
                    intro={t('frontend.shared.contact-form.gdpr-administrator')}
                    details={transformGdprDetails({
                        description: t('frontend.shared.contact-form.gdpr-privacy-policy-new'),
                        privacyPolicyLink: SITE_CONFIG.privacyPolicyLink,
                        termsAndConditionsLink: SITE_CONFIG.termsAndConditionsLink,
                    })}
                    shouldUsePopover={!!isDesktop}
                    data-cy="seller-contact-form-gdpr"
                />
                <StyledSubmitButton type="submit" disabled={formState.isSubmitting} size="large">
                    {t('frontend.companies-seller-page.contact-form.submit-button')}
                </StyledSubmitButton>
                <StyledSnackbar
                    content={t('frontend.companies.contact-form.toast-success-message')}
                    variant="success"
                    id={SNACKBAR_ID.success}
                    dismissible
                    hideAfter={3}
                />
                <StyledSnackbar
                    content={t('frontend.companies.contact-form.toast-error-message')}
                    variant="error"
                    id={SNACKBAR_ID.error}
                    dismissible
                    hideAfter={3}
                />
            </StyledForm>
        </FormProvider>
    );
};
