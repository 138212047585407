import { COMPANIES_CONFIG } from '@config/companies/companiesConfig';
import type { FormData } from '@domains/companies/sellerPage/types/filtersData';
import type { LocationsObjects } from '@type/search/location/dataItems';
import { FieldsFactory } from '@widgets/search/FieldsFactory';
import type { FC } from 'react';
import { useWatch } from 'react-hook-form';

import { FiltersRowWrapper, LocationFilterRow, SecondaryFieldsRow, Wrapper } from './AgencyFilters.theme';
import { LocationFilter } from './LocationFilter';

interface Props {
    locationsObjects: LocationsObjects[] | null;
    formData: FormData;
}

export const AgencyFilters: FC<Props> = ({ locationsObjects, formData }) => {
    const estateOption = useWatch({ name: 'estate' });
    const { searchFieldsMetadata } = COMPANIES_CONFIG.sellerPage;

    return (
        <Wrapper>
            <FiltersRowWrapper>
                <FieldsFactory
                    estate={estateOption}
                    fieldsMetadata={searchFieldsMetadata}
                    fieldsMetadataExperimentsVariants={[]}
                    keys={['estate', 'transaction']}
                    shouldRenderLabels
                    trackingData={{
                        estate: {
                            touch_point_button: 'property_type',
                        },
                        transaction: {
                            touch_point_button: 'transaction_type',
                        },
                    }}
                />
            </FiltersRowWrapper>
            <LocationFilterRow>
                <LocationFilter formData={formData} locationsObjects={locationsObjects} />
            </LocationFilterRow>
            <SecondaryFieldsRow>
                <FieldsFactory
                    estate={estateOption}
                    fieldsMetadata={searchFieldsMetadata}
                    fieldsMetadataExperimentsVariants={[]}
                    keys={['market', 'price', 'area', 'roomsNumber']}
                    shouldRenderLabels
                    shouldDisplayUnitInSuffix
                    shouldUseGridArea
                />
            </SecondaryFieldsRow>
        </Wrapper>
    );
};
