import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const BreadcrumbsWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: ${theme.space[12]} ${theme.space[16]};

    @media ${theme.mediaQueries.lgMin} {
        padding: ${theme.space[16]} ${theme.space[24]};
    }

    @media ${theme.mediaQueries.xlMin} {
        max-width: 1280px;
    }
`;
