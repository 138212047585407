import { useSegmentedRoute } from '@domains/companies/sellerPage/hooks/useSegmentedRoute';
import { Breadcrumbs as SharedBreadcrumbs } from '@domains/shared/components/Breadcrumbs/Breadcrumbs';
import type { AdvertUserType } from '@type/user/userType';
import type { FC } from 'react';

import { BreadcrumbsWrapper } from './Breadcrumbs.theme';

interface Props {
    companyName: string;
    companyType: AdvertUserType;
}

export const Breadcrumbs: FC<Props> = ({ companyName, companyType }) => {
    const segments = useSegmentedRoute({ companyName, companyType });

    return (
        <BreadcrumbsWrapper data-cy="navigation-breadcrumbs">
            <SharedBreadcrumbs breadcrumbItems={segments} shouldUseNexusTheme />
        </BreadcrumbsWrapper>
    );
};
