import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { SEARCH_WIDGET_THEME } from '@widgets/search/theme/[[[SITECODE]]]';
import type { CSSProperties } from 'react';

export const Title = styled.div`
    grid-area: title;
    font-size: ${theme.fontSizes.p2};
    font-weight: ${theme.fontWeights.bold};
    line-height: 1;
`;

const PickerButton = styled.button`
    width: ${theme.space[48]};
    height: ${theme.space[48]};
    margin: ${theme.space[0]};
    padding: ${theme.space[0]};
    border: ${theme.borderStyles.none};
    outline: none;
    background-color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.backgroundColor};
    fill: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.closeButton.color};
    color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.closeButton.color};
    cursor: pointer;
`;

export const ClosePickerButton = styled(PickerButton)`
    grid-area: close;
    justify-self: flex-end;
`;

export const BackPickerButton = styled(PickerButton)`
    grid-area: back;
`;

export const TitleWrapper = styled.div<{ isStreetSearchActive: boolean }>`
    display: grid;
    position: fixed;
    z-index: ${theme.zIndices.fixed};
    top: 0;
    left: ${({ isStreetSearchActive }): CSSProperties['left'] =>
        isStreetSearchActive ? theme.space[0] : theme.space[16]};
    grid-template-areas: 'back title close';
    grid-template-columns: auto auto 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-content: start;
    width: ${({ isStreetSearchActive }): CSSProperties['width'] =>
        isStreetSearchActive ? '100%' : `calc(100% - ${theme.space[16]})`};
    background-color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.backgroundColor};
`;
