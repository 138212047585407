import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import type { JSX } from 'react';

import { BackPickerButton, ClosePickerButton, Title, TitleWrapper } from './MobileTitleBar.theme';

export const MobileTitleBar = ({
    isStreetSearchActive,
    onBackFromStreetSearch,
    hideLocationPicker,
}: {
    isStreetSearchActive: boolean;
    onBackFromStreetSearch: () => void;
    hideLocationPicker: () => void;
}): JSX.Element => {
    const [t] = useTranslations();
    const titleLabel = isStreetSearchActive
        ? t('frontend.search.form.location.street.search-near-location')
        : t('frontend.search.form.location.mobile.title');

    return (
        <TitleWrapper isStreetSearchActive={isStreetSearchActive}>
            {isStreetSearchActive ? (
                <BackPickerButton
                    data-cy="search.form.location.close-picker"
                    data-testid="search.form.location.close-picker"
                    type="button"
                    onClick={onBackFromStreetSearch}
                >
                    <Icon icon={faChevronLeft} size="lg" />
                </BackPickerButton>
            ) : null}
            <Title>{titleLabel}</Title>
            <ClosePickerButton data-cy="search.form.location.close-picker" type="button" onClick={hideLocationPicker}>
                <Icon icon={faTimes} size="lg" />
            </ClosePickerButton>
        </TitleWrapper>
    );
};
