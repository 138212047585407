import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';
import { theme } from '@lib/styles/themes/nexus';

export const CompanyName = styled.h1`
    margin-top: 0;
    margin-bottom: 16px;
    font-size: ${SIZE.h5};
    font-weight: ${WEIGHT.bold};
    word-wrap: break-word;

    @media (min-width: ${BREAKPOINT.md}) {
        font-size: ${SIZE.h1};
    }
`;

export const DescriptionWrapper = styled.div`
    margin-top: ${theme.space['24']};
    white-space: pre-wrap;
`;
