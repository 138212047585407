import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Wrapper = styled.div`
    padding: ${theme.sizes[72]} ${theme.space[16]} ${theme.sizes[80]};

    @media ${theme.mediaQueries.lgMin} {
        padding: 0;
    }
`;

export const FiltersRowWrapper = styled.div`
    display: grid;
    grid-gap: ${theme.space[16]};
    margin-bottom: ${theme.space[16]};

    @media ${theme.mediaQueries.lgMin} {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const SecondaryFieldsRow = styled.div`
    display: grid;
    grid-gap: ${theme.space[16]};
    grid-template-areas: 'market' 'price' 'area' 'roomsNumber';
    margin-bottom: ${theme.space[16]};

    @media ${theme.mediaQueries.lgMin} {
        grid-template-areas: 'price market' 'area roomsNumber';
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const LocationFilterRow = styled.div`
    margin-bottom: ${theme.space[16]};
    padding-bottom: ${theme.space[24]};
    border-bottom: 1px solid ${theme.colors['borders-global-tertiary']};

    @media ${theme.mediaQueries.lgMin} {
        padding: 0;
        border: none;
    }
`;
