import type { DetailedLevel } from '@type/location/detailedLevel';
import type { LocationDataItem } from '@type/search/location/dataItem';

export interface GqlRootNodeAndLeaf {
    id: LocationDataItem['id'];
    name: string;
    detailedLevel: DetailedLevel;
}

export interface GqlDomainIdNode {
    id: LocationDataItem['id'];
    detailedLevel: DetailedLevel;
    name: string;
    sublocations: GqlDomainIdNode[] | null;
}

export interface JsonTreeNode {
    id: LocationDataItem['id'];
    name: string;
    children: string[];
    detailedLevel?: DetailedLevel;
}
export const checkIsJsonTreeNode = (data: unknown): data is JsonTreeNode => {
    if (typeof data !== 'object' || !data) {
        return false;
    }

    const { id, name, children, detailedLevel } = data as JsonTreeNode;

    return (
        typeof id === 'string' &&
        typeof name === 'string' &&
        Array.isArray(children) &&
        children.every((item) => typeof item === 'string') &&
        (detailedLevel === undefined || typeof detailedLevel === 'string')
    );
};

export interface TreeResponseStructure {
    locationTree: { locationTreeObjects: GqlDomainIdNode[]; __typename: string };
}

export const MISSING_LOCATION_FORM_FIELD = {
    locationName: 'locationName',
    locationFeedback: 'locationFeedback',
} as const;
