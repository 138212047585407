import { Container } from '@domains/shared/components/Container/Container';
import styled from '@emotion/styled';
import { LAYER } from '@lib/styles/partials/layers';

export const Collapsible = styled(Container)<{ positionTo?: 'right' | 'left' }>`
    position: absolute;
    z-index: ${LAYER.x2};
    top: 0;
    right: ${({ positionTo }): string => (positionTo === 'right' ? '0' : 'auto')};
    left: ${({ positionTo }): string => (positionTo === 'right' ? 'auto' : '0')};
    width: auto;
    padding: 0;
    transition: 0.2s transform ease-in-out;
`;

export const StyledPopover = styled.div`
    padding: 20px;
    background-color: ${({ theme }): string => theme.deprecated.domains.shared.popover.backgroundColor};
    box-shadow: ${({ theme }): string => theme.deprecated.domains.shared.popover.boxShadow};
`;

export const Wrapper = styled.div`
    display: flex;
    position: relative;
    align-items: center;
`;
