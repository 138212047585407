import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Container = styled.ul`
    margin: ${theme.space[0]};
    padding: ${theme.space[0]} ${theme.space[16]};
    list-style: none;

    @media ${theme.mediaQueries.lgMin} {
        padding: ${`${theme.space[4]} ${theme.space[0]}`};
    }
`;
