import { parseError } from '@domains/shared/components/nexus/FormInput/helpers/parseError';
import { Label } from '@domains/shared/components/nexus/Label/Label';
import { NON_WHITESPACE_PATTERN } from '@domains/shared/consts/regularExpressions';
import { VALIDATION_MESSAGE } from '@domains/shared/consts/validationMessages';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { FormControl } from '@nexus/lib-react/dist/core/FormControl';
import type { TextInputProps } from '@nexus/lib-react/dist/core/TextInput';
import type { ComponentProps, FC, JSX } from 'react';
import type { FieldValues, Path, RegisterOptions } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';

interface Props<T extends FieldValues> {
    id: Path<T>;
    required?: boolean;
    registerOptions?: RegisterOptions;
    type?: TextInputProps['type'];
    disabled?: boolean;
    placeholder?: string;
    labelChildren?: React.ReactNode;
    shouldUseControllerComponent?: boolean;
    dataAttributes?: Record<string, string>;
    label?: string;
    input: FC;
    suffix?: string;
    hint?: string;
    className?: string;
    processInput?: (value: string) => string;
    ariaLabel?: string;
    defaultValue?: string;
    validationStatus?: string;
    onClick?: () => void;
    shouldHandleError?: boolean;
}

export const FormInput = <T extends FieldValues>({
    id,
    required = false,
    type = 'text',
    disabled = false,
    placeholder,
    registerOptions,
    labelChildren,
    shouldUseControllerComponent = false,
    dataAttributes = {},
    label,
    input,
    suffix,
    hint,
    className,
    processInput,
    defaultValue,
    ariaLabel,
    validationStatus,
    onClick,
    shouldHandleError = true,
}: Props<T>): JSX.Element => {
    const [t] = useTranslations();

    const {
        register,
        formState: { errors },
        control,
    } = useFormContext<T>();

    const error = parseError<T>(id, errors);

    const labelComponent: FC<ComponentProps<typeof Label>> = ({ children, ...rest }) => {
        return (
            <Label {...rest} appendedChildren={labelChildren} disabled={disabled}>
                {children}
            </Label>
        );
    };

    const formControlProps = {
        label: label ?? undefined,
        inputComponent: input,
        labelComponent: label ? labelComponent : undefined,
        enableRequiredIndicator: required,
        error: shouldHandleError ? error : undefined,
        dataAttributes: {
            ...dataAttributes,
            cy: id,
        },
        hint,
        className,
    };

    const inputProps = {
        id,
        required,
        'aria-invalid': Boolean(error),
        type,
        placeholder,
        disabled,
        suffix,
        'aria-label': ariaLabel,
        defaultValue,
        validationStatus,
        onClick,
    };

    const rules = {
        ...(required && {
            required: t(VALIDATION_MESSAGE.required),
        }),
        ...(required && {
            pattern: {
                message: t(VALIDATION_MESSAGE.required),
                value: NON_WHITESPACE_PATTERN,
            },
        }),
        ...registerOptions,
    };

    return shouldUseControllerComponent ? (
        <Controller
            control={control}
            name={id}
            rules={rules}
            render={({ field: { ref, value, ...rest } }): JSX.Element => {
                let customOnChange = rest.onChange;
                if (processInput) {
                    customOnChange = (event): ReturnType<typeof customOnChange> => {
                        const processedInput = processInput(event.target.value);

                        rest.onChange({
                            target: {
                                value: processedInput,
                            },
                        });
                    };
                }

                return (
                    <FormControl
                        {...formControlProps}
                        inputProps={{
                            ref,
                            value,
                            ...rest,
                            ...inputProps,
                            onChange: customOnChange,
                        }}
                    />
                );
            }}
        />
    ) : (
        <FormControl
            {...formControlProps}
            inputProps={{
                ...register(id, { ...rules }),
                ...inputProps,
            }}
        />
    );
};
