import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Image as NexusImage } from '@nexus/lib-react/dist/core/Image';
import { Tag } from '@nexus/lib-react/dist/core/Tag';
import { H4, P3 } from '@nexus/lib-react/dist/core/Typography';

export const Wrapper = styled.div`
    display: flex;
    position: relative;
    height: 186px;
    gap: ${theme.space[16]};

    @media ${theme.mediaQueries.lgMin} {
        height: 198px;
    }
`;

export const LogoWrapper = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    left: ${theme.space[16]};
    width: 108px;
    height: 108px;
    padding: ${theme.space[4]};
    transform: translateY(-50%);
    border: 1px solid ${theme.colors['borders-global-tertiary']};
    border-radius: ${theme.radii[4]};
    background-color: ${theme.colors['background-global-primary']};

    @media ${theme.mediaQueries.lgMin} {
        position: static;
        flex-shrink: 0;
        width: 250px;
        height: auto;
        transform: translateY(0);
    }
`;

export const Image = styled(NexusImage)`
    img {
        width: 100%;
        height: 100%;
        opacity: 1;
        object-fit: contain;
    }
`;

export const BasicInfo = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    padding: 68px ${theme.space[16]} ${theme.space[16]};
    background-color: ${theme.colors['background-global-primary']};
    gap: ${theme.space[8]};

    @media ${theme.mediaQueries.lgMin} {
        padding: ${theme.space[24]};
        border-radius: ${theme.radii[4]};
        gap: ${theme.space[16]};
    }
`;

export const CompanyName = styled(H4)`
    margin: 0;
    color: ${theme.colors['text-global-highlight']};

    @media ${theme.mediaQueries.lgMin} {
        font-size: ${theme.fontSizes[24]};
    }
`;

export const HQAddress = styled(P3)`
    margin-top: auto;
    color: ${theme.colors['text-global-secondary']};

    @media ${theme.mediaQueries.smMin} {
        font-size: ${theme.fontSizes[16]};
    }
`;

export const StyledTag = styled(Tag)`
    position: absolute;
    top: ${theme.space[16]};
    right: ${theme.space[16]};
    background-color: ${theme.colors['background-brand-tertiary']};
    color: ${theme.colors['text-global-primary']};
    font-weight: ${theme.fontWeights.bold};

    @media ${theme.mediaQueries.lgMin} {
        top: ${theme.space[24]};
        right: ${theme.space[24]};
    }
`;

export const PhoneNumbersWrapper = styled.div`
    display: flex;
    gap: ${theme.space[4]};
`;

export const PhoneNumbers = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.space[8]};
`;
