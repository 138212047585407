import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Wrapper = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${theme.space[24]};
    padding: 0;
    list-style: none;
    gap: ${theme.space[16]};
`;

export const ItemWrapper = styled.li`
    display: flex;
    flex-direction: column;
    gap: ${theme.space[16]};
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
