import type { LocationDataItem } from '@type/search/location/dataItem';
import type { TreeNode } from '@type/search/location/pickerTreeNode';

import type { JsonTreeNode } from '../../../types/locations';

export const treeNodeToJsonTreeNode = (item: TreeNode): [LocationDataItem['id'], JsonTreeNode] => {
    const { id, name, children, detailedLevel } = item;

    return [
        id,
        {
            id,
            name,
            detailedLevel,
            children: [...children.values()].map(({ id }) => id),
        },
    ];
};
