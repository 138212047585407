export const DISTANCE_RADIUS_METADATA = {
    0: {
        label: 'frontend.search.form-distanceradius-0-fieldvalue',
        value: 0,
    },
    5: {
        label: 'frontend.search.form-distanceradius-5-fieldvalue',
        value: 5,
    },
    10: {
        label: 'frontend.search.form-distanceradius-10-fieldvalue',
        value: 10,
    },
    15: {
        label: 'frontend.search.form-distanceradius-15-fieldvalue',
        value: 15,
    },
    25: {
        label: 'frontend.search.form-distanceradius-25-fieldvalue',
        value: 25,
    },
    50: {
        label: 'frontend.search.form-distanceradius-50-fieldvalue',
        value: 50,
    },
    75: {
        label: 'frontend.search.form-distanceradius-75-fieldvalue',
        value: 75,
    },
} as const;

const DISTANCE_RADIUS_OPTIONS = Object.values(DISTANCE_RADIUS_METADATA);

const DEFAULT_DISTANCE_RADIUS_OPTION = DISTANCE_RADIUS_METADATA[0];

export const VALID_DISTANCE_RADIUS_VALUES = DISTANCE_RADIUS_OPTIONS.map(({ value }) => value) as ReadonlyArray<number>;

export const DEFAULT_DISTANCE_RADIUS_VALUE = DEFAULT_DISTANCE_RADIUS_OPTION.value;
