import styled from '@emotion/styled';
import { LAYER } from '@lib/styles/partials/layers';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const Container = styled.div`
    display: flex;
    position: fixed;
    z-index: ${LAYER.x1};
    right: 0;
    bottom: 0;
    left: 0;

    & > * {
        width: 100%;
        margin: 0;
        padding: 24px;
        border: 0;
        outline: 0;
        background-color: ${({ theme }): string =>
            theme.deprecated.domains.companies.stickyActionButtons.action.backgroundColor};
        color: ${({ theme }): string => theme.deprecated.domains.companies.stickyActionButtons.action.color};
        font-size: ${SIZE.p3};
        font-weight: ${WEIGHT.semibold};
        text-align: center;
        text-decoration: none;

        & + * {
            border-left: 1px solid
                ${({ theme }): string => theme.deprecated.domains.companies.stickyActionButtons.action.borderColor};
        }

        &:hover,
        &:focus {
            background-color: ${({ theme }): string =>
                theme.deprecated.domains.companies.stickyActionButtons.action.active.backgroundColor};
        }

        &:not(:disabled) {
            cursor: pointer;
        }
    }
`;
