import type { JSX } from 'react';

import type { AnchorAction } from '../types/action';
import { ActionIcon } from './ActionIcon';

export const AnchorActionElement = ({ text, iconProps, href, onClick }: AnchorAction): JSX.Element => {
    return (
        <a href={href} onClick={onClick}>
            <ActionIcon {...iconProps} />
            <span>{text}</span>
        </a>
    );
};
