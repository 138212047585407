import { SpecialOffersSection } from '@domains/shared/components/SpecialOfferSection/SpecialOffersSection';
import { Title as SpecialOffersSectionTitle } from '@domains/shared/components/SpecialOfferSection/SpecialOffersSection.theme';
import { css } from '@emotion/react';
import type { CSSProperties, SerializedStyles } from '@emotion/serialize';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { GENERICS } from '@lib/styles/partials/colors';
import { LAYER } from '@lib/styles/partials/layers';
import { theme } from '@lib/styles/themes/nexus';

export const Container = styled.div`
    position: fixed;
    z-index: ${LAYER.x10};
    inset: 0;
    padding: 16px;
    overflow: auto;
    background-color: ${({ theme }): CSSProperties['backgroundColor'] =>
        theme.deprecated.domains.companies.searchForm.backgroundColor};

    @media (min-width: ${BREAKPOINT.md}) {
        position: static;
        z-index: initial;
        margin-bottom: 24px;
        padding: 0;
        overflow: visible;
        background-color: ${GENERICS.transparent};
    }
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid
        ${({ theme }): CSSProperties['borderColor'] => theme.deprecated.domains.companies.searchForm.divider.color};
`;

export const Title = styled.h3`
    margin: 0;
`;

export const ExitButton = styled.button`
    border: 0;
    background: none;
`;

const FORM_GRID_WITH_SPECIAL_OFFER_FILTER = css`
    grid-template-areas:
        'primary-fields'
        '.'
        'special-offer'
        '.'
        'secondary-fields'
        'actions-buttons';
`;

const FORM_GRID_WITHOUT_SPECIAL_OFFER_FILTER = css`
    grid-template-areas:
        'primary-fields'
        'secondary-fields'
        'actions-buttons';
`;

export const StyledForm = styled.form<{ shouldShowSpecialOffersFilter: boolean }>`
    display: grid;
    row-gap: 16px;
    ${({ shouldShowSpecialOffersFilter }): SerializedStyles => {
        return shouldShowSpecialOffersFilter
            ? FORM_GRID_WITH_SPECIAL_OFFER_FILTER
            : FORM_GRID_WITHOUT_SPECIAL_OFFER_FILTER;
    }}
    margin-bottom: 96px;

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-areas:
            'primary-fields primary-fields'
            '. .'
            'secondary-fields actions-buttons'
            '. .';
        grid-template-columns: repeat(2, auto);
        margin-bottom: 0;
    }
`;

const Divider = styled.div`
    background-color: ${({ theme }): CSSProperties['backgroundColor'] =>
        theme.deprecated.domains.companies.searchForm.divider.color};
`;

export const FieldsContainerDivider = styled(Divider)`
    grid-column: 1/-1;
    height: 1px;
`;

export const VerticalDivider = styled(Divider)`
    width: 1px;
    min-height: 38px;
`;

const FieldsContainer = styled.div`
    display: grid;
    gap: ${theme.space[16]} ${theme.space[12]};
`;

export const PrimaryFieldsContainer = styled(FieldsContainer)<{ shouldShowSpecialOffersFilter: boolean }>`
    grid-area: primary-fields;
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-columns: ${({ shouldShowSpecialOffersFilter }): CSSProperties['gridTemplateColumns'] =>
            shouldShowSpecialOffersFilter ? 'repeat(3, 1fr) 440px auto auto' : 'repeat(3, 1fr) 480px'};
    }
`;

export const SecondaryFieldsContainer = styled(FieldsContainer)`
    grid-area: secondary-fields;
    grid-template-columns: 1fr;

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-columns: repeat(4, auto);
    }
`;

export const ActionButtonsContainer = styled.div`
    display: grid;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    grid-area: actions-buttons;
    grid-template-columns: repeat(2, 1fr);
    padding: 16px;
    column-gap: 24px;
    background-color: ${({ theme }): CSSProperties['backgroundColor'] =>
        theme.deprecated.domains.companies.searchForm.backgroundColor};

    & > * {
        white-space: nowrap;
    }

    ${VerticalDivider} {
        margin-right: 24px;
        margin-left: 8px;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        position: static;
        grid-template-columns: 1fr auto 1fr;
        padding: 0;
        column-gap: 0;
        background: none;
    }
`;

export const StyledSpecialOffersSection = styled(SpecialOffersSection)`
    grid-area: special-offer;
    grid-column-gap: 12px;
    padding: 0;
    border: 0;

    @media (min-width: ${BREAKPOINT.md}) {
        grid-area: unset;
    }

    ${SpecialOffersSectionTitle} {
        @media (min-width: ${BREAKPOINT.md}) {
            font-weight: ${theme.fontWeights[400]};
        }
    }
`;
