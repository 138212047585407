import { useNexusSearchForm } from '@domains/companies/sellerPage/components/SearchForm/hooks/useNexusSearchForm';
import { MobileFiltersFooter } from '@domains/companies/sellerPage/nexusComponents/MobileFiltersFooter/MobileFiltersFooter';
import { MobileFiltersHeader } from '@domains/companies/sellerPage/nexusComponents/MobileFiltersHeader/MobileFiltersHeader';
import { AgencyFilters } from '@domains/companies/sellerPage/nexusComponents/SellerAds/components/AdsFilters/components/AgencyFilters';
import { DeveloperFilters } from '@domains/companies/sellerPage/nexusComponents/SellerAds/components/AdsFilters/components/DeveloperFilters';
import type { FormData } from '@domains/companies/sellerPage/types/filtersData';
import type { SellerPageSearchAds } from '@domains/companies/sellerPage/types/sellerPageSearchAds';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { Button } from '@nexus/lib-react/dist/core/Button';
import SearchIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Search';
import { type FC, type MouseEvent, type MouseEventHandler, useContext } from 'react';
import { FormProvider } from 'react-hook-form';

import { Buttons } from './AdsFilters.theme';

interface Props {
    searchAds: SellerPageSearchAds;
    formData: FormData;
    isAgency: boolean;
    closeSearchFormModal: () => void;
    sellerId: string;
}

export const AdsFilters: FC<Props> = ({ searchAds, formData, isAgency, closeSearchFormModal, sellerId }) => {
    const { isDesktop } = useContext(RWDContext);
    const form = useNexusSearchForm({ formData, isAgency });
    const { trackEvent } = useTracking();
    const { locationsObjects } = searchAds;
    const [t] = useTranslations();

    const handleSearchButtonClick: MouseEventHandler = async (event) => {
        event.preventDefault();
        trackEvent('search');

        await form.submit();
    };

    const handleClearButtonClick = (): void => {
        trackEvent('clear_filters', { touch_point_button: 'searchbox' });

        form.clear();
    };

    const Filters = isAgency ? AgencyFilters : DeveloperFilters;

    return (
        <div data-cy="adverts-filters">
            <FormProvider {...form}>
                {!isDesktop && <MobileFiltersHeader onClear={form.clear} onClose={closeSearchFormModal} />}
                <Filters formData={formData} locationsObjects={locationsObjects} />
                {isDesktop ? (
                    <Buttons>
                        <Button
                            variant="flat"
                            onClick={handleClearButtonClick}
                            type="reset"
                            data-cy="adverts-filters-reset-button"
                        >
                            {t('frontend.companies-seller-page.ads-filters.clear-criteria')}
                        </Button>
                        <Button
                            variant="secondary"
                            prefixIcon={SearchIcon}
                            onClick={handleSearchButtonClick}
                            data-cy="adverts-filters-submit-button"
                        >
                            {t('frontend.companies-seller-page.ads-filters.search')}
                        </Button>
                    </Buttons>
                ) : (
                    <MobileFiltersFooter
                        sellerId={sellerId}
                        submitButtonOnClick={async (event: MouseEvent<HTMLButtonElement>) => {
                            closeSearchFormModal();
                            await handleSearchButtonClick(event);
                        }}
                    />
                )}
            </FormProvider>
        </div>
    );
};
