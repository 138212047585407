import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { Image } from '@nexus/lib-react/dist/core/Image';
import { H4, P2 } from '@nexus/lib-react/dist/core/Typography';

import { NoResults } from './NoResultsInfo.theme';

const getTranslations = (hasActiveAds: boolean, isAgency: boolean): { title: string; info: string } => {
    if (hasActiveAds) {
        return {
            title: 'frontend.companies.ads-list.no-search-results-title',
            info: 'frontend.companies.ads-list.no-search-results-info',
        };
    }

    const title = isAgency
        ? 'frontend.companies.ads-list.agency-no-ads-title'
        : 'frontend.companies.ads-list.developer-no-ads-title';

    return {
        title,
        info: 'frontend.companies.ads-list.no-ads-info',
    };
};

interface Props {
    hasActiveAds: boolean;
    isAgency: boolean;
}

export const NoResultsInfo = ({ hasActiveAds, isAgency }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { staticAssetsPrefix } = getPublicEnvConfig();

    const { title, info } = getTranslations(hasActiveAds, isAgency);

    return (
        <NoResults data-cy="inactive-adverts-fallback-message">
            <Image
                src={`${staticAssetsPrefix}/images/companies/document-magnifying-glass.webp`}
                alt={t('frontend.search.no-results.title')}
                width={130}
                height={120}
                loading="lazy"
            />
            <H4>{t(title)}</H4>
            <P2>{t(info)}</P2>
        </NoResults>
    );
};
